import React from "react";
import { FORM_TO_SUBMIT_WRONG_LANGUAGE } from "../text";
import { FormControlLabel, Checkbox, TextField } from "@mui/material";
import { DATA_TEST_IDS } from "../constants";
import { WrongLanguageIndicatorText, SubmitButton } from "../Shared";

const FormToSubmitWrongLanguage = ({
  checkedWrongLanguage,
  setCheckedWrongLanguage,
  setReason,
  reason,
  handleSubmit
}) => (
  <>
    <FormControlLabel
      value={checkedWrongLanguage === null ? undefined : checkedWrongLanguage}
      control={<Checkbox data-testid={DATA_TEST_IDS.wrongLanguageCheckbox} />}
      label={<WrongLanguageIndicatorText />}
      onChange={() => setCheckedWrongLanguage(!checkedWrongLanguage)}
    />
    {checkedWrongLanguage && (
      <div className='px-12 w-full text-left'>
        <span className='leading-6 mb-2'>
          {FORM_TO_SUBMIT_WRONG_LANGUAGE.reasonLabel}
          <span className='text-red-600'>{FORM_TO_SUBMIT_WRONG_LANGUAGE.required}</span>
        </span>
        <TextField
          variant='outlined'
          onChange={(e) => setReason(e.target.value)}
          required
          sx={{ marginBottom: "1rem", width: "100%" }}
          inputProps={{ "data-testid": DATA_TEST_IDS.reasonInput }}
        />
      </div>
    )}
    <span className='leading-loose'>{FORM_TO_SUBMIT_WRONG_LANGUAGE.explainer}</span>
    <div className='mt-6'>
      <SubmitButton
        disabled={!checkedWrongLanguage || (checkedWrongLanguage && !reason)}
        onClick={() => handleSubmit({ isWrongLanguage: true, wrongLanguageReason: reason })}
      />
    </div>
  </>
);

export default FormToSubmitWrongLanguage;
