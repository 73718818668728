import React, { useState, useContext, useEffect, Fragment } from "react";
import WorkHistoryTranscriber from "pages/WorkHistoryTranscriber";
import WorkHistoryQa from "pages/WorkHistoryQa";
import WorkHistorySummarizer from "pages/WorkHistorySummarizer";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { RateReview, Chat } from "@mui/icons-material";
import { AppContext } from "AppContext";
import Search from "components/Search";
import {
  isProofreader,
  isQA,
  isTRQA,
  isAdminOrInHouseQA,
  isTranscriber,
  isSynthesizer
} from "utils/roles";

const WorkHistory = (props) => {
  const appContext = useContext(AppContext);
  const id = props.match.params.id || appContext.auth.id;
  const viewState = appContext.viewStates[props.location.pathname] || {};
  const [role, setRole] = useState();
  const [pagination, setPagination] = useState(
    viewState.pagination || {
      pageSize: 10,
      pageNumber: 0
    }
  );
  const [sort, setSort] = useState(
    viewState.sort || {
      field: "claim.deadline",
      order: "desc"
    }
  );
  const [searchString, setSearchString] = useState("");
  const { context } = props;

  const unlistenHistory = props.history.listen((location) => {
    if (location.pathname !== props.location.pathname) {
      appContext.setViewState(props.location.pathname, {
        pagination,
        sort
      });
    }
  });

  useEffect(() => {
    if (isTRQA(appContext.auth.role) || isProofreader(appContext.auth.role)) {
      setRole("QA");
    } else {
      setRole(appContext.auth.role);
    }
  }, [appContext]);

  const changePage = (event, pageNumber) => {
    setPagination({
      ...pagination,
      pageNumber
    });
  };

  const changeSort = (field) => {
    let order = "asc";
    if (field === sort.field && sort.order === "asc") {
      order = "desc";
    }
    setSort({ field, order });
  };

  const handleSearchChange = (value) => {
    setSearchString(value || "");
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleRoleChange = (event, newRole) => {
    setRole(newRole);
    setPagination({ ...pagination, pageNumber: 0 });
    setSort({
      field: "claim.deadline",
      order: "desc"
    });
  };

  return (
    <Fragment>
      {!isAdminOrInHouseQA(appContext.auth.role) && (
        <Search
          searchFunc={handleSearchChange}
          placeholder='Search by Interaction ID or Project ID'
        />
      )}
      {isTRQA(appContext.auth.role) && (
        <ToggleButtonGroup value={role} exclusive onChange={handleRoleChange} className='mt-4'>
          <ToggleButton value='QA'>
            <Chat />
          </ToggleButton>
          <ToggleButton value='Transcriber'>
            <RateReview />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {(isQA(role) || context === "qa") && (
        <WorkHistoryQa
          {...props}
          id={id}
          searchString={searchString}
          pagination={pagination}
          changePage={changePage}
          sort={sort}
          changeSort={changeSort}
          unlistenHistory={unlistenHistory}
        />
      )}
      {(isTranscriber(role) || context === "tr") && (
        <WorkHistoryTranscriber
          {...props}
          id={id}
          searchString={searchString}
          pagination={pagination}
          changePage={changePage}
          sort={sort}
          changeSort={changeSort}
          unlistenHistory={unlistenHistory}
        />
      )}
      {(isSynthesizer(role) || context === "synthesizer") && (
        <WorkHistorySummarizer
          {...props}
          id={id}
          searchString={searchString}
          pagination={pagination}
          changePage={changePage}
          sort={sort}
          changeSort={changeSort}
          unlistenHistory={unlistenHistory}
        />
      )}
    </Fragment>
  );
};

export default WorkHistory;
