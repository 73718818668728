module.exports = {
  header: "Transcription",
  qualityLabel: "Quality",
  goodQuality: {
    value: "goodQuality",
    textChunks: [
      "The AI transcript is",
      "good quality",
      "and does not require a transcriptionist to create a transcript from the audio file."
    ]
  },
  lowQuality: {
    value: "lowQuality",
    textChunks: [
      "The AI transcript is too",
      "low quality",
      "and requires the transcriptionist to transcribe from scratch."
    ],
    helperText:
      "By sending this to the transcriptionist, you will stop working on this transcript and will not be compensated for the work on this transcript.",
    confirmation: "Sent to transcriptionist",
    reasonsLabel: "Reasons",
    reasonsPlaceholder: "Input reasons"
  }
};
