import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { Button, Dialog, DialogTitle, IconButton, TextField } from '@mui/material';

import { Add, Remove } from '@mui/icons-material';
import { getClaim, offsetDeadline } from 'services/ClaimsService';
import { utcToLocalDateString } from 'utils';
import { usePageVisibility } from 'react-page-visibility';
import { AppContext } from 'AppContext';
import Select from 'components/Select';
import ClaimExtensionsViewer from 'components/ClaimExtensionsViewer';
import './index.css';

const defaultPollTime = 1000 * 60; /// Poll every 60 seconds
const defaultRequestExtensionTime = 60 * 30; /// 30 minutes

/// useInterval Adapted from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const RequestExtensionDialog = (props) => {
  const [reason, setReason] = useState();
  const [showReasonTextBox, setShowReasonTextBox] = useState(false);

  const handleClose = () => {
    setReason();
    setShowReasonTextBox(false);
    props.onClose();
  };

  const handleExtensionRequest = () => {
    props.onExtensionRequest(reason);
    handleClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>Extension Request</DialogTitle>
      <div className='px-6 pb-4 flex flex-col'>
        <div className='text-xs'>
          All claims are allotted a {props.hardDeadlineOffset / 60}min built-in grace period (10min
          final eye, {props.hardDeadlineOffset / 60 - 10}min delay period). Please only request
          further extension if this additional grace period is not sufficient.
        </div>
        <div className='mt-2'>
          <Select
            name='reason'
            label='Extension reason'
            value={
              reason
                ? ['Terminology', 'Audio Quality', 'Accent'].includes(reason)
                  ? reason
                  : 'Other'
                : showReasonTextBox
                  ? 'Other'
                  : null
            }
            values={['Terminology', 'Audio Quality', 'Accent', 'Other']}
            onChange={(event) => {
              const selectedReason = event.target.value;
              if (selectedReason === 'Other') {
                setShowReasonTextBox(true);
                setReason();
              } else {
                setShowReasonTextBox(false);
                setReason(event.target.value);
              }
            }}
            className='pt-0 inherited-input'
          />
        </div>
        {showReasonTextBox && (
          <div className='mt-4'>
            <TextField
              label='Other extension reason'
              value={reason}
              onChange={(target) => setReason(target.currentTarget.value)}
              multiline
              fullWidth
              variant='outlined'
              rowsMax={8}
            />
          </div>
        )}
        <div className='mt-2 flex flex-row justify-between'>
          <Button onClick={handleExtensionRequest} disabled={!reason}>
            Request
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
};

const Deadline = (props) => {
  const {
    claim,
    hardDeadlineOffset, /// ASSUMPTION: Expressed in seconds
    size,
    onChangeClick,
    showDetails,
    className,
    extendable,
    extensionsViewable
  } = props;
  const [deadlines, setDeadlines] = useState({
    deadline: claim.deadline,
    finalEyeDeadline: claim.finalEyeDeadline,
    hardDeadline: claim.deadline
  });
  const [countdown, setCountdown] = useState(deadlines.deadline);
  const [timeState, setTimeState] = useState('');
  const [pollTime, setPollTime] = useState(defaultPollTime);
  const [statusMessage, setStatusMessage] = useState();
  const [openRequestExtension, setOpenRequestExtension] = useState(false);
  const [claimExtensions, setClaimExtensions] = useState(claim.extensions);
  const [canRequestExtension, setCanRequestExtension] = useState(claim.canRequestExtension);
  const pageVisible = usePageVisibility();
  const countdownRef = useRef();
  const appContext = useContext(AppContext);

  const setDeadlinesFromClaim = (currentClaim) => {
    setDeadlines({
      deadline: new Date(currentClaim.deadline).getTime(),
      finalEyeDeadline: new Date(currentClaim.finalEyeDeadline).getTime(),
      hardDeadline: new Date(currentClaim.deadline).getTime() + hardDeadlineOffset * 1000
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshClaim = async () => {
    const result = await getClaim(claim.id);
    if (deadlines.deadline !== new Date(result.deadline).getTime()) {
      setDeadlinesFromClaim(result);
    }
  };

  const resetCountdown = () => {
    const deadlineBuffer = 1000; /// Countdown component may be off by milliseconds.  Use buffer to ensure the correct deadline context.
    if (deadlines.deadline - deadlineBuffer > Date.now()) {
      setCountdown(deadlines.deadline);
      countdownRef.current.start();
      setTimeState('');
      setStatusMessage('');
    } else if (deadlines.finalEyeDeadline - deadlineBuffer > Date.now()) {
      setCountdown(deadlines.finalEyeDeadline);
      setTimeState('text-yellow-400');
      setStatusMessage('Final eye');
      countdownRef.current.start();
    } else if (deadlines.hardDeadline - deadlineBuffer > Date.now()) {
      setCountdown(deadlines.hardDeadline);
      countdownRef.current.start();
      setTimeState('text-orange-500');
      setStatusMessage('Delay reason required');
    } else {
      countdownRef.current.pause();
      setTimeState('text-red-600');
      setStatusMessage('Expired and returned to queue');
    }
  };

  useEffect(() => {
    setDeadlinesFromClaim(claim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim]);

  useEffect(() => {
    resetCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines]);

  useEffect(() => {
    if (pageVisible) {
      refreshClaim();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVisible]);

  useInterval(() => {
    try {
      refreshClaim();
    } catch (error) {
      showErrorDialog('Refresh claim failed: ' + error);
      setPollTime(null); /// Stop polling when async request errors out.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, pollTime);

  const handleExtensionRequest = (reason) => {
    offsetDeadline(claim.id, defaultRequestExtensionTime, reason).then(
      (data) => {
        setDeadlinesFromClaim(data.saved);
        setClaimExtensions(data.saved.extensions);
        setCanRequestExtension(data.saved.canRequestExtension);
        setOpenRequestExtension(false);
        appContext.showNotification('success', 'Deadline extended.');
      },
      (reject) => {
        appContext.showNotification('error', reject.message);
      }
    );
  };

  const showErrorDialog = (message) => {
    appContext.showCloseDialog('Error', message);
  };

  const textFont = `text-${size}`;

  return (
    <div className={className}>
      {showDetails && (
        <div className='mb-3 text-gray-800 text-sm font-extrabold text-left'>{statusMessage}</div>
      )}
      <div className='text-left field-label'>Time remaining:</div>
      <div className='flex items-center'>
        {onChangeClick && (
          <IconButton size='small' onClick={() => onChangeClick(true)}>
            <Add fontSize='large' />
          </IconButton>
        )}
        <div className={`font-mono text-left font-bold ${textFont} ${timeState}`}>
          <Countdown
            ref={countdownRef}
            date={countdown}
            daysInHours={true}
            onComplete={resetCountdown}
          />
        </div>
        {onChangeClick && (
          <IconButton size='small' onClick={() => onChangeClick(false)}>
            <Remove fontSize='large' />
          </IconButton>
        )}
        {extendable && canRequestExtension && (
          <div className='ml-6'>
            <Button variant='outlined' onClick={() => setOpenRequestExtension(true)}>
              Request 30min extension
            </Button>
            <RequestExtensionDialog
              open={openRequestExtension}
              onClose={() => setOpenRequestExtension(false)}
              hardDeadlineOffset={hardDeadlineOffset}
              onExtensionRequest={handleExtensionRequest}
            />
          </div>
        )}
        {extensionsViewable && claimExtensions && claimExtensions.length > 0 && (
          <ClaimExtensionsViewer claimExtensions={claimExtensions} className='ml-4' />
        )}
      </div>
      {showDetails && (
        <div className='field'>
          <div className='field-label'>Deadline:</div>
          <div className='field-value'>
            <div>{utcToLocalDateString(deadlines.deadline)}</div>
          </div>
        </div>
      )}
    </div>
  );
};

Deadline.propTypes = {
  claim: PropTypes.object.isRequired,
  hardDeadlineOffset: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']),
  onChangeClick: PropTypes.func,
  showDetails: PropTypes.bool,
  className: PropTypes.string,
  extendable: PropTypes.bool,
  extensionsViewable: PropTypes.bool
};

Deadline.defaultProps = {
  hardDeadlineOffset: 0,
  size: '4xl',
  onChangeClick: null,
  showDetails: true,
  extendable: false,
  extensionsViewable: false
};

export default Deadline;
