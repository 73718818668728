import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'AppContext';

import {
  createPayment,
  getPayments,
  submitPayment,
  processPayment,
  deletePayment
} from 'services/PaymentsService';
import { getInvoicePayments, saveInvoicePayment } from 'services/InvoicePaymentsService';

import LoadingDialog from 'components/LoadingDialog';
import InvoiceDialog from 'components/InvoiceDialog';
import BankDialog from 'components/BankDialog';
import Select from 'components/Select';

import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MuiTable from '@mui/material/Table';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import MuiTextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  secondsToHhmmss,
  toCurrency,
  utcToLocalDateString,
  utcToAbsoluteDateString,
  sort
} from 'utils';
import { isAdmin } from 'utils/roles';

const getPaymentState = (payment) => {
  if (!payment.submitDate) return 'New';
  if (!payment.processDate) return 'Submitted';
  return 'Processed';
};

export const Payments = () => {
  const appContext = useContext(AppContext);
  const [payments, setPayments] = useState([]);
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [payment, setPayment] = useState({});
  const [invoicePayments, setInvoicePayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogInvoicePayment, setDialogInvoicePayment] = useState({});
  const [dialogBankUserId, setDialogBankUserId] = useState(0);
  const [filter, setFilter] = useState('All');
  const [filteredInvoicePayments, setFilteredInvoicePayments] = useState([]);

  const getBonus = (invoicePayment) =>
    invoicePayment.bonuses.reduce((acc, val) => acc + val.total, 0);

  const calculateFinalTotal = (invoicePayment) =>
    invoicePayment.total + invoicePayment.adjustment + getBonus(invoicePayment);

  const handleSaveInvoicePayment = async (invoicePayment) => {
    try {
      setLoading(true);
      invoicePayment.finalTotal = calculateFinalTotal(invoicePayment);
      const result = await saveInvoicePayment(invoicePayment);
      let update = [...invoicePayments];
      const index = invoicePayments.findIndex((ip) => ip.id === invoicePayment.id);
      update[index] = result.saved;
      setInvoicePayments(update);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSubmit = async () => {
    try {
      setLoading(true);
      const result = await submitPayment(payment.id);
      payments[paymentIndex] = result;
      setPayment(result);
      appContext.showNotification(
        'success',
        'Payments submitted.  Payment bank files available for download.'
      );
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentProcess = async () => {
    try {
      setLoading(true);
      const result = await processPayment(payment.id);
      payments[paymentIndex] = result;
      setPayment(result);
      appContext.showNotification('success', 'Payment marked as processed.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePayment = async (date) => {
    let endDate = date.toISOString();
    endDate = endDate.substring(0, endDate.indexOf('T'));
    try {
      setLoading(true);
      const result = await createPayment(endDate);
      payments.unshift(result);
      setPayment(result);
      appContext.showNotification('success', 'New payments generated.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    appContext.showDialog(
      `Delete Payment [${payment.paymentNumber}]?`,
      'Deleting this payment removes all invoice adjustments and confirmations associated with this payment.',
      () => doDelete(),
      "Don't Delete",
      'Delete Payment'
    );
  };

  const doDelete = async () => {
    try {
      setLoading(true);
      await deletePayment(payment.id);
      payments.shift();
      setPayment(payments[paymentIndex]);
      appContext.showNotification('success', 'Payments deleted.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const showInvoice = (invoicePayment) => {
    setDialogInvoicePayment(invoicePayment);
  };

  const onCloseInvoice = () => {
    setDialogInvoicePayment({});
  };

  const showBank = (user) => {
    setDialogBankUserId(user.id);
  };

  const onCloseBank = () => {
    setDialogBankUserId(0);
  };

  useEffect(() => {
    const loadPayments = async () => {
      const result = await getPayments();
      setPayments(sort(result, 'endDate', true));
    };
    loadPayments();
  }, [appContext.auth]);

  useEffect(() => {
    if (payments.length > 0) {
      setPayment(payments[paymentIndex]);
    }
  }, [payments, paymentIndex]);

  useEffect(() => {
    const loadInvoicePayments = async () => {
      try {
        setLoading(true);
        const results = await getInvoicePayments(payment.id);
        const invoicePaymentsResult = results.map((result, i) => ({
          ...result.invoicePayment,
          gpa: results[i].monthlyProgress.gpa,
          totalAudioSecondsForMonth: results[i].monthlyProgress.totalAudioSeconds
        }));
        setInvoicePayments(
          invoicePaymentsResult.sort((a, b) => (a.payee.lastname > b.payee.lastname ? 1 : -1))
        );
      } catch (error) {
        appContext.showNotification('error', error.message);
      } finally {
        setLoading(false);
      }
    };
    if (payment.id) loadInvoicePayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    switch (filter) {
      case 'Unconfirmed':
        setFilteredInvoicePayments(invoicePayments.filter((ip) => !ip.confirmer));
        break;
      case 'Transcripts':
        setFilteredInvoicePayments(
          invoicePayments.filter((ip) => ['Transcriber', 'TRQA', 'QA'].includes(ip.payee.role))
        );
        break;
      case 'Synthesized':
        setFilteredInvoicePayments(invoicePayments.filter((ip) => ip.payee.role === 'Synthesizer'));
        break;
      default:
        setFilteredInvoicePayments(invoicePayments);
        break;
    }
  }, [filter, invoicePayments]);

  const showDialog = (title, message) => {
    appContext.showCloseDialog(title, message);
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      <InvoiceDialog
        invoicePayment={dialogInvoicePayment}
        open={dialogInvoicePayment.id > 0}
        onClose={onCloseInvoice}
      />
      <BankDialog userId={dialogBankUserId} open={dialogBankUserId > 0} onClose={onCloseBank} />
      <div className='mt-5 mb-2 flex flex-row justify-between items-center'>
        <div className='ml-5'>
          <span className='font-sans font-bold text-lg text-gray-600'>Payments</span>
          <span className='ml-2 text-xs text-gray-600'>id: {payment.paymentNumber}</span>
        </div>
        <div
          className={`${
            {
              New: 'state-color-inProgress',
              Submitted: 'state-color-actionable',
              Processed: 'state-color-complete'
            }[getPaymentState(payment)]
          } rounded`}>
          <Button
            onClick={() => {
              let message = payment.submitDate
                ? `Submitted on: ${utcToLocalDateString(payment.submitDate)}\n`
                : '';
              message += payment.processDate
                ? `Processed on: ${utcToLocalDateString(payment.processDate)}`
                : '';
              showDialog('Status', message);
            }}>
            Status
          </Button>
        </div>
        <div>
          {getPaymentState(payment) === 'New' ? (
            <Button
              variant='outlined'
              onClick={handlePaymentSubmit}
              disabled={invoicePayments.find((ip) => ip.confirmer == null) != null}>
              Submit
            </Button>
          ) : (
            getPaymentState(payment) === 'Submitted' && (
              <Button
                variant='outlined'
                onClick={handlePaymentProcess}
                disabled={appContext.auth.role !== 'Finance'}>
                Process
              </Button>
            )
          )}
        </div>
        <div className='flex items-center w-1/2'>
          {paymentIndex < payments.length - 1 && (
            <Button
              size='small'
              onClick={() => {
                setPaymentIndex(paymentIndex + 1);
              }}>
              <NavigateBefore />
            </Button>
          )}
          <span className='mx-4 font-bold'>
            {utcToAbsoluteDateString(payment.startDate)} -{' '}
            {utcToAbsoluteDateString(payment.endDate)}
          </span>
          {paymentIndex > 0 ? (
            <Button
              size='small'
              onClick={() => {
                setPaymentIndex(paymentIndex - 1);
              }}>
              <NavigateNext />
            </Button>
          ) : (
            payment.submitDate != null &&
            isAdmin(appContext.auth.role) && (
              <div className='ml-2'>
                <div className='flex flex-col justify-start items-baseline ml-8'>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Next payment date'
                      value={((d) => new Date(d.setDate(d.getDate() - 1)))(new Date())}
                      inputFormat='MMMM dd, yyyy'
                      disableMaskedInput
                      onChange={(date) => handleCreatePayment(date)}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: ''
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            )
          )}
        </div>
        {!payment.submitter && (
          <div className='w-1/6 -mt-4'>
            <Select
              name='userFilter'
              label='Filter'
              value={filter}
              values={['All', 'Transcripts', 'Synthesized', 'Unconfirmed']}
              onChange={(event, option) => setFilter(option.props.value)}
            />
          </div>
        )}
        <div className='mr-5'>
          {payment.submitter && (
            <Button variant='outlined' href={`/api/payments/payload/${payment.id}`}>
              Download
            </Button>
          )}
        </div>
      </div>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>Recipient</TableCell>
            <TableCell>Invoice #</TableCell>
            <TableCell>Total AH</TableCell>
            <TableCell>Sub-total (USD)</TableCell>
            <TableCell>GPA</TableCell>
            <TableCell>Month AH</TableCell>
            <TableCell>Bonus</TableCell>
            <TableCell className='w-1'>Adjustment</TableCell>
            <TableCell>Adjustment Reason</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Final Total</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredInvoicePayments.map((ip, index) => (
            <TableRow
              key={ip.id}
              className={ip.confirmer ? '' : 'state-color-actionable-secondary'}>
              <TableCell>{ip.payee.role}</TableCell>
              <TableCell>
                <button
                  className='link'
                  onClick={() => {
                    showBank(ip.payee);
                  }}>{`${ip.payee.firstname} ${ip.payee.lastname}`}</button>
              </TableCell>
              <TableCell>
                <button
                  className='link'
                  onClick={() => {
                    showInvoice(ip);
                  }}>
                  {ip.invoiceNumber}
                </button>
              </TableCell>
              <TableCell>{secondsToHhmmss(ip.audioSeconds)}</TableCell>
              <TableCell>{toCurrency(ip.total, ip.currency)}</TableCell>
              <TableCell>{Number(ip.gpa).toFixed(3)}</TableCell>
              <TableCell>{secondsToHhmmss(ip.totalAudioSecondsForMonth)}</TableCell>
              <TableCell>
                {ip.bonuses.length > 0 ? (
                  <button
                    className='link'
                    onClick={() => {
                      let message = '';
                      ip.bonuses.forEach((bonus) => {
                        message += `Bonus Type: ${bonus.bonusType}, Amount: ${bonus.total}\n`;
                      });
                      showDialog('Bonus', message);
                    }}>
                    {toCurrency(getBonus(ip), ip.currency)}
                  </button>
                ) : (
                  toCurrency(0, ip.currency)
                )}
              </TableCell>
              <TableCell>
                <MuiTextField
                  defaultValue={ip.adjustment ? ip.adjustment.toFixed(2) : '0.00'}
                  onChange={({ target: { value } }) => {
                    let update = [...filteredInvoicePayments];
                    update[index].adjustment = parseFloat(value);
                    if (isNaN(update[index].adjustment)) {
                      update[index].adjustment = 0;
                    }
                    update[index].finalTotal = calculateFinalTotal(update[index]);
                    update[index].isDirty = true;
                    setFilteredInvoicePayments(update);
                  }}
                  disabled={payment.submitDate != null}
                />
              </TableCell>
              <TableCell className='w-1/5'>
                <MuiTextField
                  value={ip.adjustmentReason || ''}
                  onChange={({ target: { value } }) => {
                    let update = [...filteredInvoicePayments];
                    update[index].adjustmentReason = value;
                    update[index].isDirty = true;
                    setFilteredInvoicePayments(update);
                  }}
                  className='w-full'
                  disabled={payment.submitDate != null}
                />
              </TableCell>
              <TableCell>{ip.currency}</TableCell>
              <TableCell>
                <span className='font-bold'>{calculateFinalTotal(ip).toFixed(2)}</span>
              </TableCell>
              <TableCell>
                <Button
                  variant='outlined'
                  onClick={() => {
                    handleSaveInvoicePayment(ip);
                  }}
                  disabled={ip.confirmer != null && !ip.isDirty}>
                  Confirm
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      <div className='mt-8'>
        {!payment.submitter && (
          <Button variant='outlined' onClick={handleDelete}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default Payments;
