import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { goodQuality, lowQuality } from '../../constants';

const smallText = 'text-sm mr-1';
const spanStyle = `${smallText} font-normal`;
const boldSpanStyle = `${smallText} font-bold`;

const ConfirmedQuality = ({ isGoodQualityGpt, reasons }) => {
  const { textChunks, value } = isGoodQualityGpt ? goodQuality : lowQuality;
  return (
    <>
      <div data-testid='quality-confirmed' key={value}>
        {textChunks.map((chunk, index) => {
          return (
            <span className={index !== 1 ? spanStyle : boldSpanStyle} key={index}>
              {chunk}
            </span>
          );
        })}
      </div>
      {!isGoodQualityGpt && (
        <>
          {reasons && (
            <div>
              <span className={boldSpanStyle}>{lowQuality.reasonsLabel}: </span>
              <span className={spanStyle}>{reasons}</span>
            </div>
          )}
          <div
            style={{
              lineHeight: '1.3rem',
              width: 'fit-content',
              margin: '1.3rem auto'
            }}
            className='py-1 px-2 rounded-lg bg-chipBackground'>
            <CheckBoxIcon className='text-checkboxIconBackground' />
            <span className='ml-1 text-sm font-normal'>{lowQuality.confirmation}</span>
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmedQuality;
