import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AppContext } from 'AppContext';

import {
  getSummaries,
  getClaimableSummaries,
  getClaimedSummaries,
  getAssignedSummaries,
  claimSummary,
  stateToColorClasses
} from 'services/SummariesService';
import { searchSummaries } from 'services/SearchService';

import Heading from 'components/Heading';
import Table from 'components/Table';
import Select from 'components/Select';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';

import { secondsToHhmmss, utcToLocalDateString, isNonEnglish } from 'utils';
import { isAdmin } from 'utils/roles';

import { Button, Tooltip, Link } from '@mui/material';
import { Notes, Translate, FlashOn, Pause } from '@mui/icons-material';

const columns = [
  { label: 'Interaction ID', field: 'alphasightsId' },
  { label: 'Project ID', field: 'projectId' },
  { label: 'Duration', field: 'length', sortField: 'audioSeconds' },
  { label: 'Request Date', field: 'requestDate' },
  { label: 'Topic', field: 'projectTopic' }
];
const paginationDefaults = {
  pageSize: 10,
  pageNumber: 0
};
const sortDefaults = {
  field: 'requestDate',
  order: 'desc'
};

const Summaries = (props) => {
  const appContext = useContext(AppContext);
  const viewState = appContext.viewStates[props.location.pathname] || {};
  const [summaries, setSummaries] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState(viewState.pagination || paginationDefaults);
  const [sort, setSort] = useState(viewState.sort || sortDefaults);
  const [filterState, setFilterState] = useState(viewState.filterState || 'All');
  const [heading, setHeading] = useState(
    <div className='flex flex-row items-center'>
      <div>Synthesized</div>
      {isAdmin(appContext.auth.role) && (
        <div className='ml-2 text-sm'>
          <Link href='/dashboard-synthesized'>Dashboard</Link>
        </div>
      )}
    </div>
  );
  const [loading, setLoading] = useState(false);

  const unlistenHistory = props.history.listen((location) => {
    if (location.pathname !== props.location.pathname) {
      appContext.setViewState(props.location.pathname, {
        pagination,
        sort,
        filterState
      });
    }
  });

  useEffect(() => {
    if (appContext.auth.role === 'Synthesizer' && !columns.find((x) => x.field === 'claimAction')) {
      columns.push({ label: '', field: 'claimAction' });
    } else if (isAdmin(appContext.auth.role)) {
      if (!columns.find((x) => x.field === 'summaryNotes')) {
        columns.push({
          label: 'Details',
          field: 'summaryNotes',
          unsortable: true
        });
      }
    }
    loadSummaries();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sort, filterState]);

  const loadSummaries = async () => {
    let leaving = false;
    try {
      setLoading(true);
      if (appContext.auth.role === 'Synthesizer') {
        const claimed = await getClaimedSummaries();
        if (claimed.length > 0) {
          leaving = true;
          props.history.replace(`/synthesized/${claimed[0].id}`);
        } else {
          const result = await getAssignedSummaries();
          if (result.length > 0) {
            setSummaries(result);
            setTotalCount(result.length);
            setHeading('Synthesized transcripts assigned to you');
          } else {
            const result = await getClaimableSummaries();
            setSummaries(result);
            setTotalCount(result.length);
            setHeading('Synthesized transcripts available for claim');
          }
        }
      } else {
        const result = await getSummaries(pagination, sort, filterState);
        setSummaries(result.data);
        setTotalCount(result.totalCount);
      }
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const goToNewSummary = () => {
    props.history.push('/synthesized/new');
  };

  const goToSummary = (id) => {
    props.history.push(`/synthesized/${id}`);
  };

  const handleChangePage = (event, pageNumber) => {
    setPagination({ ...pagination, pageNumber });
  };

  const handleChangeSort = (field) => {
    let order = 'asc';
    if (field === sort.field && sort.order === 'asc') {
      order = 'desc';
    }
    setSort({ field, order });
  };

  const handleClaimSelect = (id) => {
    appContext.showDialog(
      'Claim Synthesized Transcript?',
      'You cannot claim another synthesized transcript until you have completed this transcript.',
      () => doClaimSummary(id)
    );
  };

  const doClaimSummary = async (id) => {
    try {
      await claimSummary(id);
      props.history.push(`/synthesized/${id}`);
    } catch (error) {
      appContext.showNotification('error', error.message);
    }
  };

  const handleFilterStateChange = (target, selectItem) => {
    setFilterState(selectItem.props.value);
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const showDetailsDialog = (message) => {
    appContext.showCloseDialog('Details', message);
  };

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      {isAdmin(appContext.auth.role) && (
        <Search
          searchFunc={searchSummaries}
          onSelect={(id) => props.history.push(`/synthesized/${id}`)}
        />
      )}
      <Heading
        title={heading}
        secondaryContent={
          <Fragment>
            {isAdmin(appContext.auth.role) && (
              <Fragment>
                <div className='w-1/4'>
                  <Select
                    name='stateFilter'
                    label='Synthesized State'
                    value={filterState}
                    values={[
                      'All',
                      'New',
                      { value: 'InProgress', label: 'In Progress' },
                      'Completed',
                      'Cancelled',
                      'Suspended'
                    ]}
                    onChange={handleFilterStateChange}
                    itemValue='value'
                    itemDisplay='label'
                    className='input-no-padding'
                  />
                </div>
                <div>
                  <Button variant='outlined' onClick={goToNewSummary}>
                    New Synthesized
                  </Button>
                </div>
              </Fragment>
            )}
          </Fragment>
        }
        className='m-5'
      />
      <Table
        columns={columns}
        data={
          summaries &&
          summaries.map((summary, index) => ({
            id: summary.id,
            alphasightsId: (
              <div className='flex flex-row items-center rowSelectable'>
                <div>{summary.alphasightsId}</div>
                {isNonEnglish(summary) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Non-English' placement='top'>
                      <Translate fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
                {summary.priority && isAdmin(appContext.auth.role) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Prioritized' placement='top'>
                      <FlashOn fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
                {summary.suspended && isAdmin(appContext.auth.role) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Suspended' placement='top'>
                      <Pause fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
              </div>
            ),
            projectId: summary.projectId,
            length: secondsToHhmmss(summary.audioSeconds),
            audioSeconds: summary.audioSeconds,
            requestDate: utcToLocalDateString(summary.requestDate),
            projectTopic: summary.projectTopic,
            className: stateToColorClasses(summary.state).secondary,
            summaryNotes: (
              <div className='flex flex-row items-center'>
                {summary.notes && (
                  <Button
                    variant='outlined'
                    onClick={() => {
                      showDetailsDialog(summary.notes);
                    }}>
                    <Notes />
                  </Button>
                )}
              </div>
            ),
            claimAction:
              summary.state === 'New' && index === 0 ? (
                <Button
                  variant='outlined'
                  summary-id={summary.id}
                  onClick={() => handleClaimSelect(summary.id)}>
                  Claim
                </Button>
              ) : (
                ''
              )
          }))
        }
        totalCount={totalCount}
        pagination={pagination}
        sort={isAdmin(appContext.auth.role) ? sort : null}
        onRowSelect={isAdmin(appContext.auth.role) ? goToSummary : null}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
      />
    </Fragment>
  );
};

export default Summaries;
