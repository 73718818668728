import React from 'react';
import text, { wrongLanguageIndicator } from './text';
import { Button, Chip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { DATA_TEST_IDS } from './constants';

const BoldText = ({ text }) => <span className='font-bold'>{text}</span>;

export const WrongLanguageIndicatorText = () => (
  <span className='my-4 leading-normal'>
    {wrongLanguageIndicator[0]}
    <BoldText text={wrongLanguageIndicator[1]} />
    {wrongLanguageIndicator[2]}
  </span>
);

export const WrongLanguageAndReasonText = ({ reason }) => (
  <>
    <WrongLanguageIndicatorText />
    <span>
      <BoldText text={text.reasonDisplay} />
      {reason}
    </span>
  </>
);

export const SubmitButton = ({ disabled, onClick }) => (
  <Button
    variant='outlined'
    disabled={disabled}
    onClick={onClick}
    data-testid={DATA_TEST_IDS.submitWrongLanguage}>
    {text.submitButton}
  </Button>
);

export const LanguageServicesChip = ({ label }) => (
  <Chip
    label={label}
    icon={<CheckCircle sx={{ color: '#00C19B !important' }} />}
    className='mt-4 bg-chipBackground'
  />
);
