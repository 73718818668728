import React, { useState, useContext } from 'react';
import { FormControl, RadioGroup, FormLabel, TextField } from '@mui/material';

import Wrapper from 'components/Wrapper';
import { header, goodQuality, lowQuality, qualityLabel } from './constants';
import { confirmVariant } from '../constants';
import FormControlLabels from './components/FormControlLabels';
import ConfirmedQuality from './components/ConfirmedQuality';
import { AppContext } from 'AppContext';
import { updateGptTranscriptQuality } from 'services/InteractionsService';

const smallText = 'text-sm';
const spanStyle = `${smallText} font-normal`;

const { value: goodValue } = goodQuality;
const { value: lowValue, reasonsLabel, reasonsPlaceholder, helperText } = lowQuality;

const Transcription = ({
  interactionId,
  setLoading,
  isGoodQualityGpt,
  setInteraction,
  interactionLowQualityReason
}) => {
  const [selectedValue, setSelectedValue] = useState(goodValue);
  const [reasons, setReasons] = useState(interactionLowQualityReason);

  const appContext = useContext(AppContext);

  const handleClick = async () => {
    const goodQuality = selectedValue === goodValue;
    const defaultBody = { goodQuality };
    const body = goodQuality ? defaultBody : { ...defaultBody, reason: reasons };
    setLoading(true);
    try {
      await updateGptTranscriptQuality(interactionId, body);
      setInteraction((prevState) => ({
        ...prevState,
        isGoodQualityGpt: goodQuality
      }));
      setLoading(false);
    } catch (_error) {
      setLoading(false);
      return appContext.showNotification('error', 'System error. Please try again later.');
    }
  };

  const button =
    isGoodQualityGpt === null
      ? {
          variant: confirmVariant,
          onClick: handleClick,
          disabled: selectedValue === lowValue && !reasons
        }
      : undefined;

  const qualities = [goodValue, lowValue];

  const stateClassName = isGoodQualityGpt !== null ? 'state-color-complete-secondary' : '';

  return (
    <Wrapper header={header} button={button} className={stateClassName}>
      {isGoodQualityGpt === null ? (
        <FormControl className='flex flex-col items-center'>
          <FormLabel id='radio-buttons-group-label' sx={{ display: 'none' }}>
            {qualityLabel}
          </FormLabel>
          <RadioGroup
            aria-labelledby='radio-buttons-group-label'
            defaultValue={goodValue}
            onChange={(event) => setSelectedValue(event.target.value)}>
            <FormControlLabels qualities={qualities} />
          </RadioGroup>
          {selectedValue === lowValue && (
            <div className='flex flex-col items-start w-full pl-6'>
              <h2 className='text-base font-normal leading-6 mt-4 mb-2'>{reasonsLabel}</h2>
              <TextField
                className='bg-inputBackground'
                variant='outlined'
                fullWidth
                onChange={(e) => setReasons(e.target.value)}
                placeholder={reasonsPlaceholder}
              />
              <span className={`${spanStyle} mt-4 mx-auto`}>{helperText}</span>
            </div>
          )}
        </FormControl>
      ) : (
        <ConfirmedQuality isGoodQualityGpt={isGoodQualityGpt} reasons={reasons} />
      )}
    </Wrapper>
  );
};

export default Transcription;
