import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from 'components/AppHeader';
import AppNav from 'components/AppNav';
import Footer from 'components/AppFooter';
import Dialog from 'components/Dialog';
import Notification from 'components/Notification';
import Interactions from 'pages/Interactions';
import InteractionDetails from 'pages/InteractionDetails';
import Splits from 'pages/Splits';
import SplitDetails from 'pages/SplitDetails';
import Summaries from 'pages/Summaries';
import SummaryDetails from 'pages/SummaryDetails';
import Grades from 'pages/Grades';
import WorkHistory from 'pages/WorkHistory';
import Invoices from 'pages/Invoices';
import Payments from 'pages/Payments';
import Dashboard from 'pages/Dashboard';
import DashboardSummaries from 'pages/DashboardSummaries';
import Users from 'pages/Users';
import UserDetails from 'pages/UserDetails';
import Reports from 'pages/Reports';
import Login from 'pages/Login';
import { getAuth, logout } from 'services/AuthService';
import { AppContext } from 'AppContext';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Settings from 'pages/Settings';

const logoutPath = '/logout';

const HomepageRerouter = (props) => {
  switch (props.role) {
    case 'Admin':
      props.history.push('/interactions');
      break;
    case 'Finance':
      props.history.push('/payments');
      break;
    case 'Transcriber':
      props.history.push('/splits');
      break;
    case 'Synthesizer':
      props.history.push('/synthesized');
      break;
    case 'GroupAdmin':
      props.history.push('/grades');
      break;
    default:
      props.history.push('/interactions');
      break;
  }
  return <div />;
};

const App = () => {
  const [auth, setAuth] = useState(null);
  const [dialog, setDialog] = useState({ open: false });
  const [notification, setNotification] = useState({ open: false });
  const [viewStates, setViewStates] = useState({});

  useEffect(() => {
    loadAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuth = async () => {
    try {
      const auth = await getAuth();
      setAuth(auth);
    } catch (error) {
      setAuth(null);
      if (error.status !== 401) {
        showNotification('error', error.message);
      } else if (window.location.pathname === logoutPath) {
        window.location.replace('/');
      }
    }
  };

  const showNotification = (variant, message) => {
    /// Close notification regardless if it's open or not.  Then open the notification with the provided props.
    /// This provides a visual cue/transition for the user when a new notification is shown over one that is not dismissed.
    handleNotificationClose();
    setTimeout(() => {
      setNotification({ open: true, variant, message });
    }, 100);
  };

  const showDialog = (
    title,
    message,
    onConfirmed,
    cancelLabel,
    confirmLabel,
    fullWidth,
    maxWidth
  ) =>
    setDialog({
      open: true,
      title,
      message,
      onConfirmed,
      cancelLabel,
      confirmLabel,
      fullWidth,
      maxWidth
    });

  const showCloseDialog = (title, message) =>
    setDialog({
      open: true,
      title,
      message,
      onConfirmed: () => {},
      cancelLabel: '',
      confirmLabel: 'Close',
      fullWidth: true,
      maxWidth: 'xs'
    });

  const setViewState = (viewKey) => {
    setViewStates({
      ...viewStates,
      [viewKey]: viewKey
    });
  };

  const handleDialogClose = () => setDialog({ ...dialog, open: false });

  const handleNotificationClose = () => setNotification({ open: false });

  const theme = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          color: 'inherit'
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      }
    }
  });

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            auth,
            showNotification,
            showDialog,
            showCloseDialog,
            viewStates,
            setViewState
          }}>
          <Router>
            <Header />
            <AppNav />
            <Route component={Login} />
            {auth && (
              <Switch>
                <Route
                  exact
                  path='/'
                  render={(props) => <HomepageRerouter role={auth.role} {...props} />}
                />
                <Route path='/interactions' exact component={Interactions} />
                <Route
                  path='/interactions/new'
                  render={(props) => <InteractionDetails {...props} variant='new' />}
                />
                <Route
                  path='/interactions/:id'
                  render={(props) => <InteractionDetails {...props} variant='details' />}
                />
                <Route path='/splits' exact component={Splits} />
                <Route path='/splits/:id' component={SplitDetails} />
                <Route path='/synthesized' exact component={Summaries} />
                <Route
                  path='/synthesized/new'
                  render={(props) => <SummaryDetails {...props} variant='new' />}
                />
                <Route
                  path='/synthesized/:id'
                  render={(props) => <SummaryDetails {...props} variant='details' />}
                />
                <Route path='/users' exact component={Users} />
                <Route
                  path='/users/new'
                  render={(props) => <UserDetails {...props} variant='new' />}
                />
                <Route
                  path='/users/:id'
                  render={(props) => <UserDetails {...props} variant='details' />}
                />
                <Route path='/grades' exact component={Grades} />
                <Route path='/grades/:groupName' component={Grades} />
                <Route path='/workhistory' exact component={WorkHistory} />
                <Route
                  path='/workhistory/transcriber/:id'
                  component={(props) => <WorkHistory {...props} context='tr' />}
                />
                <Route
                  path='/workhistory/qa/:id'
                  component={(props) => <WorkHistory {...props} context='qa' />}
                />
                <Route
                  path='/workhistory/synthesizer/:id'
                  component={(props) => <WorkHistory {...props} context='synthesizer' />}
                />
                <Route path='/invoices/:userId?' component={Invoices} />
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/dashboard-synthesized' component={DashboardSummaries} />
                <Route path='/payments' component={Payments} />
                <Route path='/reports' exact component={Reports} />
                <Route path='/reports/:groupName' component={Reports} />
                <Route
                  path='/profile'
                  render={(props) => <UserDetails {...props} variant='profile' />}
                />
                <Route path='/settings' component={Settings} />
                <Route
                  path={logoutPath}
                  render={() => {
                    logout();
                    window.location.replace('/');
                  }}
                />
              </Switch>
            )}
            <Footer />
          </Router>
          <Dialog {...dialog} onClose={handleDialogClose} />
          <Notification {...notification} onClose={handleNotificationClose} />
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
