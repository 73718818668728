import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';

const LoadingDialog = (props) => {
  const { show } = props;

  return (
    <Dialog
      open={show}
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
          width: '50%',
          height: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }}>
      <CircularProgress size='5em' thickness={5} color='error' />
    </Dialog>
  );
};

export default LoadingDialog;
