import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  InputAdornment,
  Paper
} from '@mui/material';

import InputRating from 'components/InputRating';
import TextField from 'components/TextField';
import { uuid } from 'utils';
import { TemplatedComments } from './constants';
import './index.css';

const TemplatedCommentsSelection = (props) => {
  const { comments, open, onClose, onChange } = props;

  return (
    <Dialog open={open} onClose={onClose} scroll='paper' maxWidth='lg'>
      <DialogContent dividers>
        <div className='flex flex-row'>
          {TemplatedComments.map((categoryComments) => (
            <div key={uuid()} className='flex flex-col w-1/3 divide-y divide-gray-300'>
              <div className='font-bold pb-4'>{categoryComments.category}</div>
              {categoryComments.comments.map((comment) => (
                <FormControlLabel
                  className='h-16'
                  key={uuid()}
                  checked={comments?.includes(comment)}
                  value={comment}
                  control={<Checkbox color='primary' />}
                  label={comment}
                  labelPlacement='end'
                  onChange={(event) => onChange(event.target.value, event.target.checked)}
                />
              ))}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Assessment = (props) => {
  const {
    assessment,
    heading,
    handleChange,
    handleSave,
    className,
    hasAdditionalComments,
    useTemplatedComments,
    disabled
  } = props;
  const stateClassName =
    assessment && assessment.id
      ? 'state-color-complete-secondary'
      : 'state-color-actionable-secondary';
  const disabledClassName = disabled ? 'disabled' : '';

  const [openTemplatedCommentsDialog, setOpenTemplatedCommentsDialog] = useState(false);

  let comments = useTemplatedComments
    ? JSON.parse(assessment?.comments || '{ "comments": [] }').comments
    : [];

  const handleCommentChange = (comment, selected) => {
    if (selected) {
      comments = [...comments, comment];
    } else {
      comments = comments.filter((c) => c !== comment);
    }
    handleChange({
      target: {
        name: 'comments',
        value: JSON.stringify({ comments })
      }
    });
  };

  const commentsTxt = (commentStr) => {
    try {
      const comments = JSON.parse(commentStr).comments;
      let txt = 'Please see uploaded split for specific comments and edits.\n';
      comments.forEach((comment) => (txt += `\n- ${comment}`));
      return txt;
    } catch {
      return commentStr;
    }
  };

  return (
    <Paper className={`${className} ${stateClassName} ${disabledClassName}`}>
      {heading && <div className='component-heading'>{heading}</div>}
      <div className='flex flex-row flex-nowrap items-start justify-start'>
        {handleChange && (
          <div className='w-40'>
            <TextField
              className='grade-entry'
              name='gradeEntry'
              label='Grade Entry'
              value={assessment.totalGrade * 20}
              onChange={(event) => {
                const grade = event.target.value / 20;
                if (grade >= 0 && grade <= 5) {
                  handleChange({
                    target: {
                      name: 'totalGrade',
                      value: grade
                    }
                  });
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>
              }}
              disabled={!handleChange || disabled}
            />
          </div>
        )}
        <div className='flex flex-row items-end'>
          <InputRating
            name='totalGrade'
            value={assessment.totalGrade}
            precision={0.25}
            label='Grade'
            className='pl-8'
            disabled
          />
          <div className='text-xs'>
            {assessment.totalGrade ? `(${Number(assessment.totalGrade).toFixed(3)})` : ''}
          </div>
        </div>
      </div>
      <div className='pb-8'>
        {useTemplatedComments ? (
          <div className='mt-8 ml-8 flex flex-col items-start'>
            <ul className='mb-6 flex flex-col items-start list-disc list-inside text-sm text-gray-600'>
              {JSON.parse(assessment?.comments || '{ "comments": [] }').comments.map((comment) => (
                <li key={uuid()}>{comment}</li>
              ))}
            </ul>
            <Button
              variant='outlined'
              onClick={() => setOpenTemplatedCommentsDialog(true)}
              disabled={disabled}>
              Select Comments
            </Button>
            <TemplatedCommentsSelection
              comments={comments}
              open={openTemplatedCommentsDialog}
              onClose={() => setOpenTemplatedCommentsDialog(false)}
              onChange={handleCommentChange}
            />
          </div>
        ) : (
          <TextField
            name='comments'
            label='Comments'
            value={commentsTxt(assessment.comments)}
            multiline
            variant='outlined'
            disabled={!handleChange || disabled}
            onChange={handleChange}
          />
        )}
      </div>
      {hasAdditionalComments && (
        <div className='-mt-8 pb-8'>
          <TextField
            name='additionalComments'
            label='Client Comments'
            value={assessment.additionalComments}
            multiline
            variant='outlined'
            disabled={!handleChange || disabled}
            onChange={handleChange}
          />
        </div>
      )}
      {handleSave && (
        <div className='pb-8'>
          <Button variant='outlined' onClick={handleSave} disabled={disabled}>
            Save
          </Button>
        </div>
      )}
    </Paper>
  );
};

Assessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  heading: PropTypes.string,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  hasAdditionalComments: PropTypes.bool,
  useTemplatedComments: PropTypes.bool,
  disabled: PropTypes.bool
};

Assessment.defaultProps = {
  hasAdditionalComments: false,
  useTemplatedComments: false
};

export default Assessment;
