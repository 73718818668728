import React from "react";
import PropTypes from "prop-types";
import { WrongLanguageAndReasonText, LanguageServicesChip } from "../Shared";
import text from "../text";

const NonAdminView = ({ isWrongLanguage, loadedReason }) => (
  <>
    {isWrongLanguage ? (
      <>
        <WrongLanguageAndReasonText reason={loadedReason} />
        <LanguageServicesChip label={text.languageServicesHasBeenNotified} />
      </>
    ) : (
      <LanguageServicesChip label={text.languageServicesUpdated} />
    )}
  </>
);

NonAdminView.propTypes = {
  isWrongLanguage: PropTypes.bool,
  loadedReason: PropTypes.string
};

export default NonAdminView;
