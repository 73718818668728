import React, { Fragment, useEffect, useState, useContext } from 'react';
import LoadingDialog from 'components/LoadingDialog';
import { Button, Grid, InputAdornment } from '@mui/material';
import Heading from 'components/Heading';
import TextField from 'components/TextField';
import {
  getSettingsData,
  saveSettingsData,
  cancelTranscriptRequest
} from '../../services/SettingService';
import { AppContext } from 'AppContext';
import Select from 'components/Select';

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [cancelInteractionId, setCancelInteractionId] = useState('');
  const [cancelProvider, setCancelProvider] = useState('KENSHOHD');
  const appContext = useContext(AppContext);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        setLoading(true);
        let result = await getSettingsData();
        setSettings(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      } finally {
        setLoading(false);
      }
    };
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      const total = Number(settings.revHdPct) + Number(settings.kenshoHdPct);
      if (total > 100) {
        throw Error('RevHD and KenshoHD should not exceed 100.');
      }

      let result = await saveSettingsData(settings);
      setSettings(result.saved);
      appContext.showNotification('success', 'Settings saved');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setSettings({
      ...settings,
      [name]: value
    });
  };

  const handleTranscriptPercentage = ({ target: { name, value } }) => {
    if (value >= 0 && value <= 100) {
      handleChange({
        target: {
          name,
          value
        }
      });
    }
  };

  const handleCancelTranscriptRequest = async () => {
    try {
      setLoading(true);
      await cancelTranscriptRequest(cancelInteractionId, cancelProvider);
      appContext.showNotification(
        'success',
        `Transcript request cancelled for interaction [${cancelInteractionId}]`
      );
      setCancelInteractionId('');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      <div>
        <Heading title='Settings' className='m-5' />
        <Grid container direction='column' className='mb-12'>
          <Grid item>
            <div className='grid-row'>
              <TextField
                name='revHdPct'
                data-testid='revHd'
                label='RevHD'
                value={parseInt(settings.revHdPct, 10)}
                className='input-1-2'
                onChange={handleTranscriptPercentage}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>
                }}
              />
              <TextField
                name='revHdQaPct'
                label='RevHD QA'
                value={parseInt(settings.revHdQaPct, 10)}
                className='input-1-2'
                onChange={handleTranscriptPercentage}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div className='grid-row'>
              <TextField
                name='kenshoHdPct'
                data-testid='kenshoHd'
                label='KenshoHD'
                value={parseInt(settings.kenshoHdPct, 10)}
                className='input-1-2'
                onChange={handleTranscriptPercentage}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>
                }}
              />
              <TextField
                name='kenshoHdQaPct'
                label='KenshoHD QA'
                value={parseInt(settings.kenshoHdQaPct, 10)}
                className='input-1-2'
                onChange={handleTranscriptPercentage}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>
                }}
              />
            </div>
          </Grid>
          <Grid item className='pt-10 flex flex-row justify-center'>
            <div>
              <Button variant='outlined' onClick={handleSave}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Heading title='Cancel Transcript Request' className='m-5' />
        <div className='flex flex-row items-end'>
          <TextField
            name='cancelInteractionId'
            label='Interaction ID'
            className='input-1-4'
            error={cancelInteractionId && isNaN(cancelInteractionId)}
            value={cancelInteractionId}
            onChange={({ target: { value } }) => setCancelInteractionId(value)}
          />
          <Select
            name='provider'
            label='Provider'
            value={cancelProvider}
            values={['KENSHOHD']}
            onChange={({ target: { value } }) => setCancelProvider(value)}
            className='input-1-5'
          />
          <Button
            variant='outlined'
            disabled={!cancelInteractionId || isNaN(cancelInteractionId)}
            onClick={handleCancelTranscriptRequest}>
            Cancel Transcript
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
