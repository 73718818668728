import React from "react";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const GradeValue = (props) => {
  const { grade } = props;

  return (
    <div className='flex flex-row items-center'>
      <Rating value={grade} precision={0.25} readOnly emptyIcon={<StarIcon />} />
      {grade > 0 && <span className='text-xs'>{`(${Number(grade).toFixed(3)})`}</span>}
    </div>
  );
};

GradeValue.propTypes = {
  grade: PropTypes.number
};

export default GradeValue;
