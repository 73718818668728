import React from 'react';
import { Warning } from '@mui/icons-material';
import { CONSEQUENCES_TEXT, PROHIBITED_TEXT, BLINDED_CALL_TEXT } from './text';

export const DATA_TEST_IDS = { warningBanner: 'warning-banner' };

const WarningBanner = ({ text }) => (
  <div className='mb-12 rounded w-full text-left h-10 flex bg-backgroundWarning'>
    <div className='rounded-l w-1 min-h-full bg-borderWarning' />
    <div className='mt-3 ml-4 flex text-xs leading-snug'>
      <Warning style={{ fontSize: '18px' }} className='text-iconWarning' />
      {text}
    </div>
  </div>
);

const ProhibitedWarningBanner = () => {
  const prohibitedWarningText = (
    <>
      <span className='font-semibold ml-2 mr-1'>{PROHIBITED_TEXT}</span>
      <span>{CONSEQUENCES_TEXT}</span>
    </>
  );

  return <WarningBanner text={prohibitedWarningText} />;
};

const BlindedCallWarningBanner = () => <WarningBanner text={BLINDED_CALL_TEXT} />;

export { ProhibitedWarningBanner, BlindedCallWarningBanner };
