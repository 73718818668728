import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from 'AppContext';

import {
  getWorkInProgressSplits,
  getAssignedSplits,
  getClaimableSplits,
  claimSplit,
  stateToColorClasses
} from 'services/SplitsService';

import { Button, Tooltip } from '@mui/material';
import { FlashOn, Translate } from '@mui/icons-material';

import { secondsToHhmmss, isNonEnglish } from 'utils';
import { isAdmin } from 'utils/roles';

import Heading from 'components/Heading';
import Table from 'components/Table';
import LoadingDialog from 'components/LoadingDialog';

const columns = [
  { label: 'Interaction ID', field: 'interactionId' },
  { label: 'Project ID', field: 'projectId' },
  { label: 'Split #', field: 'position' },
  { label: 'Time range', field: 'timeRange' },
  { label: 'Duration', field: 'length' },
  { label: 'Topic', field: 'projectTopic' },
  { label: '', field: 'claimAction' }
];

const assignedHeading = 'Assigned Splits';

export const Splits = (props) => {
  const [splits, setSplits] = useState();
  const [heading, setHeading] = useState();
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    let leaving = false;
    const loadSplits = async () => {
      setLoading(true);
      try {
        let result = await getWorkInProgressSplits();
        if (result.length > 0) {
          leaving = true;
          props.history.replace(`/splits/${result[0].id}`);
        } else {
          result = await getAssignedSplits();
          if (result.length > 0) {
            setHeading(assignedHeading);
            setSplits(result);
          } else {
            result = await getClaimableSplits();
            result.length > 0
              ? setHeading(`Next ${result.length} Splits available for claim`)
              : setHeading('No claimable Splits available');
            setSplits(result);
          }
        }
      } catch (error) {
        appContext.showNotification('error', error.message);
      } finally {
        if (!leaving) setLoading(false);
      }
    };
    loadSplits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heading]);

  const handleClaimSelect = (id) => {
    appContext.showDialog(
      'Claim Split?',
      'You cannot claim another split until you have completed this split.',
      () => doClaimSplit(id)
    );
  };

  const doClaimSplit = async (id) => {
    try {
      await claimSplit(id);
      props.history.push(`/splits/${id}`);
    } catch (error) {
      appContext.showNotification('error', error.message);
    }
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      <Heading title={heading} className='m-5' />
      <Table
        columns={columns}
        data={
          splits &&
          splits.map((split, index) => ({
            id: split.id,
            interactionId: (
              <div className='flex flex-row items-center rowSelectable'>
                <div>{split.interaction.alphasightsId}</div>
                {split.interaction.priority && isAdmin(appContext.auth.role) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Prioritized' placement='top'>
                      <FlashOn fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
                {isNonEnglish(split.interaction) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Non-English' placement='top'>
                      <Translate fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
              </div>
            ),
            projectId: split.interaction.projectId,
            position: split.position,
            timeRange: `${secondsToHhmmss(split.startTimeSecond)}
            - ${secondsToHhmmss(split.endTimeSecond)}`,
            length: secondsToHhmmss(split.endTimeSecond - split.startTimeSecond),
            className: stateToColorClasses(split.state).secondary,
            projectTopic: split.interaction.projectTopic,
            claimAction:
              heading === assignedHeading || index === 0 ? (
                <Button
                  variant='outlined'
                  split-id={split.id}
                  onClick={() => handleClaimSelect(split.id)}>
                  Claim
                </Button>
              ) : (
                ''
              )
          }))
        }
      />
    </div>
  );
};

export default Splits;
