import React, { useEffect, useMemo, useState } from "react";
import { saveUserLanguageRate } from "services/UsersService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";

import Select from "components/Select";

const RateDialog = (props) => {
  const {
    onClose,
    open,
    languages,
    selectedRate,
    showNotification,
    loadRatesTable,
    userId,
    userRole,
    variant
  } = props;
  const rateDefaults = {
    id: 0,
    rate: "",
    task: ""
  };
  const [userLanguageRate, setUserLanguageRate] = useState(rateDefaults);
  const [userLanguage, setUserLanguage] = useState(null);

  const languagesMap = new Map();
  languages.forEach((language) => languagesMap.set(language.language, language.id));

  const userLanguagesList = useMemo(() => {
    const filterLanguages = languages.filter((ul) => ul?.id !== null);
    return filterLanguages.map((ul) => ul.language);
  }, [languages]);

  const taskList = useMemo(() => {
    const list = [];
    switch (userRole) {
      case "Transcriber":
        list.push(...["Transcribe", { value: "FullFile", label: "Full File" }]);
        break;
      case "QA":
        list.push("QA", "CorrectAI");
        break;
      case "TRQA":
        list.push(...["Transcribe", { value: "FullFile", label: "Full File" }, "QA"]);
        break;
      case "Synthesizer":
        list.push("Synthesize");
        break;
      default:
        break;
    }

    return list;
  }, [userRole]);

  useEffect(() => {
    setUserLanguageRate(selectedRate);
  }, [selectedRate]);

  const handleClose = () => {
    setUserLanguage(null);
    onClose();
  };

  const handleLanguageChange = ({ target: { value } }) => {
    setUserLanguage(value);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUserLanguageRate({
      ...userLanguageRate,
      [name]: value
    });
  };

  const handleSave = async () => {
    const languageId = variant === "new" ? languagesMap.get(userLanguage) : selectedRate.languageId;

    try {
      userLanguageRate.rate = userLanguageRate.rate * 60;
      const result = await saveUserLanguageRate(userLanguageRate, languageId);
      setUserLanguageRate(result.saved);
      loadRatesTable(userId);
      handleClose();
      showNotification("success", `Rate has been saved`);
    } catch (error) {
      let errorMsg;

      if (!userLanguagesList.length) {
        errorMsg = "Please select Languages in the User profile and save it";
      } else if (variant === "new" && !userLanguage) {
        errorMsg = "Please add a Language";
      } else if (!userLanguageRate.rate.trim()) {
        errorMsg = "Please add a Rate";
      } else if (!userLanguageRate.task) {
        errorMsg = "Please add a Task";
      } else {
        errorMsg = error.message;
      }

      showNotification("error", errorMsg);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='xs'>
      <DialogTitle onClose={handleClose}>
        {variant === "new" ? "Create New" : "Edit"} Rate
      </DialogTitle>
      <DialogContent dividers>
        {variant === "new" && (
          <Select
            name='language'
            label='Language'
            value={userLanguage}
            values={userLanguagesList}
            className='input'
            onChange={handleLanguageChange}
          />
        )}
        <TextField
          name='rate'
          label='Rate'
          value={userLanguageRate.rate}
          className='input'
          onChange={handleChange}
        />
        {variant === "new" && (
          <Select
            name='task'
            label='Task'
            value={userLanguageRate.task}
            values={taskList}
            className='input'
            onChange={handleChange}
            itemValue='value'
            itemDisplay='label'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateDialog;
