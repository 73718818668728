const TemplatedComments = [
  {
    category: "Style Guide Comments",
    comments: [
      "See Style Guide regarding numbers",
      "See Style Guide regarding percentages",
      "See Style Guide regarding numbers in close proximity",
      "See Style Guide regarding numbers that start a sentence",
      "See Style Guide regarding fractions and mixed fractions",
      "See Style Guide regarding currency/ISO codes",
      "Use the percent symbol when preceded by a numeral",
      "See Style Guide regarding rankings or order items",
      "See Style Guide regarding units of measure",
      "See Style Guide regarding AlphaViews",
      "Omit stuttering",
      "Omit filler words",
      "Omit repeated words not used for emphasis",
      "Omit obvious false starts that have no meaning to the sentence",
      "False starts end with a single hyphen with one space after",
      "See Style Guide regarding use of profanity"
    ]
  },
  {
    category: "General Formatting",
    comments: [
      "Score lowered due to mishears",
      "Tags incorrectly formatted",
      "Be sure to put company names/terms in a research tag",
      "Time stamps are incorrect",
      "Remove run-ons/fragments, which make sentences hard to read",
      "Ensure questions end with a question mark, and statements end with a period",
      "Use macro to ensure split is ready to upload",
      "Please use context clues to help decipher the audio",
      "Split only partially completed: QA transcribed",
      "Empty template uploaded",
      "Header is incorrect",
      "Use American spelling",
      "Incorrect speaker ID assigned"
    ]
  },
  {
    category: "Positive Comments",
    comments: [
      "Great job overall!",
      "Great job with difficult accent!",
      "Great job with difficult terminology!",
      "Great job with difficult audio!"
    ]
  }
];

export { TemplatedComments };
