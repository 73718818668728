import { USER_ROLES } from "Constants";

const genericTableColumns = [
  { label: "Interaction ID", field: "alphasightsId" },
  { label: "Project ID", field: "projectId" },
  { label: "Duration", field: "length", sortField: "audioSeconds" },
  { label: "Interaction Added", field: "transcriptRequestDate" },
  { label: "Topic", field: "projectTopic" }
];

export const tableColumns = (role) => {
  switch (role) {
    case USER_ROLES.admin:
      return [
        ...genericTableColumns,
        {
          label: "Details",
          field: "interactionNotes",
          unsortable: true
        },
        {
          label: "Status",
          field: "interactionStatus",
          unsortable: true
        }
      ];
    case USER_ROLES.trqa:
    case USER_ROLES.qa:
    case USER_ROLES.proofreader:
      return [...genericTableColumns, { label: "", field: "claimAction" }];
    default:
      return genericTableColumns;
  }
};

export const paginationDefaults = {
  pageSize: 10,
  pageNumber: 0
};

export const sortDefaults = {
  field: "transcriptRequestDate",
  order: "desc"
};
