import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import MuiSelect from '@mui/material/Select';

const Select = (props) => {
  const {
    name,
    label,
    value,
    values,
    className,
    onChange,
    readOnly,
    itemValue,
    itemDisplay,
    itemDisplayFunc,
    ...otherProps
  } = props;

  const menuItems = values.map((item) => {
    const key = (item[itemValue] || item) + '-menu-item';
    return (
      <MenuItem key={key} value={item[itemValue] || item}>
        {itemDisplayFunc(item) || item[itemDisplay] || item}
      </MenuItem>
    );
  });

  return (
    <div className={`input ${className} text-left`}>
      <FormControl variant='standard' {...otherProps}>
        <InputLabel htmlFor={`${name}-input`}>{label}</InputLabel>
        <MuiSelect
          value={value === 0 || value ? value : ''}
          onChange={onChange}
          inputProps={{
            name: `${name}`,
            id: `${name}-input`,
            readOnly: readOnly
          }}>
          {menuItems}
        </MuiSelect>
      </FormControl>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.array.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  itemValue: PropTypes.string,
  itemDisplay: PropTypes.string,
  itemDisplayFunc: PropTypes.func
};

Select.defaultProps = {
  fullWidth: true,
  onChange: () => {},
  itemDisplayFunc: () => null,
  readOnly: false
};

export default Select;
