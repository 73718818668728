import { get, postForm } from "services/ServiceBase";

export const getAuth = () => get("/api/auth");

export const login = (username, password) =>
  postForm("/api/login", {
    username,
    password
  });

export const logout = () => get("/api/logout");
