import React, { useContext, useEffect, useState } from "react";
import { getSummariesHistory, stateToColorClasses } from "services/SummariesService";
import Table from "components/Table";
import { AppContext } from "AppContext";
import Heading from "components/Heading";
import { secondsToHhmmss, utcToLocalDateString } from "utils";
import { isAdmin } from "utils/roles";
import LoadingDialog from "components/LoadingDialog";
import Search from "components/Search";
import { searchUsers } from "services/SearchService";

const columns = [
  {
    label: "Interaction ID",
    field: "alphasightsId",
    sortField: "alphasightsId"
  },
  {
    label: "Project ID",
    field: "projectId",
    sortField: "projectId"
  },
  { label: "Duration", field: "length", sortField: "audioSeconds" },
  {
    label: "Deadline",
    field: "deadline",
    sortField: "claim.deadline"
  }
];

const WorkHistorySummarizer = (props) => {
  const { id, searchString, pagination, changePage, sort, changeSort, unlistenHistory } = props;
  const appContext = useContext(AppContext);
  const [summaries, setSummaries] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSummaries = async () => {
      try {
        setLoading(true);
        let result = await getSummariesHistory(id, pagination, sort, searchString);
        setTotalCount(result.totalCount);
        setSummaries(result.data);
      } catch (error) {
        appContext.showNotification("error", error.message);
        props.history.replace("/");
      } finally {
        setLoading(false);
      }
    };
    loadSummaries();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination, sort, searchString]);

  const goToSummary = (id) => {
    props.history.push(`/summaries/${id}`);
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      {isAdmin(appContext.auth.role) && (
        <Search searchFunc={searchUsers} onSelect={(id) => props.history.push(`/users/${id}`)} />
      )}
      <Heading title={`Synthesized history for user [${id}]`} className='m-5' />
      <Table
        columns={columns}
        data={
          summaries &&
          summaries.map((summary) => ({
            id: summary.id,
            alphasightsId: summary.alphasightsId,
            projectId: summary.projectId,
            length: secondsToHhmmss(summary.audioSeconds),
            deadline: summary.claim ? utcToLocalDateString(summary.claim.deadline, true) : "",
            className: stateToColorClasses(summary.state).secondary
          }))
        }
        totalCount={totalCount || 0}
        onRowSelect={goToSummary}
        pagination={pagination}
        sort={sort}
        onChangePage={changePage}
        onChangeSort={changeSort}
      />
    </div>
  );
};

export default WorkHistorySummarizer;
