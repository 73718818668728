import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Add, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";

export const Accordion = ({ items, addDetailLabel, addDetail, ...otherProps }) => {
  const keyPrefix = "accordion";

  return (
    <div {...otherProps}>
      {items.map((item, index) => (
        <MuiAccordion key={`${keyPrefix}-panel-${index}`}>
          <MuiAccordionSummary
            expandIcon={<ExpandMore />}
            key={`${keyPrefix}-summary-${index}`}
            classes={{ root: item.summaryClass }}>
            {item.summary}
          </MuiAccordionSummary>
          <MuiAccordionDetails
            className={`${item.detailClass} border-t-2`}
            key={`${keyPrefix}-detail-${index}`}>
            {item.detail}
          </MuiAccordionDetails>
        </MuiAccordion>
      ))}
      {addDetail && (
        <MuiAccordion key={`${keyPrefix}-panel-new`}>
          <MuiAccordionSummary expandIcon={<Add />} key={`${keyPrefix}-summary-new`}>
            {addDetailLabel}
          </MuiAccordionSummary>
          <MuiAccordionDetails className='bg-gray-100 border-t-2' key={`${keyPrefix}-detail-new`}>
            {addDetail}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  addDetailLabel: PropTypes.string,
  addDetail: PropTypes.element
};

Accordion.defaultProps = {};

export default Accordion;
