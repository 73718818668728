import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { AppContext } from "AppContext";

import {
  deleteSplit,
  saveSplit,
  downloadTranscript,
  downloadEditedTranscript,
  uploadTranscript,
  uploadEditedTranscript,
  stateToColorClasses,
  cancelSplitClaim
} from "services/SplitsService";
import { offsetDeadline } from "services/ClaimsService";

import {
  doDownload,
  getFileName,
  isTranscriptExpired,
  secondsToHhmmss,
  userToString,
  utcToLocalDateString,
  isJson,
  determineRoleAssignee
} from "utils";
import { isAdmin, isAdminOrInHouseQA } from "utils/roles";
import { interactionCompleted, interactionInProgress } from "utils/interaction";

import { Button, Slider, Paper } from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";

import Accordion from "components/Accordion";
import AssigneeSelect from "components/AssigneeSelect";
import Feedback from "components/Feedback";
import Transcript from "components/Transcript";
import Assessment from "components/Assessment";
import Deadline from "components/Deadline";
import ClaimExtensionsViewer from "components/ClaimExtensionsViewer";
import DelayReason from "components/DelayReason";
import TextField from "components/TextField";

import { DEADLINE_EXTENSION_INCREMENT, SPLIT_HARD_DEADLINE_OFFSET } from "Constants";

const handleDownloadTranscript = async (split, getEdited) => {
  const download = getEdited ? downloadEditedTranscript : downloadTranscript;
  const path = getEdited ? split.editedTranscript.s3FilePath : split.transcript.s3FilePath;
  const file = await download(split.id);
  doDownload(getFileName(path), file);
};

const SplitDetails = ({
  split,
  maxSeconds,
  transcribers,
  onSplitChange,
  onSplitAdded,
  onSplitDeleted
}) => {
  const appContext = useContext(AppContext);

  const splitName = `Split ${split.position}, (${secondsToHhmmss(split.startTimeSecond)})`;

  const handleDeadlineChange = async (extend) => {
    try {
      const result = await offsetDeadline(
        split.claim.id,
        extend ? DEADLINE_EXTENSION_INCREMENT : -DEADLINE_EXTENSION_INCREMENT
      );
      onSplitChange({ ...split, claim: result.saved });
      appContext.showNotification("success", "Deadline modified.");
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const handleSplitSave = async () => {
    try {
      if (split.assessment?.comments && !split.assessment?.totalGrade) {
        throw new Error("Missing Total Grade.");
      }
      const result = await saveSplit(split);
      appContext.showNotification("success", "Split saved");
      if (!split.id) onSplitAdded(result.saved);
      else onSplitChange(result.saved);
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const handleSplitDelete = async () => {
    appContext.showDialog(
      "Delete Split?",
      "Are you certain you wish to delete this Split?",
      doDeleteSplit
    );
  };

  const doDeleteSplit = async () => {
    try {
      await deleteSplit(split.id);
      appContext.showNotification("success", "Split deleted");
      onSplitDeleted(split);
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const secondsSliderChange = (event, newValues) => {
    onSplitChange({
      ...split,
      startTimeSecond: Math.min(...newValues),
      endTimeSecond: Math.max(...newValues)
    });
  };

  const transcriberSelectChange = (event, { id }) => {
    onSplitChange({
      ...split,
      assignee: id ? transcribers.find((transcriber) => transcriber.id === id) : null
    });
  };

  const handleSplitCancel = async () => {
    appContext.showDialog(
      "Cancel Split?",
      "Cancelling will delete all transcribing work done on this split and return it to the queue.",
      () => doSplitCancel(),
      "Don't Cancel",
      "Cancel Split"
    );
  };

  const doSplitCancel = async () => {
    try {
      const result = await cancelSplitClaim(split.id);
      onSplitChange(result.saved);
      appContext.showNotification("success", "Split cancelled and returned to the TR queue.");
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const handleTranscriptUpload = async (file, edited) => {
    try {
      const result = edited
        ? await uploadEditedTranscript(split.id, file)
        : await uploadTranscript(split.id, file);
      appContext.showNotification("success", "Transcript uploaded.");
      onSplitChange({
        ...result.saved,
        assessment: split.assessment
      });
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const handleFeedbackChange = ({ target: { name, value } }) => {
    const feedback = { ...split.feedback, [name]: value };
    onSplitChange({
      ...split,
      feedback
    });
  };

  const handleFeedbackSave = async () => {
    try {
      const result = await saveSplit(split);
      onSplitChange(result.saved);
      appContext.showNotification("success", "Split Feedback saved");
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const handleAssessmentChange = ({ target: { name, value } }) => {
    const assessment = split.assessment || {};
    if (name === "comments") {
      assessment.comments = value;
    } else if (value >= 0) {
      /// As of Oct 2020, Splits have only one grade (total grade) in their assessment rather than
      /// grades across 7 categories.  To avoid changing the schema/structure (in case we wish to
      /// re-introduce these categories to Split assessments), assign the incoming totalGrade to the
      /// underlying categories.
      assessment.speakerIdentification = value;
      assessment.styleGuideAdherence = value;
      assessment.sentenceStructure = value;
      assessment.punctuation = value;
      assessment.wordErrorRate = value;
      assessment.consistency = value;
      assessment.completion = value;
      assessment.totalGrade = value;
    }
    onSplitChange({
      ...split,
      assessment
    });
  };

  const interactionFinished = () =>
    split && split.interaction && interactionCompleted(split.interaction.state);

  return (
    <div className='w-full '>
      <Paper className={`my-5 pb-5 ${split.claim ? "state-color-complete-secondary" : ""}`}>
        <div className='component-heading'>Split Details</div>
        <div className='flex flex-row mt-5 m-5'>
          <div className='w-1/6 text-sm'>{secondsToHhmmss(split.startTimeSecond)}</div>
          <Slider
            value={[split.startTimeSecond, split.endTimeSecond]}
            max={maxSeconds}
            onChange={secondsSliderChange}
            valueLabelDisplay='off'
            disabled={
              split.claim != null ||
              (split.interaction && !["New", "Suspended"].includes(split.interaction.state))
            }
            size='small'
            sx={{
              "& .MuiSlider-thumb": {
                height: 12,
                width: 12
              }
            }}
          />
          <div className='w-1/6 text-sm'>{secondsToHhmmss(split.endTimeSecond)}</div>
        </div>
        <div>
          <div className='flex flex-row items-end mb-8'>
            {transcribers && transcribers.length > 0 && (
              <AssigneeSelect
                disabled={split.claim !== null}
                className='input input-1-2'
                options={transcribers}
                defaultValue={split.claim ? split.claim.claimer : split.assignee}
                label='Transcriber'
                onChange={transcriberSelectChange}
              />
            )}
            {split.transcript && (
              <TextField
                label='Upload Date'
                value={utcToLocalDateString(split.transcript.transcriptDate)}
                className='input-1-2'
                disabled
              />
            )}
          </div>
          <div className='flex items-end flex-wrap justify-center'>
            {split.claim && !split.claim.submissionDate && (
              <div>
                <Deadline
                  claim={split.claim}
                  hardDeadlineOffset={SPLIT_HARD_DEADLINE_OFFSET}
                  size='2xl'
                  onChangeClick={handleDeadlineChange}
                  showDetails={false}
                  className='w-full'
                />
              </div>
            )}
            {split.claim?.extensions?.length > 0 && isAdminOrInHouseQA(appContext.auth.role) && (
              <ClaimExtensionsViewer claimExtensions={split.claim.extensions} />
            )}
            {["Claimed", "Transcribed"].includes(split.state) &&
              interactionInProgress(split.interaction.state) &&
              isAdmin(appContext.auth.role) &&
              !split.interaction?.qaOnly && (
                <div className='ml-4'>
                  <Button variant='outlined' onClick={handleSplitCancel}>
                    Cancel
                  </Button>
                </div>
              )}
          </div>
        </div>
        {split.interaction &&
          ["New", "Suspended"].includes(split.interaction.state) &&
          isAdmin(appContext.auth.role) &&
          !split.interaction?.qaOnly && (
            <div className='flex flex-row justify-center mb-8'>
              <div>
                <Button
                  variant='outlined'
                  onClick={handleSplitSave}
                  disabled={interactionFinished()}>
                  Save
                </Button>
              </div>
              <div className='pl-16'>
                {split.id && isAdmin(appContext.auth.role) && (
                  <Button variant='outlined' onClick={handleSplitDelete}>
                    Delete
                  </Button>
                )}
              </div>
            </div>
          )}
      </Paper>

      {split.id && (
        <div>
          {split.feedback && (
            <div>
              <Feedback
                feedback={split.feedback || {}}
                heading={`Audio feedback for ${splitName}`}
                handleChange={
                  ["Admin", "InHouseQA"].includes(appContext.auth.role)
                    ? handleFeedbackChange
                    : null
                }
                handleSave={handleFeedbackSave}
              />
            </div>
          )}
          {["Admin", "InHouseQA"].includes(appContext.auth.role) && !interactionFinished() && (
            <div>
              <Transcript
                transcript={split.transcript}
                heading={`Transcript for ${splitName}`}
                handleDownload={() => handleDownloadTranscript(split, false)}
                handleUpload={(file) => handleTranscriptUpload(file, false)}
                disabled={split.transcript && isTranscriptExpired(split.transcript.transcriptDate)}
                className='my-5 pb-5'
              />
            </div>
          )}
          {split.claim && split.claim.delayReason && (
            <DelayReason delayReason={split.claim.delayReason} disabled />
          )}
          <div>
            <Transcript
              transcript={split.editedTranscript}
              heading={`Edited Transcript for ${splitName}`}
              handleDownload={() => handleDownloadTranscript(split, true)}
              handleUpload={
                !interactionFinished() ? (file) => handleTranscriptUpload(file, true) : null
              }
              className='my-5 pb-5'
              disabled={
                split.editedTranscript && isTranscriptExpired(split.editedTranscript.transcriptDate)
              }
            />
          </div>
          <div className='mt-6'>
            <Assessment
              assessment={split.assessment || {}}
              heading={`Grades for ${splitName}`}
              handleChange={handleAssessmentChange}
              useTemplatedComments={isJson(split.assessment?.comments || "{}")}
              disabled={
                !split.transcript ||
                (interactionFinished() && !["Admin", "InHouseQA"].includes(appContext.auth.role))
              }
            />
          </div>
        </div>
      )}
      <div className='pt-10 flex flex-row justify-center'>
        <div>
          <Button
            variant='outlined'
            onClick={handleSplitSave}
            disabled={interactionFinished() && !isAdminOrInHouseQA(appContext.auth.role)}>
            Save
          </Button>
        </div>
        <div className='pl-16'>
          {split.id && isAdmin(appContext.auth.role) && (
            <Button variant='outlined' onClick={handleSplitDelete} disabled={split.state !== "New"}>
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const SplitsList = (props) => {
  const {
    maxSeconds,
    splits,
    handleSplitChange,
    newSplit,
    handleNewSplitChange,
    transcribers,
    handleAdditionalSplit,
    handleDeletedSplit,
    ...otherProps
  } = props;

  const appContext = useContext(AppContext);

  const addDetail = () => {
    if (newSplit && isAdmin(appContext.auth.role)) {
      return (
        <SplitDetails
          split={newSplit}
          maxSeconds={maxSeconds}
          transcribers={transcribers}
          onSplitChange={handleNewSplitChange}
          onSplitAdded={handleAdditionalSplit}
        />
      );
    }
  };

  const claimExtensionDisplay = (extensions) => {
    const totalExtensionTime =
      extensions.reduce((prev, current) => (prev += current.extensionSeconds), 0) / 60;
    const prefix = totalExtensionTime > 0 ? "+" : "";
    return `Extensions: ${prefix}${totalExtensionTime}min`;
  };

  return (
    <Accordion
      items={splits.map((split) => {
        const assignedUser = determineRoleAssignee(split.claim, split.assignee);

        return {
          summaryClass: stateToColorClasses(split.state).secondary,
          summary: (
            <div className='w-full flex flex-row flex-wrap text-xs items-center'>
              <div className='mr-2 font-bold'>{split.position}</div>
              <div className='w-1/4 flex flex-row flex-wrap items-center'>
                <div className='w-1/12 mr-1'>{secondsToHhmmss(split.startTimeSecond)}</div>
                <div className='w-1/2 ml-10 mr-2'>
                  <Slider
                    value={[split.startTimeSecond, split.endTimeSecond]}
                    max={maxSeconds}
                    disabled={true}
                    className='mt-2'
                    size='small'
                    sx={{
                      "& .MuiSlider-thumb": {
                        height: 8,
                        width: 8
                      }
                    }}
                  />
                </div>
                <div className='w-1/12'>{secondsToHhmmss(split.endTimeSecond)}</div>
              </div>
              <div className='ml-8 flex flex-row'>
                <span>Duration: </span>
                <span className='text-gray-600 font-bold'>
                  {secondsToHhmmss(split.endTimeSecond - split.startTimeSecond)}
                </span>
              </div>
              <div className='ml-5 w-1/2 flex flex-row justify-around'>
                {split.state !== "New" ? (
                  <Fragment>
                    <span className='text-gray-600 font-bold'>{userToString(assignedUser)}</span>
                    {split.claim && split.state === "Claimed" && (
                      <div className='flex flex-row'>
                        <Deadline
                          claim={split.claim}
                          hardDeadlineOffset={SPLIT_HARD_DEADLINE_OFFSET}
                          size='xs'
                          showDetails={false}
                          className='flex flex-row'
                        />
                      </div>
                    )}
                    {split.claim?.extensions?.length > 0 &&
                      ["Admin", "InHouseQA"].includes(appContext.auth.role) && (
                        <div className='ml-2 text-gray-600'>
                          {claimExtensionDisplay(split.claim.extensions)}
                        </div>
                      )}
                  </Fragment>
                ) : (
                  <span className='text-xs text-gray-500 mr-2'>unassigned</span>
                )}
              </div>
              {split.transcript && !isTranscriptExpired(split.transcript.transcriptDate) && (
                <div>
                  <Button
                    onClick={(event) => {
                      handleDownloadTranscript(split, false);
                      event.stopPropagation();
                    }}>
                    <RateReviewIcon />
                  </Button>
                </div>
              )}
            </div>
          ),
          detailClass: stateToColorClasses(split.state).primary,
          detail: (
            <SplitDetails
              split={split}
              maxSeconds={maxSeconds}
              transcribers={transcribers}
              onSplitChange={handleSplitChange}
              onSplitDeleted={handleDeletedSplit}
            />
          )
        };
      })}
      addDetailLabel='New Split'
      addDetail={addDetail()}
      {...otherProps}
    />
  );
};

SplitsList.propTypes = {
  maxSeconds: PropTypes.number.isRequired,
  splits: PropTypes.array.isRequired,
  handleSplitChange: PropTypes.func.isRequired,
  newSplit: PropTypes.object,
  handleNewSplitChange: PropTypes.func,
  transcribers: PropTypes.array,
  handleAdditionalSplit: PropTypes.func,
  handleDeletedSplit: PropTypes.func
};

SplitsList.defaultProps = {
  maxSeconds: 100,
  handleSplitChange: () => {},
  handleAdditionalSplit: () => {},
  handleDeletedSplit: () => {}
};

export default SplitsList;
