import React from "react";
import PropTypes from "prop-types";
import { WrongLanguageAndReasonText, SubmitButton, LanguageServicesChip } from "../Shared";
import { Checkbox, FormControlLabel } from "@mui/material";
import { DATA_TEST_IDS } from "../constants";
import text from "../text";

const AdminView = ({
  isWrongLanguage,
  loadedReason,
  checkedUpdatedLanguage,
  setCheckedUpdatedLanguage,
  handleSubmit
}) => (
  <>
    <WrongLanguageAndReasonText reason={loadedReason} />
    {isWrongLanguage ? (
      <>
        <FormControlLabel
          value={checkedUpdatedLanguage}
          control={<Checkbox data-testid={DATA_TEST_IDS.updatedLanguageCheckbox} />}
          label={<span className='leading-normal'>{text.languageServicesUpdated}</span>}
          onChange={() => setCheckedUpdatedLanguage(!checkedUpdatedLanguage)}
          className='mt-4'
        />
        <div className='mt-6'>
          <SubmitButton
            disabled={!checkedUpdatedLanguage}
            onClick={() => handleSubmit({ isWrongLanguage: false })}
          />
        </div>
      </>
    ) : (
      <LanguageServicesChip label={text.languageServicesUpdated} />
    )}
  </>
);

AdminView.propTypes = {
  isWrongLanguage: PropTypes.bool,
  loadedReason: PropTypes.string,
  checkedUpdatedLanguage: PropTypes.bool,
  setCheckedUpdatedLanguage: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default AdminView;
