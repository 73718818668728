import { USER_ROLES } from 'Constants';

export const navTabs = (individualContractor) => {
  return {
    [USER_ROLES.admin]: [
      'Dashboard',
      'Transcripts',
      'Synthesized',
      'Users',
      'Grades',
      'Payments',
      'Profile',
      'Settings',
      'Logout'
    ],
    [USER_ROLES.inHouseQa]: ['Transcripts', 'Grades', 'Profile', 'Logout'],
    [USER_ROLES.qa]: [
      'Transcripts',
      'Work History',
      ...(individualContractor ? ['Invoices'] : []),
      ...(individualContractor ? ['Library'] : []),
      'Profile',
      'Logout'
    ],
    [USER_ROLES.proofreader]: [
      'Transcripts',
      'Work History',
      ...(individualContractor ? ['Invoices'] : []),
      ...(individualContractor ? ['Library'] : []),
      'Profile',
      'Logout'
    ],
    [USER_ROLES.trqa]: [
      'Transcripts',
      'Splits',
      'Work History',
      ...(individualContractor ? ['Invoices'] : []),
      'Profile',
      'Logout'
    ],
    [USER_ROLES.transcriber]: [
      'Splits',
      'Work History',
      ...(individualContractor ? ['Invoices'] : []),
      ...(individualContractor ? ['Library'] : []),
      'Profile',
      'Logout'
    ],
    [USER_ROLES.synthesizer]: [
      'Synthesized',
      'Work History',
      ...(individualContractor ? ['Invoices'] : []),
      'Profile',
      'Logout'
    ],
    [USER_ROLES.finance]: ['Payments', 'Profile', 'Logout'],
    [USER_ROLES.groupAdmin]: ['Grades', 'Reports', 'Profile', 'Logout']
  };
};

export const text = {
  library: 'Library',
  transcripts: 'Transcripts',
  logout: 'Logout'
};
