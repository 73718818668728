import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';

import { Button, Checkbox, Chip, Paper } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { getFileName, utcToLocalDateString, uuid } from 'utils';
import { isAdmin, isProofreader } from 'utils/roles';

import { USER_ROLES } from 'Constants';

import InputFile from 'components/InputFile';

export const text = {
  proofreaderReviewed: 'This interaction is reviewed by Proofreader',
  proofreaderReviewCheck: 'Confirm that this interaction has been reviewed by a Proofreader'
};

const Transcript = ({
  heading,
  handleUpload,
  handleDownload,
  interaction,
  transcript,
  className,
  disabled,
  fileType = 'Transcript',
  temporaryTranscriptFile,
  handleSubmitTranscriptUpload,
  hasSubmitButton
}) => {
  const appContext = useContext(AppContext);
  const [proofreaderReviewed, setProofreaderReviewed] = useState(false);

  const { isProofread, requiresProofreading } = interaction || {};

  const needsProofreading = requiresProofreading && isProofreader(appContext.auth.role);

  const transcriptFileFormId =
    process.env.NODE_ENV !== 'test' ? `transcriptForm-${uuid()}` : 'transcriptForm';

  const stateClassName =
    transcript && transcript.id
      ? 'state-color-complete-secondary'
      : 'state-color-actionable-secondary';
  const disabledClassName = disabled ? 'disabled' : '';

  const showSubmitBtn =
    hasSubmitButton &&
    [USER_ROLES.admin, USER_ROLES.inHouseQa, USER_ROLES.proofreader].includes(appContext.auth.role);

  const getTranscriptName = () => {
    if (temporaryTranscriptFile) {
      return temporaryTranscriptFile.name;
    }
    return getFileName(transcript.s3FilePath);
  };

  const handleTranscriptUpload = () => {
    const files = document.getElementById(transcriptFileFormId).files;
    if (files && files.length > 0) {
      handleUpload(files[0]);
    }
  };

  return (
    <Paper className={`${className} ${stateClassName} ${disabledClassName}`}>
      {heading && <div className='component-heading'>{heading}</div>}
      <div className='flex justify-center'>
        <div className='w-2/3'>
          {handleUpload && (
            <div className='flex flex-row justify-start mb-2'>
              <InputFile
                formId={transcriptFileFormId}
                initialLabel={transcript ? `Replace ${fileType}` : `Upload ${fileType}`}
                onChange={handleTranscriptUpload}
                disabled={disabled}
              />
            </div>
          )}
          {transcript && handleDownload && (
            <div className='flex flex-row justify-start items-center'>
              <div>
                <Button variant='outlined' onClick={handleDownload} disabled={disabled}>
                  {getTranscriptName()}
                </Button>
              </div>
              <div className='text-xs ml-2'>
                as of
                {utcToLocalDateString(
                  temporaryTranscriptFile
                    ? temporaryTranscriptFile.lastModifiedDate
                    : transcript.transcriptDate
                )}
              </div>
            </div>
          )}
          {hasSubmitButton && needsProofreading && (
            <div className='flex flex-row justify-center items-baseline mt-5'>
              <div>
                <Checkbox
                  checked={proofreaderReviewed}
                  onChange={() => setProofreaderReviewed((prevValue) => !prevValue)}
                />
              </div>
              <div className='text-xs'>{text.proofreaderReviewCheck}</div>
            </div>
          )}
          {isProofread &&
            (isProofreader(appContext.auth.role) || isAdmin(appContext.auth.role)) && (
              <Chip
                icon={<CheckBoxIcon />}
                key={'reviewed'}
                label={text.proofreaderReviewed}
                className='m-1 bg-checkBoxIconBackground'
              />
            )}
          {showSubmitBtn && (
            <div className='flex justify-center items-center p-8 pb-3'>
              <Button
                variant='outlined'
                onClick={handleSubmitTranscriptUpload}
                disabled={disabled || (needsProofreading && !proofreaderReviewed)}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};

Transcript.propTypes = {
  transcript: PropTypes.object,
  temporaryTranscriptFile: PropTypes.object,
  heading: PropTypes.string,
  handleUpload: PropTypes.func,
  handleDownload: PropTypes.func,
  disabled: PropTypes.bool,
  fileType: PropTypes.string,
  isInaudible: PropTypes.bool,
  handleSubmitTranscriptUpload: PropTypes.func,
  hasSubmitButton: PropTypes.bool
};

export default Transcript;
