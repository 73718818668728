import React, { useContext, useEffect, useState } from 'react';
import { getTranscriberSplits, stateToColorClasses } from 'services/SplitsService';
import Table from 'components/Table';
import { AppContext } from 'AppContext';
import Heading from 'components/Heading';
import GradeValue from 'components/GradeValue';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';
import { interactionCompleted } from 'utils/interaction';
import { searchUsers } from 'services/SearchService';
import { secondsToHhmmss, utcToLocalDateString, isNonEnglish } from 'utils';
import { getGPA } from 'services/UsersService';
import MuiSelect from '@mui/material/Select';
import { Button, MenuItem, FormControl, Tooltip } from '@mui/material';
import { Notes, Translate } from '@mui/icons-material';

const columns = [
  {
    label: 'Interaction ID',
    field: 'interactionId',
    sortField: 'interaction.alphasightsId'
  },
  {
    label: 'Project ID',
    field: 'projectId',
    sortField: 'interaction.projectId'
  },
  {
    label: 'Split time range',
    field: 'timeRange',
    unsortable: true
  },
  {
    label: 'Deadline',
    field: 'deadline',
    sortField: 'claim.deadline'
  },
  { label: 'Grade', field: 'grade', sortField: 'assessment.totalGrade' },
  { label: 'Comments', field: 'comments', unsortable: true }
];

const WorkHistoryTranscriber = (props) => {
  const { id, searchString, pagination, changePage, sort, changeSort, unlistenHistory } = props;
  const appContext = useContext(AppContext);
  const [splits, setSplits] = useState();
  const [gpa, setGpa] = useState(0);
  const [gpaDays, setGpaDays] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSplits = async () => {
      try {
        setLoading(true);
        const result = await getTranscriberSplits(id, pagination, sort, searchString);
        setTotalCount(result.totalCount);
        setSplits(result.data);
      } catch (error) {
        appContext.showNotification('error', error.message);
        props.history.replace('/');
      } finally {
        setLoading(false);
      }
    };
    loadSplits();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination, sort, searchString]);

  useEffect(() => {
    const loadGpa = async () => {
      try {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - gpaDays);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1); // Inclusive of today
        setLoading(true);
        const result = await getGPA(id, startDate, endDate);
        setGpa(isNaN(result) ? 0 : result);
      } catch (error) {
        appContext.showNotification('error', error.message);
        props.history.replace('/');
      } finally {
        setLoading(false);
      }
    };
    loadGpa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpaDays, id]);

  const goToSplit = (id) => {
    props.history.push(`/splits/${id}`);
  };

  const showCommentsDialog = (message) => {
    const comments = JSON.parse(message).comments;
    appContext.showCloseDialog('Comments', comments);
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      {['Admin', 'InHouseQA'].includes(appContext.auth.role) && (
        <Search searchFunc={searchUsers} onSelect={(id) => props.history.push(`/users/${id}`)} />
      )}
      <Heading
        title={`Split transcribe history for user [${id}]`}
        className='m-5'
        secondaryContent={
          <div className='flex flex-row items-center'>
            <div className='text-sm'>
              Average grade
              {appContext.auth.role === 'TRQA' && ' (TR and QA)'} for the past
            </div>
            <div className='mx-2'>
              <FormControl variant='standard'>
                <MuiSelect
                  value={gpaDays}
                  onChange={(event) => {
                    setGpaDays(event.target.value);
                  }}>
                  <MenuItem key='day7' value={7}>
                    7
                  </MenuItem>
                  <MenuItem key='day30' value={30}>
                    30
                  </MenuItem>
                  <MenuItem key='day90' value={90}>
                    90
                  </MenuItem>
                  <MenuItem key='day180' value={180}>
                    180
                  </MenuItem>
                  <MenuItem key='day365' value={365}>
                    365
                  </MenuItem>
                </MuiSelect>
              </FormControl>{' '}
            </div>
            <div>days:</div>
            <GradeValue grade={gpa} />
          </div>
        }
      />
      <Table
        columns={columns}
        data={
          splits &&
          splits.map((split) => ({
            id: split.id,
            interactionId: (
              <div className='flex flex-row items-center rowSelectable'>
                <div>{split.interaction.alphasightsId}</div>
                {isNonEnglish(split.interaction) && (
                  <div className='ml-2 -mt-1'>
                    <Tooltip title='Non-English' placement='top'>
                      <Translate fontSize='inherit' />
                    </Tooltip>
                  </div>
                )}
              </div>
            ),
            projectId: split.interaction.projectId,
            timeRange: `${secondsToHhmmss(split.startTimeSecond)} 
            - ${secondsToHhmmss(split.endTimeSecond)}`,
            grade:
              split.assessment && interactionCompleted(split.interaction.state) ? (
                <GradeValue grade={split.assessment.totalGrade} />
              ) : (
                ''
              ),
            deadline: split.claim ? utcToLocalDateString(split.claim.deadline, true) : '',
            comments:
              split.assessment && split.assessment.comments ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    showCommentsDialog(split.assessment.comments);
                  }}>
                  <Notes />
                </Button>
              ) : null,
            className: stateToColorClasses(split.state).secondary
          }))
        }
        totalCount={totalCount || 0}
        onRowSelect={goToSplit}
        pagination={pagination}
        sort={sort}
        onChangePage={changePage}
        onChangeSort={changeSort}
      />
    </div>
  );
};

export default WorkHistoryTranscriber;
