import { get, post, del } from "services/ServiceBase";

export const getPayments = () => get("/api/payments");

export const submitPayment = (paymentId) => post(`/api/payments/submit/${paymentId}`);

export const processPayment = (paymentId) => post(`/api/payments/process/${paymentId}`);

export const createPayment = (endDate) => {
  return post(`/api/payments/new?endDate=${endDate}`);
};

export const deletePayment = (id) => del(`/api/payments/${id}`);
