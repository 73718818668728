import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Paper } from '@mui/material';
import { AppContext } from 'AppContext';

export const testIds = {
  disclaimer: 'disclaimer'
};

export const text = {
  button: {
    trainingMode: 'Submit for Proofreader Review',
    inaudible: 'Submit for Internal Review',
    generic: 'Submit'
  }
};

const Disclaimer = ({
  onAccepted,
  disabled,
  className,
  message = 'The audio recording of this interaction has been deleted and I confirm no copies have been made. The sole copy of the transcription document has been returned to AlphaSights.',
  isInaudible
}) => {
  const appContext = useContext(AppContext);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const validateAndSave = () => {
    if (!disclaimerAccepted) {
      appContext.showNotification('error', 'You must follow and accept the disclaimer.');
    } else {
      onAccepted();
    }
  };

  const getButtonText = () => {
    if (isInaudible) {
      return text.button.inaudible;
    }
    if (appContext.auth.inTraining) {
      return text.button.trainingMode;
    }
    return text.button.generic;
  };

  return (
    <Paper
      className={`${className} ${disabled ? 'disabled' : ''} state-color-actionable-secondary`}
      data-testid={testIds.disclaimer}>
      <div className='component-heading'>Disclaimer</div>
      <div className='flex flex-col'>
        <div className='flex justify-center'>
          <div className='w-2/3 text-left text-lg'>{message}</div>
        </div>
        <div className='flex flex-row justify-center items-baseline mt-5'>
          <div>
            <Checkbox
              checked={disclaimerAccepted}
              onChange={() => setDisclaimerAccepted((prevValue) => !prevValue)}
              disabled={disabled}
            />
          </div>
          <div className='text-xs'>I have completed the steps outlined in the disclaimer</div>
        </div>
        <div className='p-5'>
          <Button
            variant='outlined'
            onClick={validateAndSave}
            disabled={disabled || !disclaimerAccepted}>
            {getButtonText()}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

Disclaimer.propTypes = {
  onAccepted: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isInaudible: PropTypes.bool,
  message: PropTypes.string
};

export default Disclaimer;
