import { sort, getLanguages } from "utils";

export const formatLoadedUsers = (users, sourceLang, targetLang) => {
  users = users.filter((user) => {
    const languages = getLanguages(user.languages);
    return languages.includes(sourceLang) && languages.includes(targetLang) && user.active;
  });

  users = sort(users, "lastname");

  users.unshift({ id: null });
  return users;
};
