import { get } from "services/ServiceBase";

export const searchInteractions = (searchString) =>
  get(`/api/search/interactions?searchString=${encodeURIComponent(searchString)}`);

export const searchSummaries = (searchString) =>
  get(`/api/search/summaries?searchString=${encodeURIComponent(searchString)}`);

export const searchUsers = (searchString, role = null, getInactive = true) =>
  get(
    `/api/search/users?searchString=${encodeURIComponent(searchString)}${
      role ? `&role=${role}` : ""
    }&getInactive=${getInactive}`
  );
