var hasDocument = typeof document !== 'undefined';
var vendorEvents = [{
    hidden: 'hidden',
    event: 'visibilitychange',
    state: 'visibilityState'
}, {
    hidden: 'webkitHidden',
    event: 'webkitvisibilitychange',
    state: 'webkitVisibilityState'
}, {
    hidden: 'mozHidden',
    event: 'mozvisibilitychange',
    state: 'mozVisibilityState'
}, {
    hidden: 'msHidden',
    event: 'msvisibilitychange',
    state: 'msVisibilityState'
}, {
    hidden: 'oHidden',
    event: 'ovisibilitychange',
    state: 'oVisibilityState'
}];

export var isSupported = hasDocument && Boolean(document.addEventListener);

export var visibility = function () {
    if (!isSupported) {
        return null;
    }
    for (var i = 0; i < vendorEvents.length; i++) {
        var event = vendorEvents[i];
        if (event.hidden in document) {
            return event;
        }
    }
    // otherwise it's not supported
    return null;
}();

export var getVisibilityState = function getVisibilityState(_ref) {
    var hidden = _ref.hidden,
        state = _ref.state;

    return {
        documentHidden: document[hidden],
        visibilityState: document[state]
    };
};

export var getHandlerArgs = function getHandlerArgs() {
    var hidden = visibility.hidden,
        state = visibility.state;

    return [!document[hidden], document[state]];
};