export const utcToDateString = (utcDate) => {
  if (!utcDate) return null;
  const date = new Date(utcDate);
  return date.toUTCString();
};

export const utcToLocalDateString = (utcDate, dateOnly) => {
  if (!utcDate) return null;
  const date = new Date(utcDate);
  return dateOnly
    ? date.toLocaleDateString()
    : date.toLocaleString("default", {
        timeZoneName: "short"
      });
};

export const utcToAbsoluteDateString = (utcDate) => {
  if (!utcDate) return null;
  return utcDate.substring(0, utcDate.indexOf("T")).replace(/-/g, "/");
};

export const isTranscriptExpired = (utcDate) => {
  const transcriptExpirationDays = 7;
  if (!utcDate) return false;
  const expirationDate = new Date(utcDate);
  expirationDate.setDate(expirationDate.getDate() + transcriptExpirationDays);
  return new Date() > expirationDate;
};

export const hhmmssToSeconds = (hhmmss) => {
  /// From: https://stackoverflow.com/a/45292588/223214
  return hhmmss.split(":").reduce((acc, time) => 60 * acc + +time);
};

export const secondsToHhmmss = (seconds) => {
  if (!seconds) return "00:00:00";
  /// From: https://stackoverflow.com/a/34841026
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  const secs = seconds % 60;

  return [hours, minutes, secs].map((v) => (v < 10 ? "0" + v : v)).join(":");
};

export const getFileName = (fullPath) => fullPath.replace(/^.*[\\/]/, "");

export const doDownload = (fileName, blob) => {
  const anchor = document.createElement("a");
  anchor.download = fileName;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
};

export const userToString = (user, idOnly) =>
  user && user.id
    ? idOnly
      ? `TR ID: (${user.id})`
      : `(${user.id}) ${user.firstname || ""} ${user.lastname || ""}`
    : "unassigned";

export const toCurrency = (num, type = "USD") => {
  let value = num || 0;
  value = value.toLocaleString("en-US", {
    style: "currency",
    currency: type
  });

  return `${value}`.replace(",", "");
};

export const toRate = (num, type) => toCurrency(num / 60, type);

export const isNonEnglish = (interaction) => {
  const english = "English";
  return interaction.sourceLanguage !== english || interaction.targetLanguage !== english;
};

export const sort = (values, sortProperty, descending) => {
  const orderingMultiplier = descending ? -1 : 1;
  return values.sort((a, b) =>
    a[sortProperty] > b[sortProperty]
      ? 1 * orderingMultiplier
      : a[sortProperty] < b[sortProperty]
        ? -1 * orderingMultiplier
        : 0
  );
};

// https://stackoverflow.com/a/2117523/223214
// Change to uuid module if Math.random is not reliable enough as suggested in stackoverflow.
export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

export const getLanguages = (userLanguages) => userLanguages.map((ul) => ul.language);

export const determineRoleAssignee = (claim, assignee) => (claim ? claim.claimer : assignee);
