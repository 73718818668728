import React, { useState, useContext, Fragment, useEffect } from 'react';
import TimeField from 'react-simple-timefield';
import { AppContext } from 'AppContext';

import {
  getSummary,
  saveSummary,
  cancelSummaryClaim,
  cancelSummary,
  downloadTranscript,
  uploadTranscript,
  suspendSummary,
  resumeSummary
} from 'services/SummariesService';
import { searchSummaries } from 'services/SearchService';
import { offsetDeadline } from 'services/ClaimsService';
import { getSynthesizers } from 'services/UsersService';

import { LANGUAGES, SUMMARY_HARD_DEADLINE_OFFSET, DEADLINE_EXTENSION_INCREMENT } from 'Constants';

import {
  utcToLocalDateString,
  hhmmssToSeconds,
  secondsToHhmmss,
  doDownload,
  getFileName,
  userToString,
  isTranscriptExpired,
  sort,
  getLanguages
} from 'utils';
import { isAdmin } from 'utils/roles';
import { interactionInProgress } from 'utils/interaction';

import Heading from 'components/Heading';
import Select from 'components/Select';
import TextField from 'components/TextField';
import Deadline from 'components/Deadline';
import ClaimExtensionsViewer from 'components/ClaimExtensionsViewer';
import Transcript from 'components/Transcript';
import Feedback from 'components/Feedback';
import Disclaimer from 'components/Disclaimer';
import DelayReason from 'components/DelayReason';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';

import { Button, Grid, Paper, Checkbox } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

import moment from 'moment';

export const SummaryDetails = (props) => {
  const [summary, setSummary] = useState({});
  const [synthesizers, setSynthesizers] = useState([]);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  const handleChange = ({ target: { name, value } }) => {
    setSummary({
      ...summary,
      [name]: value
    });
  };

  const handleAudioLengthChange = (value) => {
    setSummary({
      ...summary,
      audioSeconds: hhmmssToSeconds(value)
    });
  };

  const {
    auth: { role, id }
  } = appContext;

  const showDeadline = () => {
    return variant.showDeadline && (isAdmin(role) || summary.state !== 'InProgress');
  };

  const isDeadlineExtendable = () => {
    return summary.claim.claimer.id === id;
  };

  useEffect(() => {
    const loadSynthesizers = async () => {
      try {
        let data = (await getSynthesizers()).data;
        data = data.filter((synthesizer) => {
          const languages = getLanguages(synthesizer.languages);
          return (
            languages.includes(summary?.sourceLanguage) &&
            languages.includes(summary?.targetLanguage) &&
            synthesizer.active
          );
        });
        data = sort(data, 'lastname');
        data.unshift({ id: null });
        setSynthesizers(data);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };
    if (isAdmin(role)) {
      loadSynthesizers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary?.id]);

  const handleDeadlineChange = async (extend) => {
    try {
      setLoading(true);
      const result = await offsetDeadline(
        summary.claim.id,
        extend ? DEADLINE_EXTENSION_INCREMENT : -DEADLINE_EXTENSION_INCREMENT
      );
      setSummary({ ...summary, claim: result.saved });
      appContext.showNotification('success', 'Deadline modified.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSummarySave = async (successMessage) => {
    let leaving = false;
    try {
      setLoading(true);
      const response = await saveSummary(summary);
      if (variant.heading === variants.new.heading) {
        leaving = true;
        props.history.push(`/synthesized/${response.id}`);
      }
      appContext.showNotification('success', successMessage);
      setSummary(response.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleSummaryCancelClaim = async () => {
    appContext.showDialog(
      'Cancel Synthesized Transcript Claim?',
      'Cancelling will delete all work done on this synthesized transcript and return it to the queue.',
      () => doSummaryCancelClaim(),
      "Don't Cancel",
      'Cancel Claim'
    );
  };

  const handleSuspend = async () => {
    const action = summary.suspended ? resumeSummary : suspendSummary;
    try {
      setLoading(true);
      const result = await action(summary.id);
      appContext.showNotification(
        'success',
        `Synthesized ${result.saved.suspended ? 'suspended' : 'resumed'} successfully.`
      );
      setSummary(result.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const doSummaryCancelClaim = async () => {
    try {
      setLoading(true);
      const result = await cancelSummaryClaim(summary.id);
      appContext.showNotification(
        'success',
        'Synthesized transcript cancelled and returned to the queue.'
      );
      setSummary(result);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSummaryCancel = () => {
    appContext.showDialog(
      'Cancel Synthesized Transcript?',
      `Cancelling deletes all work done on this synthesized transcript.  Do you wish to continue?`,
      doCancel,
      "Don't Cancel",
      'Cancel Synthesized'
    );
  };

  const doCancel = async () => {
    let leaving = false;
    try {
      setLoading(true);
      await cancelSummary(summary.id);
      leaving = true;
      props.history.push('/synthesized');
      appContext.showNotification('success', `Synthesized [${summary.id}] cancelled`);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleDownloadTranscript = async () => {
    const file = await downloadTranscript(summary.id);
    doDownload(getFileName(summary.transcript.s3FilePath), file);
  };

  const handleTranscriptUpload = async (file) => {
    try {
      setLoading(true);
      const result = await uploadTranscript(summary.id, file);
      setSummary(result.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackChange = ({ target: { name, value } }) => {
    const feedback = { ...summary.feedback, [name]: value };
    setSummary({
      ...summary,
      feedback
    });
  };

  const handleClaimChange = ({ target: { name, value } }) => {
    const claim = { ...summary.claim, [name]: value };
    setSummary({
      ...summary,
      claim
    });
  };

  const handleDisclaimerAccepted = async () => {
    let leaving = false;
    try {
      setLoading(true);
      summary.claim.submissionDate = moment();
      await saveSummary(summary);
      leaving = true;
      appContext.showNotification('success', 'Synthesized Transcript Completed.');
      props.history.push('/synthesized');
    } catch (error) {
      summary.claim.submissionDate = null;
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const synthesizerSelectChanged = async ({ target: { value } }) => {
    summary.assignee = {
      id: isNaN(value) ? null : value
    };
    try {
      setLoading(true);
      const response = await saveSummary(summary);
      appContext.showNotification('success', 'Synthesizer assigned.');
      setSummary(response.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const variants = {
    new: {
      heading: 'New Synthesized',
      showDeadline: false,
      showAdminActions: false,
      readOnly: false
    },
    details: {
      heading: 'Synthesized Details',
      showDeadline: true,
      showAdminActions: isAdmin(role),
      readOnly: true
    }
  };

  const [variant, setVariant] = useState({});
  if (!variant.heading) {
    setVariant(variants[props.variant]);
  } else if (variants[props.variant].heading !== variant.heading) {
    setVariant(variants[props.variant]);
    setSummary({ id: null }); // Reset summary
  }

  useEffect(() => {
    const loadSummary = async (id) => {
      let leaving = false;
      try {
        setLoading(true);
        const loadedSummary = await getSummary(id);
        if (!loadedSummary) {
          throw Error(`Could not find synthesized with id ${id}`);
        }
        setSummary(loadedSummary);
      } catch (error) {
        appContext.showNotification('error', error.message);
        leaving = true;
        props.history.push('/synthesized');
      } finally {
        if (!leaving) setLoading(false);
      }
    };

    if (props.match.params.id) loadSummary(props.match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      {isAdmin(role) && variant.heading !== variants.new.heading && (
        <Search
          searchFunc={searchSummaries}
          onSelect={(id) => props.history.push(`/synthesized/${id}`)}
        />
      )}
      {summary && (
        <div className='p-8'>
          <Paper className='pb-8 mb-6'>
            <Heading title={variant.heading} className='p-5' />
            {summary.state === 'Cancelled' && (
              <div className='mb-5 state-color-cancel-secondary text-2xl font-bold'>
                CANCELLED ON {utcToLocalDateString(summary.cancelDate)}
              </div>
            )}
            {summary.suspended && (
              <div className='mb-5 state-color-actionable text-2xl font-bold'>SUSPENDED</div>
            )}
            <Grid container direction='column'>
              <Grid item>
                {summary.state === 'InProgress' && (
                  <div className='flex justify-center'>
                    <div className='ml-5 w-full flex flex-row items-baseline'>
                      <div className='w-1/2 flex justify-center'>
                        <Deadline
                          claim={summary.claim}
                          hardDeadlineOffset={SUMMARY_HARD_DEADLINE_OFFSET}
                          onChangeClick={isAdmin(role) ? handleDeadlineChange : null}
                          showDetails={true}
                          extendable={isDeadlineExtendable()}
                          extensionsViewable
                        />
                      </div>
                      {isAdmin(role) && (
                        <div className='flex flex-row items-center'>
                          <div className='text-xs mr-4'>
                            <span>Claimed by:</span>
                            <span className='ml-1 font-bold'>
                              {userToString(summary.claim.claimer)}
                            </span>
                          </div>
                          <Button variant='outlined' onClick={handleSummaryCancelClaim}>
                            Cancel
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item>
                <div className='grid-row flex justify-center items-end'>
                  {props.variant === 'new' ? (
                    <TextField
                      name='audioUrl'
                      label='Audio URL'
                      value={summary.audioUrl}
                      className='input-1-3'
                      onChange={handleChange}
                      inputProps={{ readOnly: variant.readOnly }}
                    />
                  ) : (
                    <div className='input-1-3'>
                      <Button
                        variant='outlined'
                        href={`/api/summaries/audio/${summary.id}`}
                        startIcon={<MicIcon />}
                        size='large'
                        disabled={!isAdmin(role) && summary.state !== 'InProgress'}
                        download>
                        Audio file
                      </Button>
                    </div>
                  )}
                  <div className='w-1/3'>
                    {summary.state === 'Completed' && summary.claim?.extensions?.length > 0 && (
                      <div className='w-1/2 flex justify-start pl-6'>
                        <ClaimExtensionsViewer claimExtensions={summary.claim.extensions} />
                      </div>
                    )}
                  </div>
                </div>
                <div className='w-1/3' />
              </Grid>
              <Grid item>
                <div className='grid-row flex justify-center'>
                  <TextField
                    name='alphasightsId'
                    label='Interaction ID'
                    value={summary.alphasightsId}
                    className='input-1-3'
                    onChange={handleChange}
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                  <TimeField
                    name='audioSeconds'
                    value={secondsToHhmmss(summary.audioSeconds)}
                    onChange={handleAudioLengthChange}
                    input={<TextField name='audioSeconds' label='Audio Duration' />}
                    showSeconds={true}
                    className='input-1-3'
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                </div>
              </Grid>
              {showDeadline() && (
                <Grid item>
                  <div className='grid-row flex justify-center'>
                    <TextField
                      name='requestDate'
                      label='Synthesized Added On'
                      value={utcToLocalDateString(summary.requestDate)}
                      className='input-1-3'
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      name='deadline'
                      label='Synthesized Deadline'
                      value={utcToLocalDateString(summary.deadline)}
                      className='input-1-3'
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </Grid>
              )}
              <Grid item>
                <div className='grid-row flex justify-center'>
                  <TextField
                    name='projectId'
                    label='Project ID'
                    value={summary.projectId}
                    className='input-1-3'
                    onChange={handleChange}
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                  <TextField
                    name='projectTopic'
                    label='Project Topic'
                    value={summary.projectTopic}
                    className='input-1-3'
                    onChange={handleChange}
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                </div>
              </Grid>
              <Grid item>
                <div className='grid-row flex justify-center'>
                  <Select
                    name='sourceLanguage'
                    label='Source Language'
                    value={summary.sourceLanguage}
                    values={LANGUAGES}
                    className='input-1-3'
                    onChange={handleChange}
                  />
                  <Select
                    name='targetLanguage'
                    label='Target Language'
                    value={summary.targetLanguage}
                    values={LANGUAGES}
                    className='input-1-3'
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className='grid-row flex justify-center mt-4'>
                  <TextField
                    name='notes'
                    label='Details'
                    value={summary.notes}
                    multiline
                    variant='outlined'
                    className='input-2-3'
                    onChange={handleChange}
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                </div>
              </Grid>
              {isAdmin(role) && (
                <Fragment>
                  <Grid item xs={12}>
                    <div className='grid-row flex justify-center items-end mb-6'>
                      {synthesizers && synthesizers.length > 0 && summary && summary.id && (
                        <Fragment>
                          <Select
                            name='assignee'
                            label='Synthesizer'
                            value={
                              summary.claim
                                ? summary.claim.claimer.id
                                : summary.assignee
                                  ? summary.assignee.id
                                  : ''
                            }
                            values={synthesizers}
                            className='input-1-3'
                            itemValue='id'
                            itemDisplayFunc={(user) => userToString(user)}
                            onChange={synthesizerSelectChanged}
                            disabled={summary.claim != null}
                          />
                        </Fragment>
                      )}
                      <div className='input-1-3'>
                        <Checkbox
                          checked={summary.priority || false}
                          onChange={(event, value) =>
                            handleChange({
                              target: { name: 'priority', value }
                            })
                          }
                          value={summary.priority}
                          color='primary'
                          disabled={!interactionInProgress(summary.state)}
                        />
                        <span
                          className={`text-gray-${
                            summary.priority ? '8' : '5'
                          }00 text-xs font-bold`}>
                          Priority
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item className='flex flex-row justify-center'>
                    {(!variant.readOnly || interactionInProgress(summary.state)) && (
                      <div>
                        <Button
                          variant='outlined'
                          onClick={() => handleSummarySave('Synthesized saved.')}>
                          Save
                        </Button>
                      </div>
                    )}
                    {variant.showAdminActions && interactionInProgress(summary.state) && (
                      <Fragment>
                        <div className='px-16'>
                          <Button variant='outlined' onClick={handleSuspend}>
                            {summary.suspended ? 'Resume' : 'Suspend'}
                          </Button>
                        </div>
                        <div>
                          <Button variant='outlined' onClick={handleSummaryCancel}>
                            Cancel Synthesized
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Paper>
          {summary.id && summary.state !== 'Cancelled' && (
            <Fragment>
              <Feedback
                feedback={summary.feedback || {}}
                heading='Audio Feedback'
                handleChange={handleFeedbackChange}
                handleSave={() => handleSummarySave('Feedback saved.')}
                requireMissingWords={false}
                provideAccents
                disabled={summary.state === 'Completed' && !isAdmin(role)}
              />
              <Transcript
                transcript={summary.transcript}
                heading='Synthesized Transcript File'
                handleUpload={
                  summary.state !== 'Completed' || isAdmin(role)
                    ? (file) => handleTranscriptUpload(file, false)
                    : null
                }
                handleDownload={() => handleDownloadTranscript(false)}
                className='my-5 pb-5'
                disabled={
                  !summary.feedback ||
                  !summary.feedback.id ||
                  (summary.transcript && isTranscriptExpired(summary.transcript.transcriptDate))
                }
                fileType='Synthesized'
              />
              {summary.claim &&
                (summary.claim.delayReason ||
                  (moment(summary.claim.finalEyeDeadline) < moment() &&
                    !summary.claim.submissionDate)) && (
                  <DelayReason
                    delayReason={summary.claim.delayReason}
                    onDelayReasonChange={handleClaimChange}
                    className='my-5 pb-5'
                  />
                )}
              {summary.state === 'InProgress' && (
                <Disclaimer
                  onAccepted={handleDisclaimerAccepted}
                  className='my-5 pb-5'
                  disabled={
                    !summary.feedback ||
                    !summary.feedback.id ||
                    !summary.transcript ||
                    !summary.transcript.id
                  }
                  message='The audio recording of this interaction has been deleted and I confirm no copies have been made. The sole copy of the synthesized transcript document has been returned to AlphaSights. I did not include my personal opinions, perspectives, professional advice, or any information which I obtained from other sources than the call recording in the Interaction.'
                />
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SummaryDetails;
