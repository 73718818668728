import React, { useEffect, useState, useContext } from 'react';
import { getBankDetails } from 'services/UsersService';
import { Dialog, DialogTitle } from '@mui/material';
import TextField from 'components/TextField';
import { AppContext } from 'AppContext';
import { isAdmin } from 'utils/roles';

export const BankDialog = (props) => {
  const appContext = useContext(AppContext);
  const { userId, onClose, open } = props;
  const [bankDetails, setBankDetails] = useState({});

  useEffect(() => {
    const loadBankDetails = async () => {
      if (userId) {
        const result = await getBankDetails(userId);
        setBankDetails(result);
      }
    };
    loadBankDetails();
  }, [userId]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xs'>
      <DialogTitle>Bank Details</DialogTitle>
      <div className='mb-8 flex flex-row'>
        <div>
          <TextField
            label='Bank Country'
            value={bankDetails.bankCountryCode}
            className='input'
            disabled={true}
          />
          <TextField
            label='Bank Name'
            value={bankDetails.bankName}
            className='input'
            disabled={true}
          />
          <TextField
            label={bankDetails.bankCountryCode === 'US' ? 'Routing #' : 'Swift code'}
            value={bankDetails.bankId}
            className='input'
            disabled={true}
          />
        </div>
        <div>
          {bankDetails.bankCountryCode === 'CA' && (
            <TextField
              label='Transit Number'
              value={bankDetails.bankTransitNumber}
              className='input'
              disabled={true}
            />
          )}
          <TextField
            label='Name on the Account'
            value={bankDetails.bankAccountName}
            className='input'
            disabled={true}
          />
          <TextField
            label={
              bankDetails.bankCountryCode === 'AU' ? 'BSB and Account number' : 'Account # / IBAN'
            }
            value={bankDetails.bankAccountNumber}
            className='input'
            disabled={true}
          />
          {bankDetails.bankCountryCode === 'US' && (
            <TextField
              label='Account Type'
              value={bankDetails.bankAccountType}
              className='input'
              disabled={true}
            />
          )}
          <TextField
            label='Currency'
            value={bankDetails.currency || 'USD'}
            className='input'
            disabled={true}
          />
        </div>
      </div>
      {isAdmin(appContext.auth.role) && (
        <div className='mb-5 flex justify-center link'>
          <a href={`/users/${userId}`}>Go to user&apos;s details</a>
        </div>
      )}
    </Dialog>
  );
};

BankDialog.defaultProps = {
  userId: {},
  open: false
};

export default BankDialog;
