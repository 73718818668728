import { TASKS } from "../../Constants";

// Grade Periods expressed in days
export const TRANSCRIBE_GRADE_PERIOD = 30;
export const QA_GRADE_PERIOD = 180;

const QA_OR_CORRECTOR_COLUMNS = [
  { label: "ID", field: "id", sortField: "user.id" },
  { label: "Email", field: "email", sortField: "user.email" },
  { label: `Grade (past ${QA_GRADE_PERIOD} days)`, field: "grade" },
  { label: "Next Grade Date", field: "nextGradeDate" }
];
export const COLUMN_DEFINITIONS = {
  [TASKS.transcribe]: [
    { label: "ID", field: "id", sortField: "user.id" },
    { label: "Email", field: "email", sortField: "user.email" },
    { label: `Grade (past ${TRANSCRIBE_GRADE_PERIOD} days)`, field: "grade" }
  ],
  [TASKS.qa]: QA_OR_CORRECTOR_COLUMNS,
  [TASKS.correctAI]: QA_OR_CORRECTOR_COLUMNS
};

export const PAGINATION_DEFAULTS = {
  pageSize: 10,
  pageNumber: 0
};
export const SORT_DEFAULTS = {
  field: "user.id",
  order: "asc"
};
