import { get } from "services/ServiceBase";

export const getDailySnapshot = (englishOnly) => {
  let url = "/api/reports/dailysnapshot";
  if (englishOnly !== null) {
    url += `?englishOnly=${englishOnly}`;
  }
  return get(url);
};

export const getSnapshot = (startDate, endDate, englishOnly) => {
  let url = `/api/reports/snapshot?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}`;
  if (englishOnly !== null) {
    url += `&englishOnly=${englishOnly}`;
  }
  return get(url);
};

export const getSummariesDailySnapshot = () => get("/api/reports/summaries/dailysnapshot");

export const getSummariesSnapshot = (startDate, endDate) => {
  let url = `/api/reports/summaries/snapshot?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}`;
  return get(url);
};
