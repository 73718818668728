import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InputRating from 'components/InputRating';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { ACCENTS } from 'Constants';
import { Autocomplete, Checkbox } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { AppContext } from 'AppContext';
import Wrapper from 'components/Wrapper';
import { saveVariant } from '../AITranscript/constants';

const flexItemStyling = 'sm:w-1/4 w-full';
const flexItemNumberOfStyling = { maxWidth: '13.75rem' };

const audio = 'audio';
const qualityValues = {
  audio: {
    name: 'audioQuality',
    label: 'Audio Quality'
  },
  transcript: {
    name: 'transcriptQuality',
    label: 'Transcript Quality'
  }
};

export const DATA_TEST_IDS = {
  numberOfSpeakersSelect: 'number-of-speakers'
};

const Feedback = (props) => {
  const {
    feedback,
    heading,
    handleChange,
    handleSave,
    className,
    requireMissingWords,
    provideAccents,
    disabled,
    isSecondReviewCheckboxVisible,
    acceptedSecondReview,
    setAcceptedSecondReview,
    variant,
    dataTestId
  } = props;
  const appContext = useContext(AppContext);

  const stateClassName = disabled || feedback?.id ? 'state-color-complete-secondary' : '';
  const selectedAccents = ACCENTS.filter(
    (accent) => feedback && feedback.accents && feedback.accents.includes(accent.value)
  );

  const readOnly = !handleChange || disabled;

  // Rating out of 5
  const qualityValue = variant === audio ? feedback.audioQuality : feedback.transcriptQuality;

  // Values for the quality that vary depending on audio or transcript quality
  const quality = {
    ...qualityValues[variant],
    value: qualityValue
  };

  const validateAndSave = () => {
    if (
      !qualityValue ||
      !feedback.speakers ||
      /// cannot rely on !feedback.missingWords since 0 is a valid for missingWords
      (requireMissingWords && isNaN(parseInt(feedback.missingWords)))
    ) {
      appContext.showNotification('error', 'Feedback incomplete.');
    } else {
      handleSave();
    }
  };

  return (
    <Wrapper
      header={heading}
      button={{ variant: saveVariant, onClick: validateAndSave, disabled }}
      className={`${className || ''}${stateClassName}`}
      disabled={disabled ? 'disabled' : ''}
      dataTestId={dataTestId}>
      <div className='flex justify-between flex-row flex-wrap mx-6'>
        <div className={flexItemStyling} style={{ maxWidth: '9.5rem' }}>
          <InputRating
            name={quality.name}
            value={quality.value}
            label={quality.label}
            disabled={readOnly}
            onChange={handleChange}
            className='pt-5'
          />
        </div>
        <div className={flexItemStyling} style={flexItemNumberOfStyling}>
          <Select
            name='speakers'
            label='# of speakers'
            value={feedback.speakers}
            values={Array.from({ length: 5 }, (v, i) => i + 1)}
            readOnly={readOnly}
            onChange={handleChange}
            data-testid={DATA_TEST_IDS.numberOfSpeakersSelect}
          />
        </div>
        {requireMissingWords && (
          <div className={flexItemStyling} style={flexItemNumberOfStyling}>
            <TextField
              name='missingWords'
              label='# of missing words'
              value={feedback.missingWords}
              readOnly={readOnly}
              onChange={handleChange}
            />
          </div>
        )}
        {provideAccents && (
          <div className={flexItemStyling} style={{ maxWidth: '26rem' }}>
            <Autocomplete
              className='pt-5'
              multiple
              filterSelectedOptions
              options={ACCENTS}
              value={selectedAccents}
              getOptionLabel={({ display }) => display}
              isOptionEqualToValue={(option, accent) =>
                option && accent && option.value.includes(accent.value)
              }
              renderInput={(params) => <MuiTextField {...params} label='Accents' />}
              onChange={(_event, accents) => {
                handleChange({
                  target: {
                    name: 'accents',
                    value: accents.map(({ value }) => value)
                  }
                });
              }}
              disabled={readOnly}
            />
          </div>
        )}
      </div>
      <div className='mt-8 pb-8 mx-6'>
        <TextField
          name='comments'
          label='Comments'
          value={feedback.comments}
          multiline
          variant='outlined'
          disabled={readOnly}
          onChange={handleChange}
          fullWidth
          placeholder={'Comments'}
          className='bg-inputBackground input-no-padding'
        />
        {isSecondReviewCheckboxVisible && (
          <div className='flex justify-center items-center mt-8'>
            <div>
              <Checkbox
                checked={acceptedSecondReview}
                disabled={!handleChange || disabled}
                onChange={() => setAcceptedSecondReview((prevValue) => !prevValue)}
              />
            </div>
            <div className='text-xs'>
              The number of inaudibles in this transcript exceeds the acceptable limit and requires
              a second review. (This is only applicable for AlphaViews)
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

Feedback.propTypes = {
  feedback: PropTypes.object.isRequired,
  heading: PropTypes.string,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  requireMissingWords: PropTypes.bool,
  provideAccents: PropTypes.bool,
  disabled: PropTypes.bool,
  isSecondReviewCheckboxVisible: PropTypes.bool
};

Feedback.defaultProps = {
  requireMissingWords: true,
  provideAccents: false,
  isSecondReviewCheckboxVisible: false,
  acceptedSecondReview: false,
  setAcceptedSecondReview: () => {},
  variant: audio
};

export default Feedback;
