import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

import { goodQuality, lowQuality } from '../../constants';
import { COLOURS } from 'Constants';

const formStyles = {
  marginTop: '1rem',
  '& .MuiTypography-root': {
    color: COLOURS.customBlack,
    fontSize: '0.875rem',
    fontWeight: '400',
    marginLeft: '0.25rem'
  },
  '& .MuiButtonBase-root': {
    padding: '0'
  },
  '& .MuiRadio-root.Mui-checked': {
    color: COLOURS.customBlack
  }
};

const FormControlLabels = ({ qualities }) =>
  qualities.map((quality) => {
    const isGood = quality === goodQuality.value;
    const { textChunks } = isGood ? goodQuality : lowQuality;
    return (
      <FormControlLabel
        value={quality}
        control={<Radio />}
        label={
          <>
            {textChunks.map((chunk, index) => (
              <span className={`${index === 1 ? 'font-bold' : ''} mr-1`} key={index}>
                {chunk}
              </span>
            ))}
          </>
        }
        className='text-customBlack'
        sx={formStyles}
        key={quality}
      />
    );
  });

export default FormControlLabels;
