export const TEXT_FIELDS = [
  { name: "email", label: "Email" },
  { name: "password", label: "Password" },
  { name: "firstname", label: "First Name" },
  { name: "lastname", label: "Last Name" },
  null,
  { name: "address1", label: "Address Line 1" },
  { name: "address2", label: "Address Line 2" },
  { name: "address3", label: "Address Line 3" },
  { name: "city", label: "City" },
  { name: "state", label: "State" },
  { name: "postalCode", label: "Postal Code" },
  { name: "country", label: "Country" },
  { name: "phone", label: "Phone" }
];

export const URL_VARIANT = {
  new: {
    roleEmailReadOnly: false,
    heading: "New User",
    showDeleteAction: false
  },
  profile: {
    roleEmailReadOnly: true,
    heading: "Edit Profile",
    showDeleteAction: false
  },
  details: {
    roleEmailReadOnly: false,
    heading: "Edit User",
    showDeleteAction: true
  }
};

export const REVIEW_PERCENTAGE = /^(?:100|[1-9][0-9]?|0)$/;

export const DEFAULT_RATE_COLUMNS = [
  { label: "Rate", field: "rate" },
  { label: "Task", field: "task" },
  { label: "Currency", field: "currency" },
  { label: "Time", field: "time" },
  { label: "Language", field: "language" }
];

export const DEFAULT_USER = {
  id: null,
  active: true,
  languages: [{ id: null, language: "English" }],
  individualContractor: false
};

export const DEFAULT_RATES = {
  id: 0,
  rate: "",
  task: ""
};
