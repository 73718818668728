import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

import text from "./text";
import FormToSubmitWrongLanguage from "./components/FormToSubmitWrongLanguage";
import AdminView from "./components/AdminView";
import NonAdminView from "./components/NonAdminView";

const WrongLanguage = ({ handleSubmit, isAdmin, isWrongLanguage = null, loadedReason }) => {
  const [checkedWrongLanguage, setCheckedWrongLanguage] = useState(isWrongLanguage);
  const [checkedUpdatedLanguage, setCheckedUpdatedLanguage] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <Paper elevation={0} className='border-t m-6 py-6 flex flex-col items-center'>
      <span className='text-gray-600 font-bold mr-auto'>{text.title}</span>
      {isWrongLanguage === null ? (
        <FormToSubmitWrongLanguage
          checkedWrongLanguage={checkedWrongLanguage}
          setCheckedWrongLanguage={setCheckedWrongLanguage}
          setReason={setReason}
          reason={reason}
          handleSubmit={handleSubmit}
        />
      ) : isAdmin ? (
        <AdminView
          isWrongLanguage={isWrongLanguage}
          loadedReason={loadedReason}
          checkedUpdatedLanguage={checkedUpdatedLanguage}
          setCheckedUpdatedLanguage={setCheckedUpdatedLanguage}
          handleSubmit={handleSubmit}
        />
      ) : (
        <NonAdminView isWrongLanguage={isWrongLanguage} loadedReason={loadedReason} />
      )}
    </Paper>
  );
};

WrongLanguage.propTypes = {
  isWrongLanguage: PropTypes.bool,
  loadedReason: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired
};

export default WrongLanguage;
