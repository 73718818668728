import { INTERACTION_STATES } from "Constants";

export const interactionInProgress = (state) =>
  ![INTERACTION_STATES.completed, INTERACTION_STATES.cancelled].includes(state);

export const interactionClaimed = (state) =>
  [
    INTERACTION_STATES.inProgressQA.value,
    INTERACTION_STATES.inProgressCorrector.value,
    INTERACTION_STATES.inProgressProofreader.value
  ].includes(state);

export const interactionCompleted = (state) => state === INTERACTION_STATES.completed;

export const interactionInProofreading = (state) =>
  state === INTERACTION_STATES.inProgressProofreader.value;

export const interactionReadyForCorrector = (state) =>
  state === INTERACTION_STATES.correctorReady.value;

export const interactionReadyForProofreader = (state) =>
  state === INTERACTION_STATES.proofreaderReady.value;

export const interactionReadyForQa = (state) => state === INTERACTION_STATES.qaReady.value;

export const interactionInProgressProofreader = (state) =>
  state === INTERACTION_STATES.inProgressProofreader.value;
