import React, { useState, useContext, Fragment } from "react";
import { TextField } from "components/TextField";
import { AppContext } from "AppContext";
import Button from "@mui/material/Button";
import { login } from "services/AuthService";
import { requestPasswordReset, resetPassword } from "services/UsersService";

const Login = (props) => {
  const [credentials, setCredentials] = useState({});
  const appContext = useContext(AppContext);
  let resetPasswordToken = null;
  if (props.location && props.location.search) {
    resetPasswordToken = new URLSearchParams(props.location.search).get("resetPasswordToken");
  }

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({
      ...credentials,
      [name]: value
    });
  };

  const doLoginIfEnterKey = (event) => {
    if (event.key === "Enter") doLogin();
  };

  const doLogin = async () => {
    try {
      await login(credentials.email, credentials.password);
      window.location.reload();
    } catch (error) {
      appContext.showNotification("error", error.message);
    }
  };

  const doPasswordReset = async () => {
    if (!credentials.email) {
      showPasswordResetDialog(
        "Enter your email above to verify your email for this password change."
      );
    } else {
      try {
        await resetPassword(credentials.email, resetPasswordToken, credentials.password);
        appContext.showNotification(
          "success",
          "Password changed.  Login with your new password to proceed."
        );
        props.history.replace("/");
      } catch (error) {
        appContext.showNotification("error", error.message);
      }
    }
  };

  const doRequestPasswordReset = async () => {
    if (!credentials.email) {
      showPasswordResetDialog("Enter your email above to request a password reset.");
    } else {
      try {
        await requestPasswordReset(credentials.email);
        appContext.showNotification("success", `Reset password link sent to ${credentials.email}`);
      } catch (error) {
        appContext.showNotification("error", error.message);
      }
    }
  };

  const showPasswordResetDialog = (message) => {
    appContext.showCloseDialog("Password Reset", message);
  };

  return (
    <Fragment>
      {!appContext.auth && (
        <div className='flex justify-center'>
          <div className='w-full sm:w-1/3 mt-12'>
            <div className='text-gray-700 text-2xl font-bold font-sans'>
              {resetPasswordToken ? "Change your AlphaScribes Password" : "Login to AlphaScribes"}
            </div>
            <div>
              <TextField
                name='email'
                label={resetPasswordToken ? "Verify your email" : "Email"}
                value={credentials.email}
                onChange={handleChange}
                onKeyPress={doLoginIfEnterKey}
              />
            </div>
            <div>
              <TextField
                type='password'
                name='password'
                label={resetPasswordToken ? "New Password" : "Password"}
                value={credentials.password}
                onChange={handleChange}
                onKeyPress={doLoginIfEnterKey}
              />
            </div>
            <div className='mt-10'>
              <Button variant='outlined' onClick={resetPasswordToken ? doPasswordReset : doLogin}>
                {resetPasswordToken ? "Change Password" : "Login"}
              </Button>
            </div>
            {!resetPasswordToken && (
              <div className='mt-10'>
                <Button size='small' onClick={doRequestPasswordReset}>
                  Reset password
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
