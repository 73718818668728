import React, { useState, useEffect, useContext, Fragment } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { getDailySnapshot, getSnapshot } from 'services/ReportsService';
import TextField from 'components/TextField';
import GradeValue from 'components/GradeValue';
import { AppContext } from 'AppContext';
import { secondsToHhmmss } from 'utils';
import LoadingDialog from 'components/LoadingDialog';
import moment from 'moment';
import EnglishOnlyBtn from 'components/EnglishOnlyBtn';

const Dashboard = () => {
  const [dailySnapshot, setDailySnapshot] = useState();
  const [snapshot, setSnapshot] = useState();
  const [snapshotPeriod, setSnapshotPeriod] = useState('currentWeek');
  const [englishOnly, setEnglishOnly] = useState(null);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const lastWeek = moment().subtract(1, 'weeks').startOf('week');
  const lastMonth = moment().subtract(1, 'months').startOf('month');

  useEffect(() => {
    if (snapshot && dailySnapshot) setLoading(false);
  }, [snapshot, dailySnapshot]);

  useEffect(() => {
    const loadDailySnapshot = async () => {
      try {
        setLoading(true);
        const result = await getDailySnapshot(englishOnly);
        setDailySnapshot(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };
    loadDailySnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [englishOnly]);

  useEffect(() => {
    const loadSnapshot = async () => {
      try {
        setLoading(true);
        let startDate = moment();
        let endDate = moment();
        if (isNaN(snapshotPeriod)) {
          switch (snapshotPeriod) {
            case 'currentWeek':
              startDate = moment().startOf('weeks');
              break;
            case 'lastWeek':
              startDate = lastWeek;
              endDate = moment(startDate).endOf('weeks');
              break;
            case 'currentMonth':
              startDate = moment().startOf('months');
              break;
            case 'lastMonth':
              startDate = lastMonth;
              endDate = moment(startDate).endOf('months');
              break;
            default:
              break;
          }
        } else {
          startDate.subtract(snapshotPeriod, 'days');
        }
        const result = await getSnapshot(startDate.format(), endDate.format(), englishOnly);
        setSnapshot(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };
    loadSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [englishOnly, snapshotPeriod]);

  return (
    <div>
      <LoadingDialog show={loading} />
      <div>
        <div className='flex flex-row justify-between items-center mt-5 mx-5'>
          <div className='font-sans font-bold text-lg text-gray-700'>
            {englishOnly !== null
              ? englishOnly
                ? 'English interaction status'
                : 'Non-english interaction status'
              : 'Status'}{' '}
            over the past 24 hours
          </div>
          <div className='mr-2'>
            <EnglishOnlyBtn value={englishOnly} handleChange={setEnglishOnly} />
          </div>
        </div>
        {dailySnapshot && (
          <div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Interaction Status
              </div>
              <div className='flex flex-wrap mb-5'>
                <TextField
                  label='Open Interactions'
                  value={dailySnapshot.openInteractions}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Splits in Transcription'
                  value={dailySnapshot.splitsInProgress}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Splits Awaiting Transcription'
                  value={dailySnapshot.unclaimedSplits}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <Fragment>
                    <div className='input-1-5' />
                    <div className='input-1-5' />
                  </Fragment>
                )}
                <TextField
                  label='Interactions Completed'
                  value={dailySnapshot.completedInteractions}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <TextField
                    label='Interactions in QA'
                    value={dailySnapshot.interactionsInProgress}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                )}
                {englishOnly !== false && (
                  <TextField
                    label='Interactions Awaiting QA'
                    value={dailySnapshot.unclaimedInteractions}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                )}
              </div>
            </div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Audio Hours
              </div>
              <div className='flex flex-wrap mb-5'>
                <TextField
                  label='Open Interactions'
                  value={secondsToHhmmss(dailySnapshot.inCompleteSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Splits in Transcription'
                  value={secondsToHhmmss(dailySnapshot.splitsInProgressSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Splits Awaiting Transcription'
                  value={secondsToHhmmss(dailySnapshot.unclaimedSplitsSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <Fragment>
                    <div className='input-1-5' />
                    <div className='input-1-5' />
                  </Fragment>
                )}
                <TextField
                  label='Interactions Completed'
                  value={secondsToHhmmss(dailySnapshot.completedSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <TextField
                    label='Interactions in QA'
                    value={secondsToHhmmss(dailySnapshot.qaInProgressSeconds)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                )}
                {englishOnly !== false && (
                  <TextField
                    label='Interactions Awaiting QA'
                    value={secondsToHhmmss(dailySnapshot.unclaimedQaSeconds)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                )}
              </div>
            </div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Timeliness
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='TR Lag Time'
                  value={secondsToHhmmss(dailySnapshot.splitClaimLagAverageSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Splits Delayed'
                  value={`${dailySnapshot.delayedSplitsPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <TextField
                    label='QA Lag Time'
                    value={secondsToHhmmss(dailySnapshot.interactionClaimLagAverageSeconds)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                )}
                <TextField
                  label='% Interactions Delayed'
                  value={`${dailySnapshot.delayedInteractionsPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Interactions On Time'
                  value={`${100 - dailySnapshot.delayedInteractionsPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='mt-10'>
        <div className='flex flex-row justify-between items-center mt-5 mx-5'>
          <div className='font-sans font-bold text-lg text-gray-700 flex flex-row items-baseline'>
            <div className='mr-2'>
              Snapshot{' '}
              {englishOnly !== null
                ? englishOnly
                  ? 'of English interactions '
                  : 'of Non-english interactions '
                : ''}
              over
            </div>
            <FormControl variant='standard'>
              <MuiSelect
                value={snapshotPeriod}
                onChange={(event) => {
                  setSnapshotPeriod(event.target.value);
                }}>
                <MenuItem key='currentWeek' value='currentWeek'>
                  current week ({moment().startOf('weeks').format('MMM D')})
                </MenuItem>
                <MenuItem key='lastWeek' value='lastWeek'>
                  last week ({lastWeek.format('MMM D')})
                </MenuItem>
                <MenuItem key='currentMonth' value='currentMonth'>
                  current month ({moment().format('MMMM')})
                </MenuItem>
                <MenuItem key='lastMonth' value='lastMonth'>
                  last month ({lastMonth.format('MMMM')})
                </MenuItem>
                <MenuItem key='day7' value={7}>
                  past 7 days
                </MenuItem>
                <MenuItem key='day30' value={30}>
                  past 30 days
                </MenuItem>
              </MuiSelect>
            </FormControl>
          </div>
        </div>
        {snapshot && (
          <div>
            <div className='ml-5 mt-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Completion
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='Interactions Completed'
                  value={snapshot.totalInteractions}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='AH Completed'
                  value={secondsToHhmmss(snapshot.uploadedSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Interactions Cancelled'
                  value={snapshot.totalCancelledInteractions}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
            <div className='ml-5 mt-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Timeliness
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='Average Time Per Interaction'
                  value={secondsToHhmmss(snapshot.interactionCompleteAverageSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='TR Lag Time'
                  value={secondsToHhmmss(snapshot.splitClaimLagAverageSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                {englishOnly !== false && (
                  <Fragment>
                    <TextField
                      label='QA Lag Time'
                      value={secondsToHhmmss(snapshot.interactionClaimLagAverageSeconds)}
                      inputProps={{ readOnly: true }}
                      className='input-1-5'
                    />
                  </Fragment>
                )}
                <TextField
                  label='% Interactions Delayed'
                  value={`${snapshot.delayedInteractionsPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Interactions On Time'
                  value={`${100 - snapshot.delayedInteractionsPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
              <div className='mt-5 mb-20'>
                <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                  Audio Feedback
                </div>
                <div className='flex flex-wrap mb-5'>
                  <div className='input input-1-5'>
                    <div className='mb-1 flex justify-start rating-label'>
                      Average Audio Quality
                    </div>
                    <GradeValue
                      grade={snapshot.audioQualityAverage}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <TextField
                    label='Average # of Speakers'
                    value={snapshot.speakersAverage.toFixed(2)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                  <TextField
                    label='Average # of Missing Words'
                    value={snapshot.missingWordsAverage.toFixed(2)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
