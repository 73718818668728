import { USER_ROLES } from 'Constants';
import { USER_GROUPS } from '../Constants';

export const isAdmin = (role) => role === USER_ROLES.admin;
export const isFinance = (role) => role === USER_ROLES.finance;
export const isGroupAdmin = (role) => role === USER_ROLES.groupAdmin;
export const isInHouseQA = (role) => role === USER_ROLES.inHouseQa;
export const isQA = (role) => role === USER_ROLES.qa;
export const isProofreader = (role) => role === USER_ROLES.proofreader;
export const isSynthesizer = (role) => role === USER_ROLES.synthesizer;
export const isTranscriber = (role) => role === USER_ROLES.transcriber;
export const isTRQA = (role) => role === USER_ROLES.trqa;

export const isAdminOrInHouseQA = (role) => [USER_ROLES.admin, USER_ROLES.inHouseQa].includes(role);

export const isCorrector = ({ role, groupName }) => isQA(role) && groupName === USER_GROUPS.AiHdGpt;

export const showIndividualContractor = (role) =>
  isTranscriber(role) || isQA(role) || isTRQA(role) || isSynthesizer(role) || isProofreader(role);
