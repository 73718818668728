import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { getInvoice } from 'services/InvoicesService';
import { getMonthlyProgress } from 'services/UsersService';
import moment from 'moment';
import Table from 'components/Table';
import LoadingDialog from 'components/LoadingDialog';
import { AppContext } from 'AppContext';
import { secondsToHhmmss, toCurrency, toRate, utcToDateString } from 'utils';
import InvoiceDialog from 'components/InvoiceDialog';
import GradeValue from 'components/GradeValue';

const initTime = { date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 };
const serviceTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
const displayFormat = 'MMM YYYY';
const baseColumns = [
  { label: 'Completion Date (GMT)', field: 'completionDate' },
  { label: 'File', field: 'transcriptName' },
  { label: 'Duration', field: 'length' },
  { label: 'Rate', field: 'remunerationRate' },
  { label: 'Amount', field: 'amount' },
  { label: 'Invoice', field: 'invoice' }
];

export const Invoices = (props) => {
  const [invoice, setInvoice] = useState();
  const [startDate, setStartDate] = useState(moment().set({ ...initTime }));
  const [hasSimulated, setHasSimulated] = useState(false);
  const [columns, setColumns] = useState([]);
  const [endDate, setEndDate] = useState(
    moment()
      .set({ ...initTime })
      .add(1, 'months')
  );
  const appContext = useContext(AppContext);
  const [dateMonthsBack, setDateMonthsBack] = useState(0);
  const [monthlyProgress, setMonthlyProgress] = useState();

  const [loading, setLoading] = useState(false);
  const [dialogInvoicePayment, setDialogInvoicePayment] = useState({});

  useEffect(() => {
    setStartDate(
      moment()
        .set({ ...initTime })
        .subtract(dateMonthsBack, 'months')
    );
    setEndDate(
      moment()
        .set({ ...initTime })
        .subtract(dateMonthsBack - 1, 'months')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateMonthsBack]);

  useEffect(() => {
    const loadInvoice = async () => {
      try {
        setLoading(true);
        let result = await getInvoice(
          startDate.format(serviceTimeFormat),
          endDate.format(serviceTimeFormat),
          props.match.params.userId
        );
        result.length > 0 && result.some((inv) => inv.simulatedAmount)
          ? setHasSimulated(true)
          : setHasSimulated(false);
        setInvoice(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      } finally {
        setLoading(false);
      }
    };
    const loadMonthlyProgress = async () => {
      if (['QA', 'TRQA', 'Transcriber', 'Admin'].includes(appContext.auth.role)) {
        const result = await getMonthlyProgress(
          props.match.params.userId || appContext.auth.id,
          startDate.toDate()
        );
        setMonthlyProgress(result);
      }
    };
    loadInvoice();
    loadMonthlyProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setColumns(
      hasSimulated
        ? baseColumns.concat([{ label: 'Simulated Amount', field: 'simulatedAmount' }])
        : baseColumns
    );
  }, [hasSimulated]);

  const showInvoice = (invoicePayment) => {
    setDialogInvoicePayment(invoicePayment);
  };

  const onCloseInvoice = () => {
    setDialogInvoicePayment({});
  };

  const changeInvoiceDate = (isBack) => {
    if (isBack) {
      setDateMonthsBack(dateMonthsBack + 1);
    } else if (dateMonthsBack) {
      setDateMonthsBack(dateMonthsBack - 1);
    }
  };

  return (
    <div className='mb-10'>
      <LoadingDialog show={loading} />
      <InvoiceDialog
        invoicePayment={dialogInvoicePayment}
        open={dialogInvoicePayment.id > 0}
        onClose={onCloseInvoice}
      />
      <div className='mt-4 flex flex-row justify-between'>
        <div className='ml-4 flex items-center'>
          <Button onClick={() => changeInvoiceDate(true)}>
            <NavigateBefore />
          </Button>
          <span className='font-sans font-bold text-lg text-gray-600'>
            Invoices for
            {` ${startDate.format(displayFormat)}`}
          </span>
          {dateMonthsBack > 0 && (
            <Button onClick={() => changeInvoiceDate(false)}>
              <NavigateNext />
            </Button>
          )}
        </div>
        <div>
          {invoice && invoice.length > 0 && !hasSimulated && (
            <Button
              variant='outlined'
              href={`/api/invoices/download/${
                props.match.params.userId || ''
              }?startDate=${startDate.format(serviceTimeFormat)}&endDate=${endDate.format(
                serviceTimeFormat
              )}`}
              download>
              download csv
            </Button>
          )}
        </div>
        <div className='flex flex-row items-center text-sm'>
          {monthlyProgress && monthlyProgress.totalAudioSeconds > 0 && (
            <div className='mx-5 mb-1 flex flex-row items-end'>
              <div className='text-xs'>Total AH:</div>
              <div className='ml-1 font-bold'>
                {secondsToHhmmss(monthlyProgress.totalAudioSeconds)}
              </div>
            </div>
          )}
          {monthlyProgress && monthlyProgress.gpa > 0 && (
            <div className='mx-5 flex flex-row items-center'>
              <div className='text-xs'>Average grade:</div>
              <GradeValue grade={monthlyProgress.gpa} />
            </div>
          )}
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          data={
            invoice &&
            invoice.map((inv) => ({
              id: inv.id,
              jobIdentifier: inv.jobIdentifier,
              completionDate: utcToDateString(inv.completionDate),
              transcriptName: inv.transcriptName,
              length: secondsToHhmmss(inv.durationSeconds),
              remunerationRate: toRate(inv.remunerationRate, inv.currency),
              amount: toCurrency(inv.amount, inv.currency),
              invoice: inv.invoicePayment ? (
                <button
                  className='link'
                  onClick={() => {
                    showInvoice(inv.invoicePayment);
                  }}>
                  {inv.invoicePayment.invoiceNumber}
                </button>
              ) : (
                ''
              ),
              simulatedAmount: toCurrency(inv.simulatedAmount, inv.currency)
            }))
          }
        />
      </div>
    </div>
  );
};

export default Invoices;
