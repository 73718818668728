import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AppContext } from 'AppContext';

import {
  getInteractions,
  getClaimableInteractions,
  getClaimedInteractions,
  getAssignedInteractions,
  claimInteraction,
  stateToColorClasses
} from 'services/InteractionsService';
import { stateToColorClasses as splitStateToColorClasses } from 'services/SplitsService';
import { searchInteractions } from 'services/SearchService';

import Heading from 'components/Heading';
import Table from 'components/Table';
import Select from 'components/Select';
import Dot from 'components/Dot';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';
import EnglishOnlyBtn from 'components/EnglishOnlyBtn';

import { Button, Tooltip } from '@mui/material';
import { Notes, Translate, FlashOn, Pause } from '@mui/icons-material';

import { tableColumns, paginationDefaults, sortDefaults } from './constants';

import { secondsToHhmmss, utcToLocalDateString, isNonEnglish } from 'utils';
import { isAdmin, isAdminOrInHouseQA, isCorrector, isProofreader } from 'utils/roles';
import {
  interactionInProgress,
  interactionReadyForCorrector,
  interactionReadyForProofreader,
  interactionReadyForQa
} from 'utils/interaction';

import text from './text';
import { INTERACTION_STATES, USER_ROLES } from 'Constants';

const Interactions = (props) => {
  const appContext = useContext(AppContext);
  const viewState = appContext.viewStates[props.location.pathname] || {};
  const [interactions, setInteractions] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState(viewState.pagination || paginationDefaults);
  const [sort, setSort] = useState(viewState.sort || sortDefaults);
  const [filterState, setFilterState] = useState(viewState.filterState || text.all);
  const [heading, setHeading] = useState(text.interactions);
  const [loading, setLoading] = useState(false);
  const [englishOnly, setEnglishOnly] = useState(null);

  const {
    auth: {
      role,
      userContext: { groupName }
    }
  } = appContext;

  const interactionCanBeClaimed = (state) =>
    interactionReadyForCorrector(state) ||
    interactionReadyForQa(state) ||
    interactionReadyForProofreader(state);

  const unlistenHistory = () =>
    props.history.listen(({ pathname }) => {
      if (pathname !== props.location.pathname) {
        appContext.setViewState(props.location.pathname, {
          pagination,
          sort,
          filterState
        });
      }
    });

  useEffect(() => {
    loadInteractions();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sort, filterState, englishOnly]);

  const loadInteractions = async () => {
    setLoading(true);
    let leaving = false;
    try {
      if ([USER_ROLES.qa, USER_ROLES.trqa, USER_ROLES.proofreader].includes(role)) {
        const claimed = await getClaimedInteractions();

        if (claimed.length > 0) {
          leaving = true;
          // We don't allow the QA to Claim more than 1 interaction at a time
          props.history.replace(`/interactions/${claimed[0].id}`);
        } else {
          const targetRole = isProofreader(role)
            ? 'Proofreader'
            : isCorrector({ role, groupName })
              ? 'Corrector'
              : 'QA';
          const result = await getAssignedInteractions();
          if (result.length > 0) {
            setInteractions(result);
            setTotalCount(result.length);
            setHeading(`Ready for ${targetRole} Interactions assigned to you`);
          } else {
            const result = await getClaimableInteractions();
            setInteractions(result);
            setTotalCount(result.length);
            setHeading(`Ready for ${targetRole} Interactions available for claim`);
          }
        }
      } else {
        const { data, totalCount } = await getInteractions(
          pagination,
          sort,
          filterState,
          englishOnly
        );
        setInteractions(data);
        setTotalCount(totalCount);
      }
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const goToNewInteraction = () => {
    props.history.push('/interactions/new');
  };

  const goToInteraction = (id) => {
    props.history.push(`/interactions/${id}`);
  };

  const handleChangePage = (event, pageNumber) => {
    setPagination({ ...pagination, pageNumber });
  };

  const handleChangeSort = (field) => {
    let order = 'asc';
    if (field === sort.field && sort.order === 'asc') {
      order = 'desc';
    }
    setSort({ field, order });
  };

  const handleClaimSelect = (id) => {
    appContext.showDialog(text.claimingInteraction, text.completeInteraction, () =>
      doClaimInteraction(id)
    );
  };

  const doClaimInteraction = async (id) => {
    try {
      await claimInteraction(id);
      props.history.push(`/interactions/${id}`);
    } catch (error) {
      appContext.showNotification('error', error.message);
    }
  };

  const handleFilterStateChange = (target, selectItem) => {
    setFilterState(selectItem.props.value);
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleEnglishOnlyChange = (value) => {
    setEnglishOnly(value);
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const showDetailsDialog = (message) => {
    appContext.showCloseDialog('Details', message);
  };

  const IconTooltip = ({ title, Icon }) => (
    <Tooltip className='ml-2' title={title} placement='top'>
      <Icon fontSize='inherit' />
    </Tooltip>
  );

  const renderTooltip = (interaction) => (
    <>
      {isNonEnglish(interaction) && (
        <IconTooltip title={text.tooltip.nonEnglish} Icon={Translate} />
      )}
      {isAdmin(role) && interaction.priority && (
        <IconTooltip title={text.tooltip.prioritized} Icon={FlashOn} />
      )}
      {isAdmin(role) && interaction.suspended && (
        <IconTooltip title={text.tooltip.suspended} Icon={Pause} />
      )}
      {isAdminOrInHouseQA(role) && interaction.qaOnly && (
        <Tooltip title={text.tooltip.qaOnly} placement='top'>
          <div className='text-xs font-bold ml-2'>{text.QA}</div>
        </Tooltip>
      )}
    </>
  );

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      {isAdminOrInHouseQA(role) && (
        <Search
          searchFunc={searchInteractions}
          onSelect={(id) => props.history.push(`/interactions/${id}`)}
        />
      )}
      <Heading
        title={heading}
        secondaryContent={
          <Fragment>
            {isAdminOrInHouseQA(role) && (
              <Fragment>
                <div className='w-1/4'>
                  <Select
                    name='stateFilter'
                    label={text.interactionState}
                    value={filterState}
                    values={Object.values(INTERACTION_STATES)}
                    onChange={handleFilterStateChange}
                    itemValue='value'
                    itemDisplay='label'
                    className='input-no-padding'
                  />
                </div>
                <div>
                  <EnglishOnlyBtn value={englishOnly} handleChange={handleEnglishOnlyChange} />
                </div>
                <div>
                  <span className='ml-3'>
                    <Button
                      variant='outlined'
                      onClick={goToNewInteraction}
                      disabled={!isAdmin(role)}>
                      {text.newInteraction}
                    </Button>
                  </span>
                </div>
              </Fragment>
            )}
          </Fragment>
        }
        className='m-5'
      />
      <Table
        columns={tableColumns(role)}
        data={
          interactions &&
          interactions.map((interaction, index) => {
            const {
              alphasightsId,
              id,
              projectId,
              audioSeconds,
              transcriptRequestDate,
              projectTopic,
              state,
              splitStates,
              notes
            } = interaction;

            return {
              id,
              alphasightsId: (
                <div className='flex flex-row items-center rowSelectable'>
                  <div>{alphasightsId}</div>
                  {renderTooltip(interaction)}
                </div>
              ),
              projectId,
              length: secondsToHhmmss(audioSeconds),
              audioSeconds,
              transcriptRequestDate: utcToLocalDateString(transcriptRequestDate),
              projectTopic,
              className: stateToColorClasses(state).secondary,
              interactionNotes: (
                <div className='flex flex-row items-center'>
                  {notes && (
                    <Button variant='outlined' onClick={() => showDetailsDialog(notes)}>
                      <Notes />
                    </Button>
                  )}
                </div>
              ),
              interactionStatus: (
                <div>
                  {interactionInProgress(state) && (
                    <div className='w-24 flex flex-wrap ml-2'>
                      {splitStates.map((state, index) => (
                        <Dot
                          className={`${splitStateToColorClasses(state).primary} m-1`}
                          key={`split-state-${id}-${index}`}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ),
              claimAction: interactionCanBeClaimed(state) && index === 0 && (
                <Button
                  variant='outlined'
                  interaction-id={id}
                  onClick={() => handleClaimSelect(id)}>
                  Claim
                </Button>
              )
            };
          })
        }
        totalCount={totalCount}
        pagination={pagination}
        sort={isAdmin(role) ? sort : null}
        onRowSelect={isAdminOrInHouseQA(role) ? goToInteraction : null}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
      />
    </Fragment>
  );
};

export default Interactions;
