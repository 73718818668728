import { useContext, useEffect, useState, createRef } from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import { AppContext } from 'AppContext';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { navTabs, text } from './constants';
import { isTranscriber, isCorrector, isProofreader, isQA } from 'utils/roles';
import { USER_GROUPS } from 'Constants';

export const AppNav = (props) => {
  const appContext = useContext(AppContext);
  const [tabValue, setTabValue] = useState(false);
  const tabsRef = createRef();

  const { auth } = appContext;

  useEffect(() => {
    if (tabsRef.current) {
      Array.from(tabsRef.current.getElementsByTagName('a')).forEach((a) => {
        if (props.location.pathname.startsWith(a.pathname)) {
          setTabValue(a.pathname);
        }
      });
    }
  }, [props.location.pathname, tabsRef]);

  const handleChange = (event, value) => {
    setTabValue(value);
  };

  const StyledTab = styled((props) => <Tab {...props} />)(() => ({
    minWidth: '160px'
  }));

  const renderTabs = () => {
    const {
      role,
      userContext: { individualContractor, groupName }
    } = auth;
    const tabsForRole = navTabs(individualContractor)[role] || [];
    const isNonCorrectorQA = isQA(role) && groupName !== USER_GROUPS.AiHdGpt;

    return tabsForRole.map((label) => {
      let value, to, component, href, target;

      switch (label) {
        case text.transcripts:
          value = '/interactions';
          to = '/interactions';
          component = Link;
          break;
        case text.logout:
          value = `/${label.toLowerCase()}`;
          component = 'a';
          href = `/${label.toLowerCase()}`;
          break;
        case text.library:
          component = 'a';
          target = '_blank';
          if (individualContractor) {
            if (isTranscriber(role) || isNonCorrectorQA) {
              href = 'https://alphasights.docsend.com/view/s/t7kg9jx4cfvxy2jn';
            } else if (isProofreader(role) || isCorrector({ role, groupName })) {
              href = 'https://alphasights.docsend.com/view/s/e23qqdnqhupdjxqq';
            }
          }
          break;
        default:
          value = `/${label.replace(/ /g, '').toLowerCase()}`;
          to = `/${label.replace(/ /g, '').toLowerCase()}`;
          component = Link;
          break;
      }

      return (
        <StyledTab
          key={label}
          value={value}
          label={label}
          component={component}
          to={to}
          href={href}
          target={target}
        />
      );
    });
  };

  return (
    <AppBar position='static' color='default'>
      {auth && (
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          ref={tabsRef}>
          {renderTabs()}
        </Tabs>
      )}
    </AppBar>
  );
};

export default withRouter(AppNav);
