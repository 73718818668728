import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Translate from '@mui/icons-material/Translate';
import PropTypes from 'prop-types';

const EnglishOnlyBtn = ({ value, handleChange }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={(event, value) => {
      handleChange(value);
    }}
    size='small'>
    <ToggleButton value={true}>
      <div className='mx-1 -my-1 text-lg'>A</div>
    </ToggleButton>
    <ToggleButton value={false}>
      <Translate />
    </ToggleButton>
  </ToggleButtonGroup>
);

EnglishOnlyBtn.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func
};

EnglishOnlyBtn.defaultProps = {};

export default EnglishOnlyBtn;
