import React, { useEffect, useState } from 'react';
import { getInvoices } from 'services/InvoicesService';
import { Button, Dialog, DialogTitle, Table } from '@mui/material';
import { secondsToHhmmss, toCurrency, toRate, utcToDateString, sort } from 'utils';

export const InvoiceDialog = (props) => {
  const { invoicePayment, onClose, open } = props;
  const [remunerations, setRemunerations] = useState([]);

  useEffect(() => {
    const loadInvoice = async () => {
      if (invoicePayment.id) {
        const result = await getInvoices(invoicePayment.id);
        setRemunerations(sort(result, 'completionDate'));
      }
    };
    loadInvoice();
  }, [invoicePayment.id]);

  const handleClose = () => {
    onClose();
  };

  const currencyType = invoicePayment?.currency || 'USD';

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false}>
      <DialogTitle>Invoice {invoicePayment.invoiceNumber}</DialogTitle>
      <div>
        {invoicePayment.payment && invoicePayment.payment.submitDate && (
          <div className='mx-3 flex justify-around'>
            <Button
              variant='outlined'
              href={`/api/invoicePayments/download/csv/${invoicePayment.id}`}
              download>
              download csv
            </Button>
            <Button
              variant='outlined'
              href={`/api/invoicePayments/download/pdf/${invoicePayment.id}`}
              download>
              download pdf
            </Button>
          </div>
        )}
        <Table
          columns={[
            { label: 'Completion Date (GMT)', field: 'completionDate' },
            { label: 'File', field: 'transcriptName' },
            { label: 'Duration', field: 'length' },
            { label: 'Rate', field: 'remunerationRate' },
            { field: 'amount' }
          ]}
          data={remunerations.map((remuneration) => ({
            id: remuneration.id,
            jobIdentifier: remuneration.jobIdentifier,
            completionDate: utcToDateString(remuneration.completionDate),
            transcriptName: remuneration.transcriptName,
            length: secondsToHhmmss(remuneration.durationSeconds),
            remunerationRate: toRate(remuneration.remunerationRate, remuneration.currency),
            amount: toCurrency(remuneration.amount, remuneration.currency)
          }))}
        />
      </div>
      <div className='m-4 text-sm flex flex-row justify-between'>
        <div>Sub-total</div>
        <div>
          {toCurrency(
            remunerations.reduce((acc, remuneration) => acc + remuneration.amount, 0),
            currencyType
          )}
        </div>
      </div>
      {invoicePayment.bonuses &&
        invoicePayment.bonuses.map((bonus) => (
          <div key={bonus.id} className='m-4 text-sm flex flex-row justify-between'>
            <div>Bonus: {bonus.bonusType}</div>
            <div>{toCurrency(bonus.total, currencyType)}</div>
          </div>
        ))}
      <div className='m-4 text-sm flex flex-row justify-between'>
        <div>Adjustments</div>
        <div>{invoicePayment.adjustmentReason}</div>
        <div>{invoicePayment.adjustment ? invoicePayment.adjustment.toFixed(2) : 'None'}</div>
      </div>
      <div className='m-4 font-bold text-sm flex flex-row justify-between'>
        <div>Final Total</div>
        <div>
          {currencyType} {invoicePayment.finalTotal ? invoicePayment.finalTotal.toFixed(2) : '0.00'}
        </div>
      </div>
    </Dialog>
  );
};

InvoiceDialog.defaultProps = {
  invoicePayment: {},
  open: false
};

export default InvoiceDialog;
