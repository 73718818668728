import React, { useState, useContext, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from 'AppContext';

import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  IconButton,
  TableContainer
} from '@mui/material';
import MaterialSelect from '@mui/material/Select';
import { Delete, Edit } from '@mui/icons-material';

import Heading from 'components/Heading';
import Select from 'components/Select';
import TextField from 'components/TextField';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';
import Notification from 'components/Notification';
import Table from 'components/Table';
import RateDialog from 'components/RateDialog';
import { Header, ToggleGroup } from 'components/UserDetails';

import { getUser, saveUser, deleteUser, deleteUserLanguageRate } from 'services/UsersService';
import { getProfile, saveProfile } from 'services/ProfileService';
import { searchUsers } from 'services/SearchService';

import { COUNTRIES, USER_ROLES, LANGUAGES, USER_GROUPS } from 'Constants';
import {
  TEXT_FIELDS,
  URL_VARIANT,
  DEFAULT_RATES,
  DEFAULT_USER,
  DEFAULT_RATE_COLUMNS,
  REVIEW_PERCENTAGE
} from './constants';

import { isAdmin, showIndividualContractor, isAdminOrInHouseQA, isCorrector } from 'utils/roles';

export const UserDetails = (props) => {
  const [user, setUser] = useState(DEFAULT_USER);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const appContext = useContext(AppContext);
  const [originLanguages, setOriginLanguages] = useState(DEFAULT_USER.languages);
  const originLangsMap = new Map();
  originLanguages.forEach((originLanguage) =>
    originLangsMap.set(originLanguage.language, originLanguage.id)
  );
  const [selectedRate, setSelectedRate] = useState(DEFAULT_RATES);
  const [showRateDialog, setShowRateDialog] = useState(false);
  const [rates, setRates] = useState([]);
  const [rateColumns, setRateColumns] = useState(DEFAULT_RATE_COLUMNS);
  const [reviewPercentageError, setReviewPercentageError] = useState(false);
  const [variant, setVariant] = useState({});

  const { auth } = appContext;

  const authUserIsAdmin = isAdmin(auth.role);

  const onCloseRate = () => {
    setShowRateDialog(false);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSave = async () => {
    let save = saveUser;
    let load = loadUser;
    if (props.variant === 'profile') {
      save = saveProfile;
      load = loadProfile;
    }
    try {
      setLoading(true);
      const result = await save(user);
      setUser(result.saved);
      showNotification('success', `${user.email} saved`);
    } catch (error) {
      showNotification('error', error.message);
    } finally {
      setLoading(false);
      if (user.id != null) {
        load(user.id);
      }
    }
  };

  const handleDelete = () => {
    appContext.showDialog(
      'Delete User?',
      `Are you certain you wish to delete ${user.email}?`,
      doDelete
    );
  };

  const doDelete = async () => {
    try {
      await deleteUser(user.id);
      showNotification('success', `User ${user.email} deleted`);
      props.history.push('/users');
    } catch (error) {
      showNotification('error', error.message);
    }
  };

  const doUserLanguageRateDelete = async (id) => {
    try {
      await deleteUserLanguageRate(id);
      showNotification('success', `Rate ${id} is deleted`);
      loadRatesTable(user.id);
    } catch (error) {
      showNotification('error', error.message);
    }
  };

  const handleLanguageChange = (event) => {
    const strArray = event.target.value;
    const objArray = strArray.map((language) => ({
      id: originLangsMap.get(language) || null,
      language
    }));

    setUser({
      ...user,
      languages: objArray
    });
  };

  const handleGroupChange = (event) => {
    let groupName = event.target.value;
    if (groupName === USER_GROUPS.None) groupName = null;
    setUser({
      ...user,
      groupName
    });
  };

  const handleReviewPercentageChange = (event) => {
    if (event.target.value && !REVIEW_PERCENTAGE.test(event.target.value)) {
      return setReviewPercentageError(true);
    }
    handleChange(event);
  };

  const showNotification = (variant, message) => {
    setNotification({
      variant,
      message,
      open: true,
      onClose: hideNotification
    });
  };

  const hideNotification = () => {
    setNotification({ ...notification, open: false });
  };

  if (!variant.heading) {
    setVariant(URL_VARIANT[props.variant]);
  } else if (URL_VARIANT[props.variant].heading !== variant.heading) {
    setVariant(URL_VARIANT[props.variant]);
    setUser(DEFAULT_USER); // Reset user
  }

  const loadProfile = async () => {
    let leaving = false;
    try {
      setLoading(true);
      let profile = await getProfile();
      setUser(profile);
      setRates([]);
      createRates(profile.languages);
    } catch (error) {
      showNotification('error', error.message);
      leaving = true;
      props.history.push('/');
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const loadUser = async (id) => {
    let leaving = false;
    try {
      setLoading(true);
      const loadedUser = await getUser(id);
      if (!loadedUser) {
        throw new Error(`Could not find user with id ${id}`);
      }
      setUser(loadedUser);
      setOriginLanguages(loadedUser.languages);
      setRates([]);
      createRates(loadedUser.languages);
    } catch (error) {
      showNotification('error', error.message);
      leaving = true;
      props.history.push('/users/new');
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const loadRatesTable = async (id) => {
    let leaving = false;
    try {
      setLoading(true);
      const loadedUser = await getUser(id);
      setRates([]);
      createRates(loadedUser.languages);
    } catch (error) {
      showNotification('error', error.message);
      leaving = true;
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  useEffect(() => {
    if (props.variant === 'profile') {
      loadProfile();
    } else if (props.variant === 'details') {
      loadUser(props.match.params.id);
    }

    if (authUserIsAdmin && props.variant !== 'new') {
      setRateColumns([...rateColumns, { label: 'Action', field: 'action' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.variant === 'new' && user.id) {
      props.history.push(`/users/${user.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const userLanguages = useMemo(() => user.languages.map((ul) => ul.language), [user.languages]);

  const showRate = (rate) => {
    setShowRateDialog(true);
    setSelectedRate(rate);
  };

  const editRow = (row) => (
    <IconButton onClick={() => showRate(row)}>
      <Edit color='primary' />
    </IconButton>
  );

  const deleteRow = (id) => (
    <IconButton onClick={() => doUserLanguageRateDelete(id)}>
      <Delete color='primary' />
    </IconButton>
  );

  const createRates = (languages) => {
    languages.forEach((language) => {
      if (language.rates != null) {
        language.rates.forEach((rate) => {
          setRates((rates) => [
            ...rates,
            {
              id: rate.id,
              rate: (rate.rate / 60).toFixed(2),
              task: rate.task,
              languageId: language.id,
              language: language.language
            }
          ]);
        });
      }
    });
  };

  return (
    <div className='mb-12'>
      <LoadingDialog show={loading} />
      <RateDialog
        selectedRate={selectedRate}
        open={showRateDialog}
        languages={user.languages}
        onClose={onCloseRate}
        showNotification={showNotification}
        loadRatesTable={loadRatesTable}
        userId={user.id}
        userRole={user.role}
        variant={selectedRate.id === 0 ? 'new' : 'edit'}
      />
      {isAdminOrInHouseQA(auth.role) && (
        <Search searchFunc={searchUsers} onSelect={(id) => props.history.push(`/users/${id}`)} />
      )}
      <Header
        variant={{ name: props.variant, heading: variant.heading }}
        user={user}
        handleChange={handleChange}
        authUserRole={auth.role}
      />
      <Grid container>
        <Grid item xs={4}>
          <Select
            name='role'
            label='Role'
            value={user.role}
            values={Object.values(USER_ROLES)}
            readOnly={variant.roleEmailReadOnly}
            disabled={!user.active}
            onChange={handleChange}
          />
        </Grid>
        {TEXT_FIELDS.map((field, index) => (
          <Grid item xs={4} key={field?.name || index}>
            {index !== 4 ? (
              <TextField
                name={field.name}
                label={field.label}
                value={user[field.name]}
                disabled={!user.active}
                onChange={handleChange}
                {...(field.name === 'email'
                  ? {
                      inputProps: {
                        readOnly: variant.roleEmailReadOnly
                      }
                    }
                  : {})}
                {...(field.name === 'password'
                  ? {
                      type: 'password'
                    }
                  : {})}
              />
            ) : (
              <></>
            )}
          </Grid>
        ))}
        <Grid item xs={4} />
        <Grid item xs={4}>
          <div className='text-left pt-12 px-8'>
            <FormControl disabled={!user.active} variant='standard' className='w-full'>
              <InputLabel htmlFor='select-multiple-language'>Languages</InputLabel>
              <MaterialSelect
                multiple
                value={userLanguages}
                onChange={handleLanguageChange}
                input={<Input id='select-multiple-language' />}
                renderValue={(selected) => (
                  <div className='flex justify-start'>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className='m-1' />
                    ))}
                  </div>
                )}
                disabled={props.variant === 'profile'}>
                {LANGUAGES.map((language) => (
                  <MenuItem key={language} value={language}>
                    <Checkbox color='primary' checked={userLanguages.includes(language)} />
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </MaterialSelect>
            </FormControl>
          </div>
        </Grid>
        {authUserIsAdmin && (
          <Grid item xs={4}>
            <ToggleGroup
              user={user}
              handleChange={handleChange}
              showIndividualContractor={showIndividualContractor(user.role)}
            />
          </Grid>
        )}
        <Grid item xs={4} />
        {(authUserIsAdmin || user.groupName) && (
          <Grid item xs={4}>
            <Select
              name='groupName'
              label='Group'
              value={user.groupName ?? USER_GROUPS.None}
              values={Object.values(USER_GROUPS)}
              readOnly={!authUserIsAdmin}
              disabled={!user.active}
              onChange={handleGroupChange}
              className='mt-3'
            />
          </Grid>
        )}
        {authUserIsAdmin && isCorrector(user) && !user.inTraining && (
          <Grid item xs={4}>
            <TextField
              name='reviewPercentage'
              label='QA Review % (Input 0-100)'
              value={user.reviewPercentage}
              disabled={!user.active}
              onChange={handleReviewPercentageChange}
              className='mt-3'
              error={typeof user.reviewPercentage === 'number' && reviewPercentageError}
              helperText={reviewPercentageError ? 'Must be a number between 0 and 100' : ''}
            />
          </Grid>
        )}
      </Grid>
      {showIndividualContractor(user.role) && user.individualContractor && (
        <Grid container>
          <Grid item xs={4}>
            <Select
              name='bankCountryCode'
              label='Bank Country'
              value={user.bankCountryCode}
              values={COUNTRIES}
              itemValue='code'
              itemDisplay='name'
              disabled={!user.active}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name='bankName'
              label='Bank Name'
              value={user.bankName}
              disabled={!user.active}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name='bankId'
              label={user.bankCountryCode === 'US' ? 'Routing #' : 'Swift code'}
              value={user.bankId}
              disabled={!user.active}
              onChange={handleChange}
            />
          </Grid>
          {user.bankCountryCode === 'CA' && (
            <Grid item xs={4}>
              <TextField
                name='bankTransitNumber'
                label='Transit Number'
                value={user.bankTransitNumber}
                disabled={!user.active}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <TextField
              name='bankAccountName'
              label='Name on the Account'
              value={user.bankAccountName}
              disabled={!user.active}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name='bankAccountNumber'
              label={
                user.bankCountryCode === 'AU' ? 'BSB and Account number' : 'Account # -or- IBAN'
              }
              value={user.bankAccountNumber}
              helperText={
                user.maskedBankAccountNumber ? `# on file: ${user.maskedBankAccountNumber}` : null
              }
              disabled={!user.active}
              onChange={handleChange}
            />
          </Grid>
          {user.bankCountryCode === 'US' && (
            <Grid item xs={4}>
              <Select
                name='bankAccountType'
                label='Account Type'
                value={user.bankAccountType}
                values={['Checking', 'Savings']}
                disabled={!user.active}
                onChange={handleChange}
              />
            </Grid>
          )}
          {authUserIsAdmin && (
            <Grid item xs={4}>
              <Select
                name='invoiceCurrency'
                label='Currency'
                value={user.invoiceCurrency}
                values={['USD', 'GBP', 'EUR']}
                disabled={!user.active}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
      )}
      <div className='mt-12 flex flex-row justify-center'>
        <div>
          <Button
            variant='outlined'
            onClick={handleSave}
            disabled={!authUserIsAdmin && appContext.auth.id !== user.id}>
            Save
          </Button>
        </div>
        {variant.showDeleteAction && (
          <div className='pl-16'>
            <Button variant='outlined' onClick={handleDelete} disabled={!authUserIsAdmin}>
              Delete
            </Button>
          </div>
        )}
      </div>
      {showIndividualContractor(user.role) && user.individualContractor && (
        <Fragment>
          <Heading title='Rates' className='ml-5' />
          <TableContainer className='p-8'>
            <Table
              size={authUserIsAdmin ? 'small' : null}
              columns={rateColumns}
              data={
                rates &&
                rates.map((rate) => ({
                  id: rate.id,
                  rate: rate.rate,
                  task: rate.task === 'FullFile' ? 'Full File' : rate.task,
                  currency: user.invoiceCurrency,
                  time: 'per minute',
                  language: rate.language,
                  action: (
                    <div>
                      {editRow(rate)}
                      {deleteRow(rate.id)}
                    </div>
                  )
                }))
              }
            />
          </TableContainer>
          {authUserIsAdmin && (
            <div>
              <Button variant='outlined' onClick={() => showRate(DEFAULT_RATES)}>
                Add new Rate
              </Button>
            </div>
          )}
        </Fragment>
      )}
      <Notification {...notification} />
    </div>
  );
};

UserDetails.propTypes = {
  variant: PropTypes.string.isRequired
};

export default UserDetails;
