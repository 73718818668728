import React from 'react';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import Wrapper from 'components/Wrapper';
import {
  aiTranscriptText,
  audioText,
  docName,
  durationText,
  trIdText,
  header,
  uploadDateText
} from './constants';
import { getFileName, doDownload } from 'utils';
import { downloadTranscript } from 'services/SplitsService';

const spanClass = 'font-normal ml-4';

const AITranscriptAudioFiles = ({ interaction: { id, trId, aiTranscript, audio } }) => {
  const files = [
    {
      name: aiTranscriptText,
      downloadText: `Download ${aiTranscriptText}`,
      uploadDate: aiTranscript.uploadDate,
      s3FilePath: aiTranscript.s3FilePath,
      downloadedDoc: trId && `${trId}.docx`,
      buttonProps: {
        onClick: () => handleDownloadTranscript(aiTranscript.id)
      }
    },
    {
      name: audioText,
      downloadText: `Download ${audioText}`,
      duration: audio.duration,
      buttonProps: {
        href: `/api/interactions/audio/${id}`
      }
    }
  ];

  const handleDownloadTranscript = async (splitId) => {
    const path = await downloadTranscript(splitId);
    const file = getFileName(aiTranscript.s3FilePath);
    doDownload(file, path);
  };

  return (
    <Wrapper header={header} colour={'bg-inherit'}>
      {files.map(({ name, downloadText, downloadedDoc, uploadDate, duration, buttonProps }) => {
        return (
          <div
            className='p-5 flex rounded-lg mx-20 mt-4 font-bold text-sm justify-between items-center bg-yellow-100'
            key={name}
            data-testid={name}>
            <span data-testid={`name-${name.replace(/\s+/g, '-')}`}>{name}</span>
            {uploadDate && (
              <span>
                {uploadDateText}
                <span className={spanClass}>{uploadDate}</span>
              </span>
            )}
            {downloadedDoc && (
              <span>
                {docName}
                <span className={spanClass}>{downloadedDoc}</span>
              </span>
            )}
            {duration && (
              <span>
                {durationText}
                <span className={spanClass}>{duration}</span>
              </span>
            )}
            {trId && name === audioText && (
              <span>
                {trIdText}
                <span className={spanClass}>({trId})</span>
              </span>
            )}
            <IconButton
              className={`text-customBlack bg-white py-3 px-4 rounded border border-solid border-greyBorder`}
              download
              {...buttonProps}>
              <FileDownloadOutlinedIcon />
              <span className='ml-2 font-normal'>{downloadText}</span>
            </IconButton>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default AITranscriptAudioFiles;
