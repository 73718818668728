import React from "react";

const Dot = (props) => {
  const { className } = props;
  return (
    <span
      className={className}
      style={{
        height: "12px",
        width: "12px",
        border: "1px solid #aaa",
        borderRadius: "50%",
        display: "inline-block"
      }}
    />
  );
};

export default Dot;
