import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Heading from "components/Heading";
import { Link } from "react-router-dom";
import { isAdminOrInHouseQA, isAdmin } from "utils/roles";

export const workhistoryPath = "/workhistory";
export const transcriberPath = "/transcriber";
export const qaPath = "/qa";
export const synthesizerPath = "/synthesizer";
export const invoicesPath = "/invoices";
export const gradesPath = "/grades";
export const reportsPath = "/reports";

const ButtonComponent = ({ name, url, testId }) => (
  <div className="pl-16">
    <Button component={Link} to={url} data-testid={testId}>
      {name}
    </Button>
  </div>
);

const renderRoleButtons = user => {
  const transcriberButton = {
    name: "Transcribe History",
    url: `${workhistoryPath}${transcriberPath}/${user.id}`
  };
  const qaHistoryButton = {
    name: "QA History",
    url: `${workhistoryPath}${qaPath}/${user.id}`
  };

  const buttonsByRole = {
    Transcriber: [transcriberButton],
    TRQA: [transcriberButton, qaHistoryButton],
    QA: [qaHistoryButton],
    Synthesizer: [
      {
        name: "Synthesized History",
        url: `${workhistoryPath}${synthesizerPath}/${user.id}`
      }
    ]
  };

  if (!buttonsByRole[user.role]) return;

  return buttonsByRole[user.role].map(button => (
    <ButtonComponent
      name={button.name}
      url={button.url}
      key={button.name}
      testId="role-button"
    />
  ));
};

const renderAdminButtons = user => {
  const adminButtons = [
    { name: "Invoices", url: `${invoicesPath}/${user.id}` },
    { name: "Users & Grades", url: `${gradesPath}/${user.groupName}` },
    { name: "Reports", url: `${reportsPath}/${user.groupName}` }
  ];

  return (
    <>
      <ButtonComponent name={adminButtons[0].name} url={adminButtons[0].url} />
      {user.groupName &&
        adminButtons.map((button, index) => {
          if (index === 0) return null;
          return (
            <ButtonComponent
              name={button.name}
              url={button.url}
              key={button.name}
            />
          );
        })}
    </>
  );
};

const renderButtons = (user, authUserRole, variant, handleChange) => {
  if (!user.id || variant !== "details") return null;

  return (
    <>
      <div className="flex flex-row" data-testid="header-buttons">
        {isAdminOrInHouseQA(authUserRole) && (
          <div
            className="flex flex-row"
            data-testid="admin-or-inhouseqa-buttons"
          >
            {renderRoleButtons(user)}
            {isAdmin(authUserRole) && renderAdminButtons(user)}
          </div>
        )}
      </div>
      <div>
        <Switch
          checked={user.active}
          onChange={(_event, value) =>
            handleChange({
              target: { name: "active", value }
            })
          }
          value={user.active}
          color="primary"
        />
        <span className="text-gray-500 text-xs font-bold">
          {user.active ? "Active" : "Inactive"}
        </span>
      </div>
    </>
  );
};

const Header = ({ variant, user, handleChange, authUserRole }) => (
  <Heading
    className="m-5"
    title={`${variant.heading || ""}${user.id ? ` (${user.id})` : ""}`}
    secondaryContent={renderButtons(
      user,
      authUserRole,
      variant.name,
      handleChange
    )}
  />
);

Header.propTypes = {
  variant: PropTypes.shape({
    heading: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  authUserRole: PropTypes.string.isRequired
};

export default Header;
