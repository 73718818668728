import React, { Fragment, useEffect, useState, useContext } from "react";
import { Button, Switch } from "@mui/material";
import { getUsers, getUsersByFilter } from "services/UsersService";
import { searchUsers } from "services/SearchService";
import Heading from "components/Heading";
import Table from "components/Table";
import LoadingDialog from "components/LoadingDialog";
import Search from "components/Search";
import Select from "components/Select";
import { AppContext } from "AppContext";
import { LANGUAGES } from "Constants";

const columns = [
  { label: "Role", field: "role" },
  { label: "Id", field: "id" },
  { label: "Login Email", field: "email" },
  { label: "Firstname", field: "firstname" },
  { label: "Lastname", field: "lastname" }
];
const paginationDefaults = {
  pageSize: 10,
  pageNumber: 0
};
const sortDefaults = {
  field: "email",
  order: "asc"
};

const Users = (props) => {
  const appContext = useContext(AppContext);
  const viewState = appContext.viewStates[props.location.pathname] || {};
  const [users, setUsers] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState(viewState.pagination || paginationDefaults);
  const [sort, setSort] = useState(viewState.sort || sortDefaults);
  const [filter, setFilter] = useState({
    role: "All",
    language: "All",
    activity: "All"
  });
  const [loading, setLoading] = useState(false);
  const [getInactive, setGetInactive] = useState(false);

  const unlistenHistory = props.history.listen((location) => {
    if (location.pathname !== props.location.pathname) {
      appContext.setViewState(props.location.pathname, {
        pagination,
        sort
      });
    }
  });

  useEffect(() => {
    loadUsers();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter, sort, getInactive]);

  const loadUsers = async () => {
    try {
      setLoading(true);
      let result;
      const isEveryFilterAll = Object.values(filter).every((val) => val === "All");
      if (isEveryFilterAll) {
        result = await getUsers(pagination, sort, getInactive);
      } else {
        result = await getUsersByFilter(pagination, sort, filter, getInactive);
      }
      setUsers(result.data);
      setTotalCount(result.totalCount);
    } catch (error) {
      appContext.showNotification("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const goToNewUser = () => {
    props.history.push("/users/new");
  };

  const goToUser = (id) => {
    props.history.push(`/users/${id}`);
  };

  const handleChangePage = (event, pageNumber) => {
    setPagination({ ...pagination, pageNumber });
  };

  const handleChangeSort = (field) => {
    let order = "asc";
    if (field === sort.field && sort.order === "asc") {
      order = "desc";
    }
    setSort({ field, order });
  };

  const handleChangeRole = (event, option) => {
    setFilter({ ...filter, role: option.props.value });
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleChangeLanguage = (event, option) => {
    setFilter({ ...filter, language: option.props.value });
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleChangeActivity = (event, option) => {
    setFilter({ ...filter, activity: option.props.value });
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleChangeInactive = (event, value) => {
    setGetInactive(!value);
    setPagination({ ...pagination, pageNumber: 0 });
  };

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      {["Admin", "InHouseQA"].includes(appContext.auth.role) && (
        <Search
          searchFunc={(searchString) => searchUsers(searchString, null, getInactive)}
          onSelect={(id) => props.history.push(`/users/${id}`)}
        />
      )}
      <Heading
        title='AlphaScribes Users'
        secondaryContent={
          <Button variant='outlined' onClick={goToNewUser}>
            New User
          </Button>
        }
        className='mt-5 mx-5'
      />
      <div className='flex flex-row items-center'>
        <div className='w-1/4 -ml-3 -mt-4'>
          <Select
            name='userRole'
            label='Role'
            value={filter.role}
            values={[
              "All",
              "Admin",
              "GroupAdmin",
              "Transcriber",
              "TRQA",
              "QA",
              "Synthesizer",
              "InHouseQA",
              "Proofreader"
            ]}
            onChange={handleChangeRole}
          />
        </div>
        <div className='w-1/4 -ml-3 -mt-4'>
          <Select
            name='userActivity'
            label='Activity'
            value={filter.activity}
            values={[
              "All",
              { value: "Progress", label: "Work In Progress" },
              { value: "NoProgress", label: "No Work in Progress" }
            ]}
            itemValue='value'
            itemDisplay='label'
            onChange={handleChangeActivity}
          />
        </div>
        <div className='w-1/4 -ml-3 -mt-4'>
          <Select
            name='userLanguage'
            label='Language'
            value={filter.language}
            values={["All", ...LANGUAGES]}
            itemValue='value'
            itemDisplay='label'
            onChange={handleChangeLanguage}
          />
        </div>
        <div className='mr-5 ml-auto'>
          <span className='text-gray-500 text-xs font-bold'>
            {getInactive ? "Include inactive users" : "Show only active users"}
          </span>
          <Switch
            checked={!getInactive}
            onChange={handleChangeInactive}
            value={getInactive}
            color='primary'
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={
          users &&
          users.map((user) => ({
            ...user,
            className: !user.active ? "disabled" : ""
          }))
        }
        totalCount={totalCount}
        pagination={pagination}
        sort={sort}
        onRowSelect={goToUser}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
      />
    </Fragment>
  );
};

export default Users;
