export default {
  all: "All",
  assignedQAInteractions: "Ready for QA Interactions assigned to you",
  claimingInteraction: "Claim Interaction?",
  claimableQAInteractions: "Ready for QA Interactions available for claim",
  completeInteraction:
    "You cannot claim another interaction until you have completed this interaction.",
  interactions: "Interactions",
  interactionState: "Interaction State",
  newInteraction: " New Interaction",
  QA: "QA",
  tooltip: {
    nonEnglish: "Non-English",
    prioritized: "Prioritized",
    suspended: "Suspended",
    qaOnly: "QA Only"
  }
};
