import React from "react";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

export const InputRating = ({ name, value, label, className, onChange, ...otherProps }) => {
  const onRatingChange = (event, newValue) => {
    onChange({ target: { name: name, value: newValue } });
  };
  const nonce = Date.now();

  return (
    <div className={className}>
      <div className='ml-1 flex justify-start rating-label'>{label}</div>
      <div className='mt-1 flex justify-start'>
        <Rating
          name={`${name}-${nonce}`}
          value={value || 0}
          onChange={onRatingChange}
          emptyIcon={<StarIcon />}
          {...otherProps}
        />
      </div>
    </div>
  );
};

InputRating.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
};

InputRating.defaultProps = {
  label: "Rating",
  onChange: () => {}
};

export default InputRating;
