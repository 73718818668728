import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { Close, CheckCircle, Warning, Info, Error } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { red, green, amber, grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
};

const variantColor = {
  success: {
    backgroundColor: green[800]
  },
  error: {
    backgroundColor: red[800]
  },
  info: {
    backgroundColor: grey[600]
  },
  warning: {
    backgroundColor: amber[600]
  }
};

export default function Notification(props) {
  const { spacing } = useTheme();
  const classes = makeStyles(() => ({
    icon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: spacing(1)
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  }));
  const { open, message, onClose, variant } = props;
  const Icon = variantIcon[variant];
  const color = variantColor[variant];
  const seconds = 5;
  const isAutoClosed = ['success', 'info'].includes(variant) ? seconds * 1000 : null;

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
        autoHideDuration={isAutoClosed}>
        <SnackbarContent
          style={color}
          sx={{ padding: '10px 16px' }}
          message={
            <span>
              <Icon className={classes['icon']} />
              {message}
            </span>
          }
          action={[
            <IconButton key='close' aria-label='Close' onClick={handleClose}>
              <Close sx={{ color: '#fff' }} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </div>
  );
}

Notification.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.string
};

Notification.defaultProps = {
  open: false,
  variant: 'info'
};
