import React from 'react';
import { Button, Paper } from '@mui/material';
import { confirmVariant, saveVariant } from '../AITranscript/constants';
import { COLOURS } from 'Constants';

const Wrapper = ({
  header,
  children,
  button,
  className,
  disabled,
  dataTestId,
  colour = 'bg-yellow-100'
}) => {
  const { variant, onClick, disabled: disableButton } = button || {};

  const styles = {
    borderColor: COLOURS.customBlack,
    border: '1px solid',
    width: 'fit-content',
    margin: '1rem auto 0'
  };

  const buttonStyles = {
    ...styles,

    '&:disabled': {
      ...styles
    }
  };

  const paperBackground = colour ? { backgroundColor: colour } : {};

  return (
    <Paper
      className={`p-10 rounded-lg border border-solid border-greyBorder flex flex-col font-bold leading-8 mt-6 ${className} ${disabled} text-customBlack`}
      style={{
        ...paperBackground
      }}
      data-testid={dataTestId}>
      <h1 className='text-lg text-left'>{header}</h1>
      {children}
      {variant && (
        <Button
          className='mt-4 mx-auto border rounded border-solid font-bold text-sm px-4 py-1 uppercase'
          sx={buttonStyles}
          onClick={onClick}
          disabled={disableButton}>
          {variant === confirmVariant ? confirmVariant : saveVariant}
        </Button>
      )}
    </Paper>
  );
};

export default Wrapper;
