export const wrongLanguageIndicator = [
  "The transcript is in the ",
  "wrong language ",
  "indicated."
];

export const FORM_TO_SUBMIT_WRONG_LANGUAGE = {
  reasonLabel: "Reasons ",
  required: "*",
  explainer:
    "By marking the transcript as being in the wrong language, you will stop working on this transcript and will not be compensated for the work on this transcript."
};

export default {
  reasonDisplay: "Reasons: ",
  submitButton: "Submit",
  title: "Wrong language set up",
  languageServicesUpdated:
    "The language services team has i) updated the source and/or target language ii) clicked the save button",
  languageServicesHasBeenNotified:
    "The language services team has been notified and this interaction has been suspended."
};
