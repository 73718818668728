import React, { Fragment } from "react";
import PropTypes from "prop-types";

export const Heading = (props) => {
  const { title, className, secondaryContent } = props;
  return (
    <div className={`flex flex-row justify-between items-center ${className}`}>
      <div className='font-sans font-bold text-lg text-gray-600'>{title}</div>
      {secondaryContent && <Fragment>{secondaryContent}</Fragment>}
    </div>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryContent: PropTypes.node
};

Heading.defaultProps = {
  title: ""
};

export default Heading;
