import { get, post, del, getPagedSorted, toDateString } from 'services/ServiceBase';
import { USER_ROLES } from 'Constants';

export const getUsers = (pagination, sort, getInactive = true) =>
  getPagedSorted(`/api/users?getInactive=${getInactive}&`, pagination, sort);

export const getUsersByFilter = (pagination, sort, filter, getInactive = true) => {
  const role = filter.role === 'All' ? '' : filter.role;
  const language = filter.language === 'All' ? '' : filter.language;
  const isWorkingOnClaim =
    filter.activity === 'All' ? '' : filter.activity === 'Progress' ? true : false;

  return getPagedSorted(
    `/api/users?role=${role}&language=${language}&isWorkingOnClaim=${isWorkingOnClaim}&getInactive=${getInactive}&`,
    pagination,
    sort
  );
};

export const getTranscribers = () =>
  get(`/api/users?roles=${USER_ROLES.transcriber},${USER_ROLES.trqa}&pageSize=0`); /// pageSize=0 means return all Transcribers

export const getQAs = () => get(`/api/users?roles=${USER_ROLES.qa},${USER_ROLES.trqa}&pageSize=0`); /// pageSize=0 means return all QAs

export const getProofreaders = () => get(`/api/users?roles=${USER_ROLES.proofreader}&pageSize=0`); /// pageSize=0 means return all Proofreaders

export const getSynthesizers = () => get(`/api/users?roles=${USER_ROLES.synthesizer}&pageSize=0`); /// pageSize=0 means return all Synthesizers

export const getUsersWithGrades = (task, startDate, pagination, sort) =>
  getPagedSorted(
    `/api/users/grades?task=${task}&startDate=${toDateString(startDate)}&`,
    pagination,
    sort
  );

export const getGroupUsersWithGrades = (startDate, groupName, pagination, sort) =>
  getPagedSorted(
    `/api/users/grades/group/${groupName}?startDate=${toDateString(startDate)}&`,
    pagination,
    sort
  );

export const getGPA = (userId, startDate, endDate) =>
  get(
    `/api/users/gpa/${userId}?startDate=${toDateString(startDate)}&endDate=${toDateString(endDate)}`
  );

export const getMonthlyProgress = (userId, monthDate) =>
  get(`/api/users/monthly-progress/${userId}?monthDate=${toDateString(monthDate)}`);

export const getUser = (id) => get(`/api/users/${id}`);

export const saveUser = (user) => {
  const url = user.id ? `/api/users/${user.id}` : '/api/users/new';
  return post(url, user);
};

export const deleteUser = (id) => del(`/api/users/${id}`);

export const getGradeSchedule = (id) => get(`/api/users/gradeSchedule/${id}`);

export const getBankDetails = (id) => get(`/api/users/bank/${id}`);

export const saveGradeSchedule = (gradeSchedule) => post('/api/users/gradeSchedule', gradeSchedule);

export const requestPasswordReset = (email) =>
  post(`/api/users/resetpassword/request?email=${encodeURIComponent(email)}`);

export const resetPassword = (email, token, newPassword) =>
  post(
    `/api/users/resetpassword?email=${encodeURIComponent(email)}&token=${encodeURIComponent(
      token
    )}&newPassword=${encodeURIComponent(newPassword)}`
  );

export const deleteUserLanguageRate = (id) => del(`/api/users/userLanguageRate/${id}`);

export const saveUserLanguageRate = (userLanguageRate, userLanguageId) => {
  const url = `/api/users/userLanguageRate/${userLanguageRate.id}?userLanguageId=${userLanguageId}`;
  return post(url, userLanguageRate);
};
