import React from 'react';

import Wrapper from 'components/Wrapper';
import { HEADER, TRANSCRIPTS, DOWNLOAD_BUTTONS } from './text';
import DownloadButton from './DownloadButton';
import { assetTypes, ORIGINAL_TRANSCRIPT_KEY, PROCESSED_TRANSCRIPT_KEY } from './constants';
import {
  downloadOriginalTranscript,
  downloadProcessedTranscript
} from 'services/InteractionsService';

const AITranscriptFiles = ({ interactionId }) => {
  const transcripts = [ORIGINAL_TRANSCRIPT_KEY, PROCESSED_TRANSCRIPT_KEY];

  return (
    <Wrapper header={HEADER} colour={'bg-inherit'}>
      {transcripts.map((transcript) => (
        <div
          className='p-5 flex rounded-lg mx-20 mt-4 font-bold text-sm justify-between items-center bg-yellow-100'
          key={transcript}>
          {TRANSCRIPTS[transcript]}
          <DownloadButton
            interactionId={interactionId}
            text={DOWNLOAD_BUTTONS[transcript]}
            downloadFn={
              transcript === ORIGINAL_TRANSCRIPT_KEY
                ? downloadOriginalTranscript
                : downloadProcessedTranscript
            }
            assetType={assetTypes[transcript]}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default AITranscriptFiles;
