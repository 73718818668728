import React, { useState, useEffect, useContext, Fragment } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { getSummariesDailySnapshot, getSummariesSnapshot } from 'services/ReportsService';
import TextField from 'components/TextField';
import GradeValue from 'components/GradeValue';
import { AppContext } from 'AppContext';
import { secondsToHhmmss } from 'utils';
import LoadingDialog from 'components/LoadingDialog';
import moment from 'moment';

export const DashboardSummaries = () => {
  const [dailySnapshot, setDailySnapshot] = useState();
  const [snapshot, setSnapshot] = useState();
  const [snapshotPeriod, setSnapshotPeriod] = useState('currentWeek');
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const lastWeek = moment().subtract(1, 'weeks').startOf('week');
  const lastMonth = moment().subtract(1, 'months').startOf('month');

  useEffect(() => {
    if (snapshot && dailySnapshot) setLoading(false);
  }, [snapshot, dailySnapshot]);

  useEffect(() => {
    const loadDailySnapshot = async () => {
      try {
        setLoading(true);
        const result = await getSummariesDailySnapshot();
        setDailySnapshot(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };
    loadDailySnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadSnapshot = async () => {
      try {
        setLoading(true);
        let startDate = moment();
        let endDate = moment();
        if (isNaN(snapshotPeriod)) {
          switch (snapshotPeriod) {
            case 'currentWeek':
              startDate = moment().startOf('weeks');
              break;
            case 'lastWeek':
              startDate = lastWeek;
              endDate = moment(startDate).endOf('weeks');
              break;
            case 'currentMonth':
              startDate = moment().startOf('months');
              break;
            case 'lastMonth':
              startDate = lastMonth;
              endDate = moment(startDate).endOf('months');
              break;
            default:
              break;
          }
        } else {
          startDate.subtract(snapshotPeriod, 'days');
        }
        const result = await getSummariesSnapshot(startDate.format(), endDate.format());
        setSnapshot(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };
    loadSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotPeriod]);

  return (
    <div>
      <LoadingDialog show={loading} />
      <div>
        <div className='flex flex-row justify-between items-center mt-5 mx-5'>
          <div className='font-sans font-bold text-lg text-gray-700'>
            Status over the past 24 hours
          </div>
        </div>
        {dailySnapshot && (
          <div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Synthesized Status
              </div>
              <div className='flex flex-wrap mb-5'>
                <TextField
                  label='Open Synthesized'
                  value={dailySnapshot.openSummaries}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized Completed'
                  value={dailySnapshot.completedSummaries}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized in Progress'
                  value={dailySnapshot.summariesInProgress}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized Awaiting Claim'
                  value={dailySnapshot.unclaimedSummaries}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Audio Hours
              </div>
              <div className='flex flex-wrap mb-5'>
                <TextField
                  label='Open Synthesized'
                  value={secondsToHhmmss(dailySnapshot.inCompleteSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized Completed'
                  value={secondsToHhmmss(dailySnapshot.completedSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized in Progress'
                  value={secondsToHhmmss(dailySnapshot.summariesInProgressSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized Awaiting Claim'
                  value={secondsToHhmmss(dailySnapshot.unclaimedSummariesSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
            <div className='ml-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Timeliness
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='Synthesized Lag Time'
                  value={secondsToHhmmss(dailySnapshot.summariesClaimLagAverageSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Synthesized Delayed'
                  value={`${dailySnapshot.delayedSummariesPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Synthesized On Time'
                  value={`${100 - dailySnapshot.delayedSummariesPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='mt-10'>
        <div className='flex flex-row justify-between items-center mt-5 mx-5'>
          <div className='font-sans font-bold text-lg text-gray-700 flex flex-row items-baseline'>
            <div className='mr-2'>Snapshot over</div>
            <FormControl variant='standard'>
              <MuiSelect
                value={snapshotPeriod}
                onChange={(event) => {
                  setSnapshotPeriod(event.target.value);
                }}>
                <MenuItem key='currentWeek' value='currentWeek'>
                  current week ({moment().startOf('weeks').format('MMM D')})
                </MenuItem>
                <MenuItem key='lastWeek' value='lastWeek'>
                  last week ({lastWeek.format('MMM D')})
                </MenuItem>
                <MenuItem key='currentMonth' value='currentMonth'>
                  current month ({moment().format('MMMM')})
                </MenuItem>
                <MenuItem key='lastMonth' value='lastMonth'>
                  last month ({lastMonth.format('MMMM')})
                </MenuItem>
                <MenuItem key='day7' value={7}>
                  past 7 days
                </MenuItem>
                <MenuItem key='day30' value={30}>
                  past 30 days
                </MenuItem>
              </MuiSelect>
            </FormControl>
          </div>
        </div>
        {snapshot && (
          <div>
            <div className='ml-5 mt-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Completion
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='Synthesized Completed'
                  value={snapshot.totalSummaries}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='AH Completed'
                  value={secondsToHhmmss(snapshot.uploadedSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='Synthesized Cancelled'
                  value={snapshot.totalCancelledSummaries}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
            </div>
            <div className='ml-5 mt-5'>
              <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                Timeliness
              </div>
              <div className='flex flex-wrap'>
                <TextField
                  label='Average Time Per Synthesized'
                  value={secondsToHhmmss(snapshot.summaryCompleteAverageSeconds)}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <Fragment>
                  <TextField
                    label='Lag Time'
                    value={secondsToHhmmss(snapshot.summaryClaimLagAverageSeconds)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                </Fragment>
                <TextField
                  label='% Synthesized Delayed'
                  value={`${snapshot.delayedSummariesPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
                <TextField
                  label='% Synthesized On Time'
                  value={`${100 - snapshot.delayedSummariesPercent}%`}
                  inputProps={{ readOnly: true }}
                  className='input-1-5'
                />
              </div>
              <div className='mt-5 mb-20'>
                <div className='flex justify-start font-sans font-bold text-base text-gray-500'>
                  Audio Feedback
                </div>
                <div className='flex flex-wrap mb-5'>
                  <div className='input input-1-5'>
                    <div className='mb-1 flex justify-start rating-label'>
                      Average Audio Quality
                    </div>
                    <GradeValue
                      grade={snapshot.audioQualityAverage}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <TextField
                    label='Average # of Speakers'
                    value={snapshot.speakersAverage.toFixed(2)}
                    inputProps={{ readOnly: true }}
                    className='input-1-5'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardSummaries;
