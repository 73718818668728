import React from "react";

import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { doDownload } from "utils";

const DownloadButton = ({ interactionId, text, downloadFn, assetType }) => {
  const handleClick = async (event) => {
    const blob = await downloadFn(interactionId);
    await doDownload(`${interactionId}_${assetType}.docx`, blob);
    event.stopPropagation();
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={<Download />}
      variant='outlined'
      sx={{
        width: "147px",
        fontSize: "12px",
        textTransform: "none",
        boxSizing: "content-box",
        padding: "8px 12px",
        lineHeight: "16px",
        borderColor: "#E2E4E9",
        background: "#FFFFFF",
        "& .MuiButton-startIcon": {
          "& > *:nth-of-type(1)": {
            fontSize: "16px"
          }
        }
      }}
    >
      {text}
    </Button>
  );
};

export default DownloadButton;
