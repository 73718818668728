import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import "./index.css";

const Header = () => {
  return (
    <header className='h-16 flex flex-row justify-between items-center'>
      <div>
        <Link to='/'>
          <img className='object-contain h-10 pl-2' src={logo} width='90%' alt='AlphaScribes' />
        </Link>
      </div>
    </header>
  );
};

export default Header;
