import { Autocomplete } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { useState } from "react";

import { userToString } from "utils";

const AssigneeSelect = ({
  defaultValue,
  label,
  onChange,
  dataTestId = "",
  options = [],
  className = "",
  disabled = false
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  return (
    <Autocomplete
      className={className}
      options={options}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => userToString(option)}
      renderInput={(params) => <MuiTextField {...params} label={label} />}
      onChange={handleChange}
      disabled={disabled}
      data-testid={`${dataTestId}-assignee-select`}
      disableClearable
    />
  );
};

export default AssigneeSelect;
