import React from "react";
import PropTypes from "prop-types";
import TextField from "components/TextField";
import Paper from "@mui/material/Paper";

export const DelayReason = (props) => {
  const { delayReason, onDelayReasonChange, className, disabled } = props;
  const stateClassName = delayReason
    ? "state-color-complete-secondary"
    : "state-color-actionable-secondary";

  return (
    <Paper className={`${className} ${stateClassName}`}>
      <div className='w-4/5 my-4 mx-auto pb-8'>
        <TextField
          name='delayReason'
          label='Reason for Delay'
          value={delayReason}
          multiline
          variant='outlined'
          onChange={onDelayReasonChange}
          inputProps={{
            readOnly: disabled
          }}
        />
      </div>
    </Paper>
  );
};

DelayReason.propTypes = {
  delayReason: PropTypes.string.isRequired,
  onDelayReasonChange: PropTypes.func,
  disabled: PropTypes.bool
};

DelayReason.defaultProps = {
  onDelayReasonChange: () => {}
};

export default DelayReason;
