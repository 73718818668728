import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import "./index.css";

export const InputFile = ({ formId, initialLabel, onChange, disabled }) => {
  const [label, setLabel] = useState(initialLabel);

  const onFileChange = (event) => {
    if (event.target.value) {
      onChange();
    } else {
      setLabel(initialLabel);
    }
  };

  return (
    <Fragment>
      <input
        type='file'
        id={formId}
        className='inputfile'
        onChange={onFileChange}
        disabled={disabled}
      />
      <Button variant='outlined'>
        <label htmlFor={formId}>{label}</label>
      </Button>
    </Fragment>
  );
};

InputFile.propTypes = {
  formId: PropTypes.string.isRequired,
  initialLabel: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

InputFile.defaultProps = {
  initialLabel: "choose a file",
  onChange: () => {}
};

export default InputFile;
