import React, { useState, Fragment, useContext, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { RateReview, Chat, MarkChatRead } from "@mui/icons-material";
import { getUsersWithGrades, getGroupUsersWithGrades } from "services/UsersService";
import { searchUsers } from "services/SearchService";
import { AppContext } from "AppContext";
import Table from "components/Table";
import LoadingDialog from "components/LoadingDialog";
import { utcToLocalDateString } from "utils";
import GradeValue from "components/GradeValue";
import Heading from "components/Heading";
import Search from "components/Search";
import { TASKS } from "../../Constants";
import { COLUMN_DEFINITIONS, PAGINATION_DEFAULTS, SORT_DEFAULTS } from "./constants";
import { getDefaultTask, mapTaskToRole, getGradePeriod } from "./utils";

export const DATA_TEST_IDS = {
  qaToggle: "qa-toggle",
  transcribeToggle: "transcribe-toggle",
  correctAIToggle: "correctAI-toggle"
};

const Grades = (props) => {
  const appContext = useContext(AppContext);
  const group = appContext.auth.userContext.groupName || props.match.params.groupName;
  const viewState = appContext.viewStates[props.location.pathname] || {};
  const unlistenHistory = props.history.listen((location) => {
    if (location.pathname !== props.location.pathname) {
      appContext.setViewState(props.location.pathname, {
        users,
        task,
        columns,
        pagination,
        sort
      });
    }
  });
  const [users, setUsers] = useState(
    viewState.users || {
      data: null
    }
  );
  const [task, setTask] = useState(getDefaultTask(group));
  const [columns, setColumns] = useState(viewState.columns || COLUMN_DEFINITIONS[task]);

  const [pagination, setPagination] = useState(viewState.pagination || PAGINATION_DEFAULTS);
  const [sort, setSort] = useState(viewState.sort || SORT_DEFAULTS);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - getGradePeriod(task));
        const result = group
          ? await getGroupUsersWithGrades(startDate, group, pagination, sort)
          : await getUsersWithGrades(task, startDate, pagination, sort);
        setUsers(result);
        setColumns(COLUMN_DEFINITIONS[task]);
      } catch (error) {
        appContext.showNotification("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    loadUsers();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, task, sort]);

  const goToUser = (id) => {
    switch (task) {
      case TASKS.qa:
      case TASKS.correctAI:
        props.history.push(`/workhistory/qa/${id}`);
        break;
      case TASKS.transcribe:
        props.history.push(`/workhistory/transcriber/${id}`);
        break;
      default:
        props.history.push("/");
        break;
    }
  };

  const changeTask = (_event, newTask) => {
    if (newTask) {
      setTask(newTask);
      setPagination(PAGINATION_DEFAULTS);
      setSort(SORT_DEFAULTS);
    }
  };

  const changePage = (_event, pageNumber) => {
    setPagination({
      ...pagination,
      pageNumber
    });
  };

  const changeSort = (field) => {
    let order = "asc";
    if (field === sort.field && sort.order === "asc") {
      order = "desc";
    }
    setSort({ field, order });
  };

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      <Search
        searchFunc={(searchString) => searchUsers(searchString, mapTaskToRole(task))}
        onSelect={(id) => goToUser(id)}
      />
      <Heading
        title={`${group ? group : task} Grades`}
        secondaryContent={
          !group && (
            <ToggleButtonGroup value={task} exclusive onChange={changeTask}>
              <ToggleButton value={TASKS.qa} data-testid={DATA_TEST_IDS.qaToggle}>
                <Chat />
              </ToggleButton>
              <ToggleButton value={TASKS.transcribe} data-testid={DATA_TEST_IDS.transcribeToggle}>
                <RateReview />
              </ToggleButton>
              <ToggleButton value={TASKS.correctAI} data-testid={DATA_TEST_IDS.correctAIToggle}>
                <MarkChatRead />
              </ToggleButton>
            </ToggleButtonGroup>
          )
        }
        className='m-5'
      />
      <Table
        columns={columns}
        data={
          users.data &&
          users.data.map((datum) => ({
            id: datum.user.id,
            email: datum.user.email,
            grade: <GradeValue grade={datum.grade} />,
            nextGradeDate: utcToLocalDateString(datum.nextGradeDate, true)
          }))
        }
        totalCount={users.totalCount || 0}
        pagination={pagination}
        sort={sort}
        onRowSelect={goToUser}
        onChangePage={changePage}
        onChangeSort={changeSort}
      />
      <div className='mb-10' />
    </Fragment>
  );
};

export default Grades;
