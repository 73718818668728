import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import Heading from 'components/Heading';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/GetApp';
import MuiTextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Reports = (props) => {
  const appContext = useContext(AppContext);
  const group = appContext.auth.userContext.groupName || props.match.params.groupName;
  const today = new Date();
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [endDate, setEndDate] = useState(today);

  const formatDate = (date, addDay = false) => {
    if (date === null) return null;
    const formattedDate = date.set ? date.toDate() : new Date(+date);
    const utcOffset = formattedDate.getTimezoneOffset();
    formattedDate.setHours(0);
    formattedDate.setMinutes(0 - utcOffset);
    formattedDate.setSeconds(0);
    formattedDate.setMilliseconds(0);
    if (addDay) formattedDate.setDate(formattedDate.getDate() + 1);
    return formattedDate.toISOString();
  };

  return (
    <div>
      <Heading title={`${group} Reports`} className='m-5' />
      <div className='flex flex-row ml-10'>
        <div className='flex flex-col justify-start items-baseline'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Start Date'
              value={startDate}
              inputFormat='MMMM dd, yyyy'
              disableMaskedInput
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: ''
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className='flex flex-col justify-start items-baseline ml-8'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='End Date'
              value={endDate}
              inputFormat='MMMM dd, yyyy'
              disableMaskedInput
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: ''
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className='ml-8 mt-6'>
        <div className='flex justify-start'>
          <Button
            href={`/api/reports/group/split-timeliness/${group}?startDate=${formatDate(
              startDate
            )}&endDate=${formatDate(endDate, true)}`}
            startIcon={<DownloadIcon />}>
            Split Timeliness
          </Button>
        </div>
        <div className='flex justify-start'>
          <Button
            href={`/api/reports/group/transcribers/${group}?startDate=${formatDate(
              startDate
            )}&endDate=${formatDate(endDate, true)}`}
            startIcon={<DownloadIcon />}>
            TR Report
          </Button>
        </div>
        <div className='flex justify-start'>
          <Button
            href={`/api/reports/group/split-extensions/${group}?startDate=${formatDate(
              startDate
            )}&endDate=${formatDate(endDate, true)}`}
            startIcon={<DownloadIcon />}>
            Splits Extensions
          </Button>
        </div>
        <div className='flex justify-start'>
          <Button
            href={`/api/reports/group/split-drops/${group}?startDate=${formatDate(
              startDate
            )}&endDate=${formatDate(endDate, true)}`}
            startIcon={<DownloadIcon />}>
            Splits Drops
          </Button>
        </div>
        <div className='flex justify-start'>
          <Button
            href={`/api/reports/group/user-drops-extensions/${group}?startDate=${formatDate(
              startDate
            )}&endDate=${formatDate(endDate, true)}`}
            startIcon={<DownloadIcon />}>
            User Drops &amp; Extensions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
