import { get, post, download, postFile, del, getPagedSorted } from "services/ServiceBase";

export const getSplit = (id) => get(`/api/splits/detail/${id}`);

export const getWorkInProgressSplits = () => get("/api/splits/workInProgress");

export const getAssignedSplits = () => get("/api/splits/assigned");

export const getClaimableSplits = () => get("/api/splits/claimable");

export const getSplits = (interactionId) =>
  get(`/api/splits/interaction/${interactionId}?pageSize=0`);

export const getTranscriberSplits = (transcriberId, pagination, sort, searchString) =>
  getPagedSorted(
    `/api/splits/transcriber/${transcriberId}?searchString=${encodeURIComponent(searchString)}&`,
    pagination,
    sort
  );

export const claimSplit = (id) => post(`/api/splits/claim/${id}`, {});

export const cancelSplitClaim = (id) => post(`/api/splits/cancel/claim/${id}`, {});

export const downloadTranscript = (id) => download(`/api/splits/download/${id}`);

export const downloadEditedTranscript = (id) => download(`/api/splits/download/edited/${id}`);

export const uploadTranscript = (id, file) => postFile(`/api/splits/upload/${id}`, file);

export const uploadEditedTranscript = (id, file) =>
  postFile(`/api/splits/upload/edited/${id}`, file);

export const saveSplit = (split) => {
  const url = split.id ? `/api/splits/save/${split.id}` : "/api/splits/new";
  return post(url, split);
};

export const deleteSplit = (id) => del(`/api/splits/${id}`);

export const stateToColorClasses = (state) => {
  switch (state) {
    case "New":
      return {
        primary: "state-color-new",
        secondary: "state-color-new-secondary"
      };
    case "Assigned":
      return {
        primary: "state-color-assigned",
        secondary: "state-color-assigned-secondary"
      };
    case "Claimed":
      return {
        primary: "state-color-inProgress",
        secondary: "state-color-inProgress-secondary"
      };
    case "Transcribed":
      return {
        primary: "state-color-actionable",
        secondary: "state-color-actionable-secondary"
      };
    case "Completed":
    case "Graded":
      return {
        primary: "state-color-complete",
        secondary: "state-color-complete-secondary"
      };
    case "Invalid":
      return {
        primary: "state-color-error",
        secondary: "state-color-error-secondary"
      };
    default:
      return {
        primary: "",
        secondary: ""
      };
  }
};
