import React, { useContext, useEffect, useState } from 'react';
import { Button, Paper, FormControl, MenuItem, Tooltip } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import MuiSelect from '@mui/material/Select';
import NotesIcon from '@mui/icons-material/Notes';

import { getQaInteractions, stateToColorClasses } from 'services/InteractionsService';
import { getGPA, getGradeSchedule, saveGradeSchedule } from 'services/UsersService';
import Table from 'components/Table';
import { AppContext } from 'AppContext';
import Heading from 'components/Heading';
import GradeValue from 'components/GradeValue';
import { secondsToHhmmss, utcToLocalDateString } from 'utils';
import TextField from 'components/TextField';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';
import { searchUsers } from 'services/SearchService';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const columns = [
  {
    label: 'Interaction ID',
    field: 'alphasightsId',
    sortField: 'alphasightsId'
  },
  {
    label: 'Project ID',
    field: 'projectId',
    sortField: 'projectId'
  },
  { label: 'Duration', field: 'length', sortField: 'audioSeconds' },
  {
    label: 'Deadline',
    field: 'deadline',
    sortField: 'claim.deadline'
  },
  { label: 'Grade', field: 'grade', sortField: 'assessment.totalGrade' },
  { label: 'Comments', field: 'comments', unsortable: true }
];

const WorkHistoryQa = (props) => {
  const { id, searchString, pagination, changePage, sort, changeSort, unlistenHistory } = props;
  const appContext = useContext(AppContext);
  const [interactions, setInteractions] = useState();
  const [gpa, setGpa] = useState(0);
  const [gpaDays, setGpaDays] = useState(30);
  const [gradeSchedule, setGradeSchedule] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadInteractionsAndGradeSchedule = async () => {
      try {
        setLoading(true);
        let result = await getQaInteractions(id, pagination, sort, searchString);
        setTotalCount(result.totalCount);
        setInteractions(result.data);
        if (id === appContext.auth.id) {
          result = null;
        } else {
          result = await getGradeSchedule(id);
        }
        setGradeSchedule(result);
      } catch (error) {
        appContext.showNotification('error', error.message);
        props.history.replace('/');
      } finally {
        setLoading(false);
      }
    };
    loadInteractionsAndGradeSchedule();
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination, sort, searchString]);

  useEffect(() => {
    const loadGpa = async () => {
      try {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - gpaDays);
        setLoading(true);
        const result = await getGPA(id, startDate, new Date());
        setGpa(isNaN(result) ? 0 : result);
      } catch (error) {
        appContext.showNotification('error', error.message);
        props.history.replace('/');
      } finally {
        setLoading(false);
      }
    };
    loadGpa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpaDays, id]);

  const changeGradeSchedule = (name, value) => {
    setGradeSchedule({ ...gradeSchedule, [name]: value });
  };

  const scheduleNextGrade = async () => {
    try {
      setLoading(true);
      await saveGradeSchedule(gradeSchedule);
      appContext.showNotification('success', 'Grade schedule saved.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const goToInteraction = (id) => {
    props.history.push(`/interactions/${id}`);
  };

  const showCommentsDialog = (message) => {
    appContext.showCloseDialog('Comments', message);
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      {['Admin', 'InHouseQA'].includes(appContext.auth.role) && (
        <Search searchFunc={searchUsers} onSelect={(id) => props.history.push(`/users/${id}`)} />
      )}
      <Heading
        title={`Transcription QA history for user [${id}]`}
        secondaryContent={
          <div className='flex flex-row items-center'>
            <div className='text-sm'>
              Average grade
              {appContext.auth.role === 'TRQA' && ' (TR and QA)'} for the past
            </div>
            <div className='mx-2'>
              <FormControl variant='standard'>
                <MuiSelect
                  value={gpaDays}
                  onChange={(event) => {
                    setGpaDays(event.target.value);
                  }}>
                  <MenuItem key='day7' value={7}>
                    7
                  </MenuItem>
                  <MenuItem key='day30' value={30}>
                    30
                  </MenuItem>
                  <MenuItem key='day90' value={90}>
                    90
                  </MenuItem>
                  <MenuItem key='day180' value={180}>
                    180
                  </MenuItem>
                  <MenuItem key='day365' value={365}>
                    365
                  </MenuItem>
                </MuiSelect>
              </FormControl>{' '}
            </div>
            <div>days:</div>
            <GradeValue grade={gpa} />
          </div>
        }
        className='m-5'
      />
      {gradeSchedule && (
        <Paper className='m-5'>
          <div className='component-heading'>Grade Schedule</div>
          <div className='pb-8 flex flex-col'>
            <div className='flex flex-col justify-start items-baseline ml-8'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Next grade date'
                  value={gradeSchedule.nextGradeDate}
                  inputFormat='MMMM dd, yyyy'
                  disableMaskedInput
                  onChange={(date) => changeGradeSchedule('nextGradeDate', date)}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: ''
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className='pb-8'>
              <TextField
                name='notes'
                label='Comments'
                value={gradeSchedule.notes}
                multiline
                variant='outlined'
                onChange={({ target: { name, value } }) => changeGradeSchedule(name, value)}
              />
            </div>
            <div>
              <Button variant='outlined' onClick={scheduleNextGrade}>
                Save
              </Button>
            </div>
          </div>
        </Paper>
      )}
      <Table
        columns={columns}
        data={
          interactions &&
          interactions.map((interaction) => ({
            id: interaction.id,
            alphasightsId: (
              <div className='flex flex-row items-center rowSelectable'>
                <div>{interaction.alphasightsId}</div>
                {interaction.qaOnly && ['Admin', 'InHouseQA'].includes(appContext.auth.role) && (
                  <div className='ml-2'>
                    <Tooltip title='QA Only' placement='top'>
                      <div className='text-xs font-bold'>QA</div>
                    </Tooltip>
                  </div>
                )}
              </div>
            ),
            projectId: interaction.projectId,
            length: secondsToHhmmss(interaction.audioSeconds),
            grade: interaction.assessment ? (
              <GradeValue grade={interaction.assessment.totalGrade} />
            ) : (
              ''
            ),
            deadline: interaction.claim
              ? utcToLocalDateString(interaction.claim.deadline, true)
              : '',
            comments:
              interaction.assessment && interaction.assessment.comments ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    let comments = interaction.assessment.comments;
                    if (interaction.assessment.additionalComments) {
                      comments = `Comments:\n${comments}\n\nClient Comments:\n${interaction.assessment.additionalComments}`;
                    }
                    showCommentsDialog(comments);
                  }}>
                  <NotesIcon />
                </Button>
              ) : null,
            className: stateToColorClasses(interaction.state).secondary
          }))
        }
        totalCount={totalCount || 0}
        onRowSelect={goToInteraction}
        pagination={pagination}
        sort={sort}
        onChangePage={changePage}
        onChangeSort={changeSort}
      />
    </div>
  );
};

export default WorkHistoryQa;
