import React, { useState, useEffect } from 'react';
import { useDebounce } from 'hooks/useDebounce';

import { Autocomplete, CircularProgress, InputAdornment, TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

const Search = ({ searchFunc, onSelect, placeholder }) => {
  const [searchString, setSearchString] = useState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchString = useDebounce(searchString, 500);

  useEffect(() => {
    let active = true;
    if (debouncedSearchString || !onSelect) {
      (async () => {
        setLoading(true);
        const response = await searchFunc(debouncedSearchString);
        if (active) {
          setLoading(false);
          setOptions(response);
        }
      })();
    } else {
      setOptions([]);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  const searchTextField = (props) => (
    <TextField
      {...props}
      placeholder={placeholder}
      fullWidth
      variant='outlined'
      size='small'
      onChange={(event) => {
        setSearchString(event.target.value);
      }}
      InputProps={{
        ...props?.InputProps,
        style: {
          borderRadius: 0,
          padding: 0
        },
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
            {props?.InputProps?.endAdornment}
          </>
        )
      }}
    />
  );

  return onSelect ? (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.searchTerm}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <div className='flex flex-row w-full items-center'>
              <div className='w-24 text-sm text-gray-600'>{option.searchField}</div>
              <div>{option.searchTerm}</div>
            </div>
          </li>
        );
      }}
      options={options}
      noOptionsText='No results'
      loading={loading}
      onChange={(event, option) => option && onSelect(option.id)}
      renderInput={(params) => searchTextField(params)}
    />
  ) : (
    searchTextField()
  );
};

Search.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string
};

Search.defaultProps = {};

export default Search;
