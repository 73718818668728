const processResponse = async (response) => {
  if (!response.ok) {
    let message = response.statusText;
    const parsedError = await response.json();

    if (parsedError) {
      message = parsedError.message;
      if (
        message === 'Full authentication is required to access this resource' ||
        message.includes('parameter authUser')
      ) {
        /// Improve generic authentication failure error message from Kotlin service.
        message = 'Login expired.  Reload this page and log in to continue.';
      }
    } else if (response.status >= 500 && response.status < 600) {
      message = 'Backend server unavailable.';
    }
    throw { status: response.status, message };
  }
  return await response.json();
};

export const get = async (url) => {
  const response = await fetch(url);
  return await processResponse(response);
};

export const download = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.blob();
};

export const getPagedSorted = async (url, pagination, sort) => {
  let request = url;
  if (pagination.pageNumber) request += `pageNumber=${pagination.pageNumber}&`;
  if (pagination.pageSize) request += `pageSize=${pagination.pageSize}&`;
  if (sort.field) request += `sortField=${sort.field}&`;
  if (sort.order) request += `order=${sort.order}&`;

  const response = await fetch(request);
  return await processResponse(response);
};

export const post = async (url, props) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  });
  return await processResponse(response);
};

export const patch = async (url, props) => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  });
  return await processResponse(response);
};

export const postForm = async (url, props) => {
  const formData = new FormData();
  for (const name in props) {
    formData.append(name, props[name]);
  }
  const response = await fetch(url, {
    method: 'POST',
    body: formData
  });
  return await processResponse(response);
};

export const postFile = async (url, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await fetch(url, {
    method: 'POST',
    body: formData
  });
  return await processResponse(response);
};

export const del = async (url) => {
  const response = await fetch(url, {
    method: 'DELETE'
  });
  return await processResponse(response);
};

export const toDateString = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
