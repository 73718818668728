import { USER_ROLES, TASKS } from "../../Constants";
import { QA_GRADE_PERIOD, TRANSCRIBE_GRADE_PERIOD } from "./constants";

export const getDefaultTask = (group) => (group ? TASKS.transcribe : TASKS.qa);

const isQATask = (task) => task === TASKS.correctAI || task === TASKS.qa;

export const mapTaskToRole = (task) => (isQATask(task) ? USER_ROLES.qa : USER_ROLES.transcriber);

export const getGradePeriod = (task) =>
  isQATask(task) ? QA_GRADE_PERIOD : TRANSCRIBE_GRADE_PERIOD;
