import { get, post, getPagedSorted, download, postFile } from "services/ServiceBase";

export const getSummaries = (pagination, sort, filterState) => {
  if (!filterState || filterState === "All") {
    return getPagedSorted("/api/summaries?", pagination, sort);
  }
  return getPagedSorted(`/api/summaries/filter?state=${filterState}&`, pagination, sort);
};

export const getClaimableSummaries = () => get("/api/summaries/claimable");

export const getClaimedSummaries = () => get("/api/summaries/claimed");

export const getAssignedSummaries = () => get("/api/summaries/assigned");

export const getSummary = (id) => get(`/api/summaries/detail/${id}`);

export const getSummariesHistory = (userId, pagination, sort, searchString) =>
  getPagedSorted(
    `/api/summaries/history/${userId}?searchString=${encodeURIComponent(searchString)}&`,
    pagination,
    sort
  );

export const claimSummary = (id) => post(`/api/summaries/claim/${id}`, {});

export const cancelSummaryClaim = (id) => post(`/api/summaries/cancel/claim/${id}`, {});

export const downloadTranscript = (id) => download(`/api/summaries/download/${id}`);

export const uploadTranscript = (id, file) => postFile(`/api/summaries/upload/${id}`, file);

export const saveSummary = (summary) => {
  const url = summary.id ? `/api/summaries/save/${summary.id}` : "/api/summaries/new";
  return post(url, summary);
};

export const cancelSummary = (id) => post(`/api/summaries/cancel/${id}`);

export const suspendSummary = (id) => post(`/api/summaries/suspend/${id}`);

export const resumeSummary = (id) => post(`/api/summaries/resume/${id}`);

export const stateToColorClasses = (state) => {
  switch (state) {
    case "New":
      return {
        primary: "state-color-new",
        secondary: "state-color-new-secondary"
      };
    case "InProgress":
      return {
        primary: "state-color-qa-claimed",
        secondary: "state-color-qa-claimed"
      };
    case "Completed":
      return {
        primary: "state-color-complete",
        secondary: "state-color-complete-secondary"
      };
    case "Cancelled":
      return {
        primary: "state-color-cancel",
        secondary: "state-color-cancel-secondary"
      };
    case "Invalid":
      return {
        primary: "state-color-error",
        secondary: "state-color-error-secondary"
      };
    default:
      return {
        primary: "",
        secondary: ""
      };
  }
};
