import React from "react";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";

export const TextField = (props) => {
  const { value, inputProps, className, ...otherProps } = props;

  return (
    <div className={`input ${className}`}>
      <MuiTextField
        value={value === 0 || value ? value : ""}
        {...otherProps}
        inputProps={{
          ...inputProps
        }}
      />
    </div>
  );
};

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.object,
  className: PropTypes.string
};

TextField.defaultProps = {
  fullWidth: true,
  onChange: () => {}
};

export default TextField;
