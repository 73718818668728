import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

import MaterialDialog from '@mui/material/Dialog';

export default function Dialog(props) {
  const {
    open,
    message,
    onClose,
    title,
    onConfirmed,
    cancelLabel,
    confirmLabel,
    fullWidth,
    maxWidth
  } = props;
  const onConfirmClick = () => {
    onClose();
    onConfirmed();
  };

  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}>
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {Array.isArray(message) ? (
          <DialogContentText id='dialog-description'>
            {message.map((comment, index) => (
              <span key={index}>
                • {comment}
                <br />
              </span>
            ))}
          </DialogContentText>
        ) : (
          <DialogContentText id='dialog-description' style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {cancelLabel && <Button onClick={onClose}>{cancelLabel}</Button>}
        <Button onClick={onConfirmClick}>{confirmLabel}</Button>
      </DialogActions>
    </MaterialDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirmed: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string
};

Dialog.defaultProps = {
  open: false,
  message: '',
  onClose: () => {},
  onConfirmed: () => {},
  title: '',
  cancelLabel: 'Cancel',
  confirmLabel: 'OK',
  fullWidth: false,
  maxWidth: 'sm'
};
