import React from "react";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import { isCorrector } from "utils/roles";

export const ToggleGroup = ({
  user,
  handleChange,
  showIndividualContractor
}) => (
  <div className="flex flex-col">
    {showIndividualContractor && (
      <div className="flex ml-8 mt-8 items-center">
        <Switch
          checked={user.individualContractor}
          onChange={(_event, value) =>
            handleChange({
              target: { name: "individualContractor", value }
            })
          }
          value={user.individualContractor}
          disabled={!user.active}
          color="primary"
        />
        <div className="pb-1 text-gray-700">Individual Contractor</div>
      </div>
    )}
    {isCorrector(user) && (
      <div className="flex ml-8 mt-8 items-center">
        <Switch
          checked={user.inTraining}
          onChange={(_event, value) =>
            handleChange({ target: { name: "inTraining", value } })
          }
          value={user.inTraining}
          disabled={!user.active}
          color="primary"
        />
        <div className="pb-1 text-gray-700">Training Mode</div>
      </div>
    )}
  </div>
);

ToggleGroup.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  showIndividualContractor: PropTypes.bool.isRequired
};

export default ToggleGroup;
