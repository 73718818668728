import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { utcToLocalDateString } from 'utils';
import Table from 'components/Table';
import { AppContext } from 'AppContext';

const ClaimExtensionsViewer = ({ claimExtensions, className }) => {
  const appContext = useContext(AppContext);
  const [openViewExtensions, setOpenViewExtensions] = useState(false);

  const showReasonDialog = (message) => {
    appContext.showCloseDialog('Reason', message);
  };

  return (
    <div className={className}>
      <Button onClick={() => setOpenViewExtensions(true)}>View Extensions</Button>
      <Dialog
        open={openViewExtensions}
        onClose={() => setOpenViewExtensions(false)}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>Extensions</DialogTitle>
        <div className='px-6 pb-4'>
          <Table
            columns={[
              { label: 'Date', field: 'date' },
              { label: 'Amount', field: 'amount' },
              { label: 'Requester', field: 'requester' },
              { label: 'Reason', field: 'reasonAction' }
            ]}
            data={claimExtensions.map((extension) => ({
              id: extension.id,
              date: utcToLocalDateString(extension.date),
              amount: `${extension.extensionSeconds / 60} min`,
              requester: `${extension.requester.firstname} ${extension.requester.lastname}`,
              reasonAction: extension.reason ? (
                <Button variant='outlined' onClick={() => showReasonDialog(extension.reason)}>
                  View
                </Button>
              ) : (
                ''
              )
            }))}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ClaimExtensionsViewer;
