import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@mui/material/Table';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  paging: {
    borderWidth: 0
  }
}));

export const Table = (props) => {
  const {
    columns,
    data,
    onRowSelect,
    totalCount,
    pagination,
    sort,
    onChangePage,
    onChangeSort,
    emptyMessage,
    ...otherProps
  } = props;

  const onRowClick = (event, id) => {
    /// React only to row clicks and not to interactive elements inside the row
    /// Assumption: Row does not contain nested interactive tables.
    if (
      event.target.nodeName.toLowerCase() === 'td' ||
      event.target.classList.contains('rowSelectable')
    ) {
      if (onRowSelect) onRowSelect(id);
    }
  };

  const createSortHandler = (field) => () => {
    onChangeSort(field);
  };

  const classes = styles();

  return data && data.length ? (
    <MaterialTable {...otherProps}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={`${column.field}-cell`} sortDirection={sort ? sort.order : 'asc'}>
              {sort && !column.unsortable ? (
                <TableSortLabel
                  active={sort.field === (column.sortField || column.field)}
                  direction={sort.order}
                  onClick={createSortHandler(column.sortField || column.field)}>
                  {column.label}
                </TableSortLabel>
              ) : (
                <span key={`${column.field}-label`}>{column.label}</span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((datum) => (
          <TableRow
            hover={onRowSelect != null}
            onClick={(event) => onRowClick(event, datum.id)}
            key={datum.id}
            className={`${datum.className} ${onRowSelect ? 'cursor-pointer' : ''}`}
            data-testid={datum.id}>
            {columns.map((column) => (
              <TableCell key={`${datum.id}_${column.field}`}>{datum[column.field]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      {pagination && totalCount > 0 && (
        <TableFooter>
          <TableRow sx={{ '& td': { border: 0 } }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={columns.length}
              count={totalCount}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber}
              onPageChange={onChangePage}
              className={classes.paging}
            />
          </TableRow>
        </TableFooter>
      )}
    </MaterialTable>
  ) : (
    data && <div className='placeholder'>{emptyMessage}</div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  onRowSelect: PropTypes.func,
  totalCount: PropTypes.number,
  pagination: PropTypes.object,
  sort: PropTypes.object,
  onChangePage: PropTypes.func,
  onChangeSort: PropTypes.func,
  emptyMessage: PropTypes.string
};

Table.defaultProps = {
  data: null,
  emptyMessage: 'No records'
};

export default Table;
